import React, { useState, useEffect } from 'react';
import WelcomeText from '../Component-UI/WelcomeText';
import ButtonWrapper from '../Component-UI/Button';
import MetricCard from '../Component/MetricCard/MetricCard';
import JobSummaryCard from '../Component/JobSummaryCard/JobSummaryCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import JobDashboardCard from '../Component/JobDashboardCard/JobDashboardCard';
import { useCurrentUserEmployerId } from '../../../../../hooks/useCurrentUserEmployerId';
import { useAllListingsJobForNewEmp } from '../../../../../data/listings';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { filterByLatestJobs } from '../../../../seeker/details/New_UI/Components/util';
import Meta from '../../../../custom-ui/meta/Meta';


const JobDashboardNewUI = () => {
    const employerId = useCurrentUserEmployerId();
    const navigate = useNavigate()
    const [id, setId] = useState();
    const [jobList, setJobList] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null);
    const [jobSummaryTotal, setJobSummaryTotal] = useState();
    const [metric , setMetric ] = useState({totalView:0, totalClick:0})
    const { data } = useAllListingsJobForNewEmp(id);

    useEffect(() => {
        setId(employerId);
    }, [employerId]);
    useEffect(() => {
        function filterByLatestJobs(jobList:any) {
            let latestJobs
            if (jobList) {
                latestJobs = [...jobList].sort((a, b) => {
                    const dateA:any = new Date(a.creationDate?.seconds * 1000);
                    const dateB:any = new Date(b.creationDate?.seconds * 1000);
                    return dateB - dateA;
                });
            }
            return latestJobs
        }
        setJobList(filterByLatestJobs(data) || []);
        setFilteredJobs(filterByLatestJobs(data) || []);
    }, [data]);

    useEffect(() => {
        let JobSummary;
        let live = 0;
        let draft = 0;
        let hold = 0;
        let closed = 0;
        data?.map((job: any) => {
            if (job.jobState === "live") live = live + 1
            else if (job.jobState === "draft") draft = draft + 1
            else if (job.jobState === "on_hold") hold = hold + 1
            else closed = closed + 1
        })
        setJobSummaryTotal(JobSummary = {
            totalLive: live,
            totatDraft: draft,
            totalHold: hold,
            totalClosed: closed
        })
    }, [data])

    useEffect(() => {
        if (jobList.length > 0) {
            const totalClick = jobList.reduce((acc: number, item: any) => acc + (item?.applyNowCounter || 0), 0);
            const totalViews  =  jobList.reduce((acc: number, item: any) => acc + (item?.clicksCounter || 0), 0);
            setMetric({totalClick:totalClick,totalViews:totalViews})
        }
    }, [jobList]);
    

    const applyFilter = (filterState: any) => {
        if (activeFilter === filterState) {
            setFilteredJobs(filterByLatestJobs( jobList));
            setActiveFilter(null);
        } else {
            const filtered = jobList.filter((job: any) => {
                switch (filterState) {
                    case "live": return job.jobState === "live";
                    case "draft": return job.jobState === "draft";
                    case "hold": return job.jobState === "on_hold";
                    case "closed":
                        return job.jobState === "closed_filled" || job.jobState === "closed_cancelled";
                    default: return true;
                }
            });
            setFilteredJobs(filterByLatestJobs(filtered));
            setActiveFilter(filterState);
        }
    };

    const handleSearch = () => {
        if (searchQuery.trim() === '') return;
        const filtered = jobList.filter((job: any) =>
             job.jobName?.toLowerCase().includes(searchQuery.toLowerCase()) 
        // ||
            // job.workType?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredJobs(filterByLatestJobs(filtered));
        setIsSearching(true);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setFilteredJobs(filterByLatestJobs( jobList));
        setIsSearching(false);
    };
    
    const handleInputChange = (e: any) => {setSearchQuery(e.target.value); if(!e.target.value){  setFilteredJobs(jobList); setIsSearching(false); }}
    const handleKeyPress = (e: any) => e.key === 'Enter' && handleSearch();
    return (
        <div className='h-screen w-full flex flex-col'>
            <Meta title="Jobs" />
            <div className="sticky w-full top-0 bg-gray-50 z-10">
            {/* Section 1: metric card */}
            <div className='flex  justify-between'>
                <WelcomeText />
                <ButtonWrapper onClick={() => navigate("/jobs/new")}>
                    <div className='flex items-center'>
                        <img src='/images/list_alt_add.png' />
                        <p className='ml-3'>Add New Job</p>
                    </div>
                </ButtonWrapper>
            </div>

            {/* Section 2: job summary */}
            <div className='mt-4 grid grid-cols-2 gap-4'>
                <div className='grid grid-cols-2 gap-4'>
                    <MetricCard imgUrl="/images/totalviewicon_emp.png" cardText='Total Views' count={metric.totalViews} />
                    <MetricCard imgUrl="/images/totalclick.png" cardText='Total Clicks' count={metric.totalClick} />
                </div>
                <div>
                    <div className='grid grid-cols-2 gap-4'>
                        <JobSummaryCard cardBgColor='bg-green-200' textClr='text-green-500'
                            cardName="Live Jobs" cardHighLightClr={activeFilter === "live" ? 'bg-green-400' : ""} count={jobSummaryTotal?.totalLive}
                            cardShadowClr="shadow-green-500" imgUrl='/images/Layer_1.png'
                            onClick={() => applyFilter("live")} />
                        <JobSummaryCard cardBgColor='bg-orange-100' textClr='text-orange-400'
                            cardName="Drafts" cardHighLightClr={activeFilter === "draft" ? 'bg-orange-400' : ""} count={jobSummaryTotal?.totatDraft}
                            cardShadowClr="shadow-orange-400" imgUrl='/images/Group.png'
                            onClick={() => applyFilter("draft")} />
                    </div>
                    <div className='grid mt-4 grid-cols-2 gap-4'>
                        <JobSummaryCard cardBgColor='bg-violet-200' textClr='text-violet-500'
                            cardName="On Hold" cardHighLightClr={activeFilter === "hold" ? 'bg-violet-400' : ""} count={jobSummaryTotal?.totalHold}
                            cardShadowClr="shadow-violet-500" imgUrl='/images/Group_onhold.png'
                            onClick={() => applyFilter("hold")} />
                        <JobSummaryCard cardBgColor='bg-red-200' textClr='text-red-400'
                            cardName="Closed" cardHighLightClr={activeFilter === "closed" ? 'bg-red-400' : ""} count={jobSummaryTotal?.totalClosed}
                            cardShadowClr="shadow-red-500" imgUrl='/images/Layer_closed.png'
                            onClick={() => applyFilter("closed")} />
                    </div>
                    </div>
                    
               
                </div>
                
                <div className='flex mt-4 items-center'>
                    <h1 className='text-instant-teams-blue-Main text-2xl font-bold'>Jobs</h1>
                    <div className='w-[50%] flex justify-start items-center ml-5'>
                        <div className='relative w-full'>
                            <input
                                type='text'
                                value={searchQuery}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                placeholder='Search by job title '
                                className='w-full pl-4 pr-10 py-3 text-gray-600 bg-white rounded-full text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-300'
                            />
                            <div
                                onClick={isSearching ? handleClearSearch : handleSearch}
                                className='absolute inset-y-0 right-4 flex items-center cursor-pointer'
                            >
                                <FontAwesomeIcon
                                    icon={isSearching ? faTimes : faSearch}
                                    className="text-gray-400 w-4 h-4"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
            {/* Section 3: job listing */}
            <div className='mt-4 flex-1 overflow-y-scroll no-scrollbar p-4'>
             
                <div className='w-full mt-4 grid grid-cols-3 gap-4'>
                    {filteredJobs.map((job: any) => (
                        <JobDashboardCard key={job.id} jobData={job} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default JobDashboardNewUI;
