import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../providers/auth";
//import MultiFactorSignIn from "multi-factor/MultiFactorSignIn";
import AuthCode from "react-auth-code-input";
import MultiFactorSignIn from "../../../../auth/multi-factor/MultiFactorSignIn";
import { multiFactor } from "firebase/auth";
import { sliLogger, activateUser } from "../../../../../callable-cloud-functions/cloudFunctions";
import { friendlyFirebaseError } from "../../../../../providers/firebase-errors/friendlyFirebaseError";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/custom-ui/toast-alert/ToastAlert";
import "./style.css";

interface SeekerLoginProps {
  onForgotPassword: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const SeekerLogin: any = ({
  onDone,
  seekerInfo,
  onForgotPassword,
  onSingup,
  employerLogin,
  handleSignInClick,
  onSingupEmployer,
  newEmpConfig
}: {
  onDone: Function;
  seekerInfo: any;
  employerLogin: boolean;
  onForgotPassword: any;
  onSingup: () => {};
  handleSignInClick: any;
  onSingupEmployer: () => {};
  newEmpConfig: boolean;
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { handleMultiFactorSignIn } = auth;
  const [pending, setPending] = useState(false);
  const [emailCheckTrigger, setEmailCheckTrigger] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const [isModalMFALogin, setIsModalMFALogin] = useState(false);
  const [error, setError] = useState()

  const isMultiFactorUser = (user: any) => {
    return multiFactor(user).enrolledFactors[0];
  };

  const {
    handleSubmit: handleSubmitMain,
    register: registerMain,

    formState: { errors: errorsMain },
    trigger: triggerMain,
  } = useForm({
    defaultValues: {
      email: "",
      pass: "",
    },
  });

  const {
    handleSubmit: handleSubmitModal,
    register: registerModal,

    formState: { errors: errorsModal },
    trigger: triggerModal,
  } = useForm({
    defaultValues: {
      email: "",
      pass: "",
    },
  });

  const closeModal = () => {
    setIsModalMFALogin(false);
  };


  const submitHandlersByType: any = {
    signin: ({ email, pass }: any) => {
      return auth.signin(email, pass).then((user: any) => {
        // Call auth complete handler
        // props.onAuth(user);
        handleAuth(user)
      });
    },
    // Add other handlers here if needed
  };

  // Handle form submission
  const onSubmit = async ({ email, pass }: any) => {
    // Show pending indicator
    setPending(true);
    setIsCodeComplete(true)
    // Specify the action type, for example "signin"
    await submitHandlersByType.signin({ email, pass })
      .catch(async (error: any) => {

        setError(`${friendlyFirebaseError(error)}`)
        // toast.error(`${friendlyFirebaseError(error)}`);
        setPending(false);
        setIsCodeComplete(false)
        if (error.code === "auth/user-disabled") {
          setPending(true);
          const result: any = await activateUser({ email: email });
          if (result.data.success) {
            await auth.signin(email, pass).then((user: any) => {
              // props.onAuth(user);
            }).catch(async (error: any) => {
              setPending(false);
              console.log("error3", error)
              if (error.code === "auth/multi-factor-auth-required") {


                handleMultiFactorSignIn(
                  error,
                  setShouldMfaStart,
                  (mfaResolver: any) => {
                    setMfaResolver(mfaResolver); // Update the state with the resolver
                    onDone(mfaResolver); // Call onDone with the updated resolver
                  },
                  'test',
                );
                //onDone(mfaResolver)
              } else {
                setError(`${friendlyFirebaseError(error)}`)
                toast.error(`${friendlyFirebaseError(error)}`);

              }
            });
          } else {

            setPending(false);
            setIsCodeComplete(false)

            // toast.error(`${friendlyFirebaseError(error)}`);

          }
        } else if (error.code === "auth/multi-factor-auth-required") {

          handleMultiFactorSignIn(
            error,
            setShouldMfaStart,
            (mfaResolver: any) => {
              setMfaResolver(mfaResolver);
              onDone(mfaResolver);
            },
            'test',
          );

        } else {
          setError(`${friendlyFirebaseError(error)}`)
          toast.error(`${friendlyFirebaseError(error)}`);

        }


        if (
          friendlyFirebaseError(error) ===
          "An error occurred. Please try again later."
        ) {
          sliLogger({
            message: "Authentication failed",
            sevLevel: "ERROR",
            messageContext: { details: "Frontend email/pw login", error },
            resourceType: "Firebase Authentication",
            action: "Login",
            resultStatus: "success",
            cuj: "User Login",
          });
        } else {
          sliLogger({
            message: "Failed to authenticate user, but not an error on our part",
            sevLevel: "INFO",
            messageContext: { details: "Frontend email/pw login" },
            resourceType: "Firebase Authentication",
            action: "Login",
            resultStatus: "success",
            cuj: "User Login",
          });
        }
      });

    // SLI Logger
    sliLogger({
      message: "Successfully authenticated user",
      sevLevel: "INFO",
      messageContext: { details: "Frontend email/pw login" },
      resourceType: "Firebase Authentication",
      action: "Login",
      resultStatus: "success",
      cuj: "User Login",
    });
  };


  const onSigninWithProvider = (provider: any) => {
    //setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user: any) => {
        // Remember this provider was last used
        // so we can indicate for next login.
        localStorage.setItem("lastUsedAuthProvider", provider);
        handleAuth(user)
      })
      .catch((error: any) => {
        ///setPending(null);
        console.log("onSigninWithProvider errored", error);
        if (error.code === "auth/multi-factor-auth-required") {
          try {
            // User is a multi-factor user. Second factor challenge is required.
            handleMultiFactorSignIn(
              error,
              setShouldMfaStart,
              (mfaResolver: any) => {
                setMfaResolver(mfaResolver); // Update the state with the resolver
                onDone(mfaResolver); // Call onDone with the updated resolver
              },
              'test',
            );
            sliLogger({
              message: "MFA user login successful",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          } catch (error) {
            sliLogger({
              message: "MFA user login unsuccessful",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          }
        } else {

          // SLI Logger
          if (
            friendlyFirebaseError(error) ===
            "An error occurred. Please try again later."
          ) {
            sliLogger({
              message: "Authentication failed",
              sevLevel: "ERROR",
              messageContext: {
                details: "Frontend SAML login",
                error: error.message,
              },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          } else {
            sliLogger({
              message:
                "Failed to authenticate user, but not an error on our part",
              sevLevel: "INFO",
              messageContext: { details: "Frontend SAML login" },
              resourceType: "Firebase Authentication",
              action: "Login",
              resultStatus: "success",
              cuj: "User Login",
            });
          }
        }
      });
  };




  const handleAuth = async (user: any) => {
    const token = await user?.auth?.currentUser.getIdTokenResult();
    const { claims } = token ?? {};


    if (!isMultiFactorUser(user?.auth?.currentUser)) {
      localStorage.setItem("mfaReset", "true")
      onDone('MFAD')
      return;
    }


    if (!isMultiFactorUser(user?.auth?.currentUser)) {
      onDone('MFAD')
      localStorage.setItem("mfaReset", "true")
      return;
    }

  };



  return (
    <div className="max-w-lg mx-auto bg-white">

      <div className="w-full mt-10 text-center ">

        <div className="w-full  flex justify-center items-center">
          <img
            src="/images/InstantTeamsLogo_Main_1.png"
            alt="instant teams logo"
            className="mb-6 w-60 xs:w-[150px] xs:mb-3"
          />
        </div>
        <h2 className="text-2xl mt-6 font-semibold text-[#15415E]">{employerLogin ? "Employer Login" : "Login"}</h2>

      </div>
      <div className="max-w-lg mx-auto mt-10  bg-white">

        <form className="" onSubmit={handleSubmitMain(onSubmit)}>
          {/* Email Field */}
          <div className="relative mb-7">
            <input
              type="email"
              name="email"
              placeholder=" "
              ref={registerMain({
                required: "Please enter an email",
              })}
              onFocus={() => setEmailCheckTrigger(false)}
              onChange={async () => await triggerMain("email")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: 'none' }}
            />
            <label
              htmlFor="email"
              className="absolute -top-3.5 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-500 peer-focus:-top-3.5 peer-focus:text-[#15415E]"
            >
              Email <span className="text-red-600">*</span>
            </label>
            {errorsMain.email && <p className="mt-1 text-sm text-red-600">{errorsMain.email.message}</p>}
          </div>

          {/* Password Field */}
          <div className={`relative mb-7`}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder=" "
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: 'none' }}
              name="pass"
              ref={registerMain({
                required: "Please enter a password",
              })}
            />
            <label
              htmlFor="password"
              className="absolute -top-3.5 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-500 peer-focus:-top-3.5 peer-focus:text-[#15415E]"
            >
              Password <span className="text-red-600">*</span>
            </label>
            {errorsMain.pass && (
              <p className="mt-1 text-sm text-red-600">{errorsMain.pass.message}</p>
            )}
          </div>
        { !employerLogin&& <div className=" w-full mb-5 my-2">
            <p className="text-sm text-center text-gray-500 ">If you're already a member of our military spouse community app, you're all set! <br /> Simply use the same login credentials here to get started.</p>
          </div>}
          <div className={`text-center `}>
            <a href="#" className="text-sm text-[#15415E] font-semibold underline" style={{ fontSize: "16px" }} onClick={onForgotPassword}>
              Forgot Password?
            </a>
          </div>

          <div className={`flex mt-4 justify-center`}>
            <button
              type="submit"
              className={`py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none  focus:ring-2 ${isCodeComplete ? "bg-[#15415E]" : "bg-[#15415E] text-[#fffff]"
                }`}
              //className={`py-2 px-8 text-white font-medium rounded-full bg-[#8AA0AE] hover:bg-[#8AA0AE] focus:outline-none focus:ring-2 focus:ring-[#8AA0AE]`} bg-[#8AA0AE]
              disabled={pending}
              style={{ width: '170px' }}
            >
              {pending ? (
                <div className="flex items-center justify-center space-x-2">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>

          {/* Forgot Password Link */}


          {
            !employerLogin ? (
              <>

                <div className={`flex items-center justify-center space-x-4 mt-3`}>

                </div>

                {/* Create Account Link */}
                <div className={`text-center mt-4`}>
                  <p onClick={onSingup} className="text-sm text-[#15415E] cursor-pointer font-semibold underline" style={{ fontSize: "16px" }}>
                    Create Account?
                  </p>
                </div>
              </>
            ) : (
              <>{newEmpConfig && (

                <div className={`text-center mt-4`}>
                  <p onClick={onSingupEmployer} className="text-sm text-[#15415E] cursor-pointer font-semibold underline" style={{ fontSize: "16px" }}>
                    Create Account?
                  </p>
                </div>

              )}</>
            )
          }
        </form>


        {isModalMFALogin && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
            <div
              className={`transform transition-transform duration-300 ease-in-out bg-white rounded-2xl w-1/2 shadow-lg p-8 xs:w-[90%]`}>
              <div className="flex justify-around space-x-4">

                <img src="/images/12m.png" alt="App Store" className="w-20" />

              </div>
              <h2 className="text-2xl font-bold text-center mb-6 text-[#15415E] xs:text-[18px] xs:mb-3">
                Login with Twelve Million Plus
              </h2>

              <div className="max-w-lg mx-auto bg-white">

                <form className="space-y-6" onSubmit={handleSubmitModal(onSubmit)}>

                  <div className="relative mb-7">
                    <input
                      type="email"
                      name="email"
                      placeholder=" "
                      ref={registerModal({
                        required: "Please enter an email",
                      })}
                      onFocus={() => setEmailCheckTrigger(false)}
                      onChange={async () => await triggerModal("email")}
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: 'none' }}
                    />
                    <label
                      htmlFor="email"
                      onFocus={() => setEmailCheckTrigger(false)}
                      className="absolute -top-3.5 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-500 peer-focus:-top-3.5 peer-focus:text-[#15415E]"
                    >
                      Email <span className="text-red-600">*</span>
                    </label>
                    {errorsModal.email && <p className="mt-1 text-sm text-red-600">{errorsModal.email.message}</p>}
                  </div>

                  {/* Password Field */}
                  <div className={`relative mb-7`}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder=" "
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: 'none' }}
                      name="pass"
                      ref={registerModal({
                        required: "Please enter a password",
                      })}
                    />
                    <label
                      htmlFor="password"
                      className="absolute -top-3.5 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-500 peer-focus:-top-3.5 peer-focus:text-[#15415E]"
                    >
                      Password <span className="text-red-600">*</span>
                    </label>
                    {errorsModal.pass && (
                      <p className="mt-1 text-sm text-red-600">{errorsModal.pass.message}</p>
                    )}
                  </div>

                  <div className={`flex justify-center`}>
                    <button
                      type="submit"
                      className={`py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2 ${isCodeComplete ? "bg-[#15415E]" : "bg-[#8AA0AE] text-[#fffff]"
                        }`}
                      //className={`py-2 px-8 text-white font-medium rounded-full bg-[#8AA0AE] hover:bg-[#8AA0AE] focus:outline-none focus:ring-2 focus:ring-[#8AA0AE]`}
                      disabled={pending}
                      style={{ width: '170px' }}
                    >
                      {pending ? (
                        <div className="flex items-center justify-center space-x-2">
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>


        )}



        {/* Resend Confirmation Code */}

      </div>
      <div className={`bg-white h-full pt-16 `} >
        <div className="flex justify-center items-center space-x-4">
          <p className="text-sm text-[#15415E] font-semibold cursor-pointer underline" style={{ fontSize: "16px" }} onClick={() => {
            onSigninWithProvider('instantTeamsWorkspace');
          }}>
            Admin Login
          </p>

          {!employerLogin ? (<>
            <span className="text-gray-400 ">|</span>
            <p onClick={() => { handleSignInClick(true) }} className="text-sm text-[#15415E] font-semibold cursor-pointer underline" style={{ fontSize: "16px" }}>
              Employer Login
            </p>
          </>) : (<>
            <span className="text-gray-400 ">|</span>
            <p onClick={() => { handleSignInClick(false) }} className="text-sm text-[#15415E] font-semibold cursor-pointer underline" style={{ fontSize: "16px" }}>
              Seeker Login
            </p>
          </>)}
        </div>
      </div>
      <ToastAlert />
    </div>
  );
}

export default SeekerLogin;

