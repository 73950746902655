import { useState, useEffect } from "react";
import { cn } from "../../../../../../util/cn";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { contactSalesSupport } from "../../../../../../schemas/SpeakWithAccountExecutive";
import Input from "../../../../../custom-ui/new-ui/Input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import TextArea from "../../../../../custom-ui/new-ui/TextArea";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { AccountExecutiveLeads } from "../../../../../../dataTypes/AccountExecutiveLeads";
import { useAuth } from "../../../../../../providers/auth";
import { createNewEntryForAccountExecutiveLeads } from "../../../../../../data/accountExecutiveLeads";
import { getUser } from "../../../../../../providers/database";

type TSpeakWithAccountExecutiveModalProps = {
  onCancel: () => void;
  onDone: () => void;
  source: string;
  type: string;
};;

const ContactSalesSupport = ({
  onCancel,
  onDone,
  source,
  type
}: TSpeakWithAccountExecutiveModalProps) => {
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const [userData, setUserData] = useState(null);
  const employerId = user?.claims?.employerId;

  // @ts-ignore
  const [formSchema] = useState(contactSalesSupport());

  type FormValues = z.infer<
    ReturnType<typeof contactSalesSupport>
  >;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    // const { fullName, email, phoneNumber, message, ...restValues } = values;
    const { message, ...restValues } = values;

    const fullName = userData?.name;
    const email = userData?.email;
    const phoneNumber = userData?.phone;

    const accountExecutiveLeadsData: any = {
      fullName,
      email,
      phoneNumber,
      message,
      employerId: employerId,
      createdAt: new Date(),
      source: source,
      type:type
    };

    await createNewEntryForAccountExecutiveLeads(accountExecutiveLeadsData)
      .then(() => {
        toast.success("Your form has been submitted successfully");
        setTimeout(() => {
          onDone();
        }, 3000);
      })
      .catch(() => {
        toast.error("There was an error submitting the form");
      });
  };

 

 
  useEffect(() => {
    getUser(user.id).then((userDataObj) => {
      setUserData(userDataObj);
    });
  }, []);

  return (
    <>
      <div className={cn("flex flex-col items-center overflow-hidden", {})}>
        <form className="mt-[30px] w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[30px]">

              

          

            <Controller
              control={control}
              name="message"
              render={(field) => (
                <>
                  <TextArea
                    {...field}
                    display="Message"
                    placeholder="Please write here"
                    labelClassName="text-[16px] text-[#1E596D] font-[400] mb-[10px]"
                    readOnly={false}
                    error={errors?.message?.message}
                    maxLength={500}
                    required={true}
                  />
                </>
              )}
            />
          </div>

          <div className="flex flex-row justify-center items-center gap-[15px] mt-[50px]">
            <button
              className="flex justify-center items-center h-[60px] w-[260px] py-[25px] px-[60px] rounded-[70px] bg-[#15415E] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <ToastAlert />
    </>
  );
};

export default ContactSalesSupport;
