import React from "react";
import { cn } from "../../../../../../util/cn";

type TCreditCardComponentProps = {
  employer: any;
  cardholder_name: string;
  brand: string;
  exp_month: string;
  exp_year: string;
  last4: string;
};

const CreditCardComponent = ({
  employer,
  cardholder_name,
  brand,
  exp_month,
  exp_year,
  last4,
}: TCreditCardComponentProps) => {
  return (
    <div
      className={cn(
        "relative self-center flex flex-col w-[600px] h-[339px] rounded-[20px] border-[1px] border-[#8AA0AE80] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] mt-[20px] px-[30px] pt-[40px] text-[#FFFFFF] overflow-hidden",
        {
          "border-[5px] border-red-500":
            (employer?.stripeData?.subscriptionId &&
              employer?.stripeData?.subscriptionStatus === "past_due") ||
            (!employer?.stripeData?.subscriptionId &&
              employer?.stripeData?.subscriptionStatus === "canceled" &&
              employer?.stripeData?.reason !== "cancellation_requested"),
        },
      )}
    >
      <div className="absolute w-[308px] h-[308px] absolute top-[-90px] left-[-112px] opacity-50 mix-blend-soft-light bg-gradient-to-b from-[#FFFFFF] to-[#FFFFFF00] rounded-full"></div>

      <div className="absolute w-[415px] h-[415px] absolute top-[-54px] left-[328px] opacity-20 mix-blend-soft-light bg-gradient-to-b from-[#FFFFFF] to-[#FFFFFF00] rounded-full"></div>

      <div className="absolute w-[205px] h-[205px] absolute top-[249px] left-[268px] opacity-10 mix-blend-soft-light bg-gradient-to-b from-[#FFFFFF] to-[#FFFFFF00] rounded-full"></div>

      <div className="absolute w-[655px] h-[655px] absolute top-[-283px] left-[-8px] opacity-20 mix-blend-soft-light bg-gradient-to-b from-[#FFFFFF] to-[#FFFFFF00] rounded-full"></div>

      <p className="h-[35px] font-[Gilroy-Medium] font-[400] text-[48px] leading-[58.8px]">
        {/* VISA */}
        {brand.toUpperCase()}
      </p>
      <p className="mt-[45px] font-[Gilroy-Medium] font-[400] text-[48px] leading-[58.8px]">
        {/* xxxx xxxx xxxx 3456 */}
        xxxx xxxx xxxx {last4}
      </p>
      <div className="flex flex-row w-[400px] justify-between mt-[34px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px]">
        <p>Card Holder Name</p>
        <p>Valid</p>
      </div>
      <div className="flex flex-row w-[400px] justify-between mt-[10px] font-[Gilroy-SemiBold] font-[400] text-[30px] leading-[36.75px]">
        <p>{cardholder_name}</p>
        <p>
          {exp_month.length === 1 ? `0${exp_month}` : exp_month}/{exp_year}
        </p>
        {/* <p>Dianne J. Dolls</p>
        <p>8/27</p> */}
      </div>
    </div>
  );
};

export default CreditCardComponent;
