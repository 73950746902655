import * as z from "zod";


const urlRegex = /^https:\/\//;



export const getJobsNewSchema = (isSubmittingJobFirstTime: boolean) => {
  return z
    .object({
      jobName: z
        .string({
          required_error: "Job Title field is required",
        })
        .min(1, {
          message: "Job Title is required",
        }),
      solutionType: z
        .union([
          z.literal("job_board"),
          z.literal("bpo"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Solution Type is required",
        }),

      startDate: z
        .date({
          required_error: "Start Date field is required",
          invalid_type_error: "Start Date is required",
        })
        .nullable()
        .refine((val) => val !== null, {
          message: "Start Date is required",
        })
        .optional(),
      openings: z
        .union([
          z.string().optional(),
          z
            .number({
              invalid_type_error: "Openings is required",
            })
            .min(1, {
              message: "There should be minimum of 1 Opening",
            })
            .max(500, {
              message: "There should be a maximum of 500 Openings",
            }),
        ])
        .refine((value) => value !== "", {
          message: "Openings is required",
        }),
      noATS: z.string().optional(),
      noATSContactEmail: z.string().optional(),
      applicationFlow: z .string({
        required_error: "Application Flow field is required",
      }).optional(),
      location: z.string().optional(),
      workSettings: z
        .union([
          z.literal("remote"),
          z.literal("in_person"),
          z.literal("hybrid"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Setting is required",
        }),
      workType: z
        .union([
          z.literal("full_time"),
          z.literal("part_time"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Type is required",
        }),
      workTerm: z
        .union([
          z.literal("permanent"),
          z.literal("temporary"),
          z.literal("temp_to_perm"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" || null, {
          message: "Work Term is required",
        }),
      hoursPerWeekMin: z
        .union([
          z.string().optional(),
          z
            .number({
              invalid_type_error: "Min (Hrs) is required",
            })
            .min(0, {
              message: "There should be a minimum of 0 Hr",
            }),
        ])
        .refine((value) => value !== "", {
          message: "Min (Hrs) is required",
        }),
      hoursPerWeekMax: z
        .union([
          z.string().optional(),
          z
            .number({
              invalid_type_error: "Max (Hrs) is required",
            })
            .max(100, {
              message: "There should be a maximum of 100 Hrs",
            }),
        ])
        .refine((value) => value !== "", {
          message: "Max (Hrs) is required",
        }),
      payRateType: z
        .union([
          z.literal("salary"),
          z.literal("hourly"),
          z.literal("commission"),
          z.literal("unpaid_internship"),
          z.literal(""),
        ])
        .refine((val) => val !== "" || null, {
          message: "Pay Rate is required",
        }),
      salaryMin: z.string().optional(),
      salaryMax: z.string().optional(),
      hourlyMin: z.string().optional(),
      hourlyMax: z.string().optional(),
      isCommission: z
        .boolean({
          required_error: "isCommission field is required",
        })
        .optional(),
      commission: z
        .string({
          required_error: "This field is required",
        })
        .min(1, {
          message: "This field is required",
        })
        .optional(),
      additionalBenifits: z
        .string({
          required_error: "Additional Comp Incentives field is required",
        })
        .min(1, {
          message: "Additional Comp Incentives is required",
        }).optional(),
      unpaidInternship: z
        .string({
          required_error: "Free Internship field is required",
        })
        .min(1, {
          message: "Free Internship is required",
        })
        .optional(),
      jobSummary: z
        .string({
          required_error: "Job Summary field is required",
        })
        .min(1, {
          message: "Job Summary is required",
        })
        .optional(),
      whatsInItForYou: z
        .string({
          required_error: "What's in it for you? field is required",
        })
        .min(1, {
          message: "What's in it for you? is required",
        })
        .optional(),
      dayInLife: z
        .string({
          required_error: "Day in the Life field is required",
        })
        .min(1, {
          message: "Day in the Life is required",
        })
        .optional(),
      mustHaves: z
        .string({
          required_error:
            "Must Haves/What You Bring field to the Table is required",
        })
        .min(1, {
          message: "Must Haves/What You Bring to the Table is required",
        })
        .optional(),
      ...(isSubmittingJobFirstTime
        ? {
          aspectsOfJob: z.array(z.string()).optional(),
          mustHavesCheckboxes: z
            .array(z.string())
            .nonempty("At least one checkbox must be selected")
            .max(5, { message: "You must check at most 5 checkboxes." }),
          aboutThisJob: z
            .array(
              z.object({
                code: z.string(),
                labelLeft: z.string(),
                labelRight: z.string(),
                value: z.number(),
              }),
            )
            .nonempty("At least one checkbox must be selected")
            .max(5, { message: "You must check at most 5 checkboxes." }),
        }
        : {
          aspectsOfJob: z.array(z.string()).optional(),
          mustHavesCheckboxes: z
            .array(z.string())
            .nonempty("At least one checkbox must be selected")
            .optional(),
          aboutThisJob: z
            .array(
              z.object({
                code: z.string(),
                labelLeft: z.string(),
                labelRight: z.string(),
                value: z.number(),
              }),
            )
            .nonempty("At least one checkbox must be selected")
            .optional(),
        }),
      aspectsOfJobOther: z
        .string({
          required_error: "Other field is required",
        })
        .min(1, {
          message: "Other is required",
        })
        .optional(),
      isAspectsOfJobOther: z
        .boolean({
          required_error: "isAspectsOfJobOther field is required",
        })
        .optional(),
    })
    .merge(
      z.object({
        hoursPerWeekMin: z
          .union([
            z.string().optional(),
            z
              .number({
                invalid_type_error: "Min (Hrs) must be a number",
              })
              .min(0, { message: "There should be a minimum of 0 Hr" }),
          ])
          .optional(),

        hoursPerWeekMax: z
          .union([
            z.string().optional(),
            z
              .number({
                invalid_type_error: "Max (Hrs) must be a number",
              })
              .max(100, { message: "There should be a maximum of 100 Hrs" }),
          ])
          .optional(),
      })
    )
    .superRefine((data, ctx) => {

      if (data.workSettings === "hybrid" && (!data.location || data.location.trim() === "")) {
        ctx.addIssue({
          path: ["location"],
          message: "Location is required for hybrid work setting",
          code: "custom",
        });
      }
      if (data.noATS === "noATS_true") {
        // Ensure applicationFlow is provided
        if (!data.applicationFlow) {
          ctx.addIssue({
            path: ["applicationFlow"],
            message: "Application Flow URL is required",
            code: "custom",
          });
        } else if (!/^https:\/\//.test(data.applicationFlow)) {
          ctx.addIssue({
            path: ["applicationFlow"],
            message: "Application Flow URL must use HTTPS",
            code: "custom",
          });
        }
      }

      if (data.noATS === "noATS_false") {
        // Ensure noATSContactEmail is provided and is a valid email
        if (!data.noATSContactEmail) {
          ctx.addIssue({
            path: ["noATSContactEmail"],
            message: "Email ID is required",
            code: "custom",
          });
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.noATSContactEmail)) {
          ctx.addIssue({
            path: ["noATSContactEmail"],
            message: "Invalid email format",
            code: "custom",
          });
        }
      }

      if (data.workType == "part_time") {
        if (!data.hoursPerWeekMin) {
          ctx.addIssue({
            path: ["hoursPerWeekMin"],
            message: "Min (Hrs) is required for part-time jobs",
            code: "custom",
          });
        }
        if (!data.hoursPerWeekMax) {
          ctx.addIssue({
            path: ["hoursPerWeekMax"],
            message: "Max (Hrs) is required for part-time jobs",
            code: "custom",
          });
        }
      }

      if (
        data?.aspectsOfJob?.length === 0 &&
        (!data?.aspectsOfJobOther || data?.aspectsOfJobOther?.length === 0) &&
        !data.isAspectsOfJobOther
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["aspectsOfJobRoot"], // Root-level error
          message: "Please check at least one of the above checkboxes",
        });
      }

      if (
        isSubmittingJobFirstTime &&
        data?.aboutThisJob?.length &&
        data?.aboutThisJob?.length > 5
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["aboutThisJobRoot"], // Root-level error
          message: "Please check at most 5 checkboxes",
        });
      }

      if (
        (isSubmittingJobFirstTime || !data?.whatsInItForYou) &&
        data?.mustHavesCheckboxes?.length &&
        data?.mustHavesCheckboxes?.length > 5
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["mustHavesCheckboxesRoot"], // Root-level error
          message: "Please check at most 5 checkboxes",
        });
      }

      const applicationFlow = data.applicationFlow || "";
      if (data?.noATS === "noATS_true") {
        if (!urlRegex.test(applicationFlow)) {
          ctx.addIssue({
            code: "custom",
            message: "Invalid URL",
            path: ["applicationFlow"],
          });
        }
      }

      const hoursPerWeekMin =
        data.hoursPerWeekMin || data.hoursPerWeekMin === 0
          ? data.hoursPerWeekMin
          : null;
      const hoursPerWeekMax =
        data.hoursPerWeekMax || data.hoursPerWeekMax === 0
          ? data.hoursPerWeekMax
          : null;

      if (hoursPerWeekMin !== null && hoursPerWeekMax !== null) {
        if (Number(hoursPerWeekMin) > Number(hoursPerWeekMax)) {
          ctx.addIssue({
            code: "custom",
            message: "Min (Hrs) must be less than Max (Hrs)",
            path: ["hoursPerWeekMin"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Max (Hrs) must be greater than Min (Hrs)",
            path: ["hoursPerWeekMax"],
          });
        }

        if (Number(hoursPerWeekMax) > 100) {
          ctx.addIssue({
            code: "custom",
            message: "There should be a maximum of 100 Hrs",
            path: ["hoursPerWeekMax"],
          });
        }
      }

      const payRateType = data.payRateType;

      if (payRateType === "salary") {
        // Check if values are present
        const salaryMax = data.salaryMax?.trim() || null; // Trim whitespace and assign null if empty
        const salaryMin = data.salaryMin?.trim() || null; // Trim whitespace and assign null if empty

        if (salaryMax === null) {
          ctx.addIssue({
            code: "custom",
            message: "Max is required",
            path: ["salaryMax"],
          });
        }
        if (salaryMin === null) {
          ctx.addIssue({
            code: "custom",
            message: "Min is required",
            path: ["salaryMin"],
          });
        }

        // Parse the salaries to numbers for comparison
        const parsedSalaryMax = salaryMax !== null ? Number(salaryMax) : null;
        const parsedSalaryMin = salaryMin !== null ? Number(salaryMin) : null;

        if (parsedSalaryMin !== null && parsedSalaryMax !== null) {
          if (Number(parsedSalaryMin) > Number(parsedSalaryMax)) {
            ctx.addIssue({
              code: "custom",
              message: "Min must be less than Max",
              path: ["salaryMin"],
            });
            ctx.addIssue({
              code: "custom",
              message: "Max must be greater than Min",
              path: ["salaryMax"],
            });
          }
        }
      } else if (payRateType === "hourly") {
        // Check if values are present
        const hourlyMax = data.hourlyMax?.trim() || null; // Trim whitespace and assign null if empty
        const hourlyMin = data.hourlyMin?.trim() || null; // Trim whitespace and assign null if empty

        if (hourlyMax === null) {
          ctx.addIssue({
            code: "custom",
            message: "Max is required",
            path: ["hourlyMax"],
          });
        }
        if (hourlyMin === null) {
          ctx.addIssue({
            code: "custom",
            message: "Min is required",
            path: ["hourlyMin"],
          });
        }

        // Parse the hourlies to numbers for comparison
        const parsedHourlyMax = hourlyMax !== null ? Number(hourlyMax) : null;
        const parsedHourlyMin = hourlyMin !== null ? Number(hourlyMin) : null;

        if (parsedHourlyMin !== null && parsedHourlyMax !== null) {
          if (Number(parsedHourlyMin) > Number(parsedHourlyMax)) {
            ctx.addIssue({
              code: "custom",
              message: "Min must be less than Max",
              path: ["hourlyMin"],
            });
            ctx.addIssue({
              code: "custom",
              message: "Max must be greater than Min",
              path: ["hourlyMax"],
            });
          }
        }
      } else if (payRateType === "commission") {
        if (data.commission === "") {
          ctx.addIssue({
            code: "custom",
            message: "Commission is required",
            path: ["commission"],
          });
        }
      } else if (payRateType === "unpaid_internship") {
        if (data.unpaidInternship === "") {
          ctx.addIssue({
            code: "custom",
            message: "Free Internship is required",
            path: ["unpaidInternship"],
          });
        }
      }
    });
};

