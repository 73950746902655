import { useState, useEffect } from "react";
import CardWrapper from "./CardWrapper";
import { useEmployer } from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";
import DialogWrapper from "../../../../../custom-ui/dialog/Dialog_NewUI";
import { cn } from "../../../../../../util/cn";
import ElementsProvider from "./ElementsProvider";
import { getStripePaymentMethod } from "../../../../../../callable-cloud-functions/cloudFunctions";
import CreditCardComponent from "./CreditCardComponent";

type SavedCardComponentProps = {};

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// eslint-disable-next-line no-empty-pattern
const SavedCardComponent = ({}: SavedCardComponentProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  const [isInitialized, setIsInitialized] = useState(false);
  const [isModalUpdateCard, setIsModalUpdateCard] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previousCardDetails, setPreviousCardDetails] = useState(null);

  const closeModal = () => {
    setIsModalUpdateCard(false);
    setIsLoading(false);
  };

  const handleUpdateCard = () => {
    setIsLoading(true);
    setIsModalUpdateCard(true);
  };

  const fetchStripePaymentMethod = async () => {
    try {
      setIsLoading(true);
      const result = await getStripePaymentMethod({
        employerId: employerId,
        customerId: employer.customerId,
      });

      const data = result.data as any;
      if (data) {
        setCardDetails(data?.cardDetails);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchStripePaymentMethod = debounce(
    fetchStripePaymentMethod,
    1000,
  );

  // const onCardSaved = () => {
  //   setIsModalUpdateCard(false);

  //   if (employer) {
  //     debouncedFetchStripePaymentMethod();
  //   }
  // };

  const onCardSaved = () => {
    setIsModalUpdateCard(false);

    if (employer) {
      const previousDetails = JSON.stringify(cardDetails);
      debouncedFetchStripePaymentMethod();

      setTimeout(() => {
        const newDetails = JSON.stringify(cardDetails);
        if (previousDetails === newDetails) {
          fetchStripePaymentMethod();
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIsInitialized(true);
      fetchStripePaymentMethod();
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CardWrapper className="h-fit w-fit">
        <div className="flex flex-col flex-wrap">
          <div className="flex flex-row justify-between items-center">
            <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[25px] text-[#15415E] uppercase tracking-wide">
              Saved Card
            </p>

            <div className="flex flex-row gap-[10px]">
              {!(
                (employer?.stripeData?.subscriptionId &&
                  employer?.stripeData?.subscriptionStatus === "past_due") ||
                (!employer?.stripeData?.subscriptionId &&
                  employer?.stripeData?.subscriptionStatus === "canceled")
              ) && (
                <button
                  className={cn(
                    "bg-[#15415E] text-[#FFFFFF] flex justify-center items-center rounded-[70px] w-[185px] h-[40px]",
                  )}
                  onClick={handleUpdateCard}
                  disabled={false}
                >
                  Update Card
                </button>
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="relative self-center flex justify-center items-center w-[600px] h-[339px] rounded-[20px] border-[1px] border-[#8AA0AE80] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] mt-[20px] px-[30px] pt-[40px] text-[#FFFFFF] overflow-hidden">
              <img
                src="/images/loader_v2.gif"
                alt="loader"
                className="w-[50px] h-[50px]"
              />
            </div>
          ) : (
            <CreditCardComponent
              employer={employer}
              cardholder_name={employer?.billingContact?.name ?? ""}
              brand={cardDetails?.brand ?? ""}
              exp_month={
                cardDetails?.exp_month ? cardDetails?.exp_month.toString() : ""
              }
              exp_year={
                cardDetails?.exp_year
                  ? cardDetails.exp_year.toString().substr(2, 4)
                  : ""
              }
              last4={cardDetails?.last4}
            />
          )}
        </div>
      </CardWrapper>

      {isModalUpdateCard && (
        <DialogWrapper
          title="Add New Card"
          onClose={closeModal}
          displayCloseIcon={true}
          size="max-w-[730px]"
        >
          <ElementsProvider
            employerId={employer.id}
            customerId={employer.customerId}
            onDone={onCardSaved}
          />
        </DialogWrapper>
      )}
      <ToastAlert />
    </>
  );
};

export default SavedCardComponent;
