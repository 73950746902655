import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { updateUserById } from '../../../../../../data/user';

const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps,
    stepIndex,
    stepsLength,
    setIsJoyrideRunning,
    userData,
    jobStatus,
}) => {
    const isLastStep = stepIndex === stepsLength - 1;
    return (
        <div {...tooltipProps} className="rounded-lg shadow-lg p-4 bg-white max-w-xs relative">
            {/* Tooltip Content */}
            <div className="text-gray-800 text-base mb-20">{step.content}</div> {/* Add margin to avoid overlapping buttons */}

            {/* Fixed Bottom Buttons */}
            <div className="fixed bottom-4 left-0 right-0 flex justify-between items-center px-4 max-w-xs mx-auto">
                <button
                    onClick={async () => {
                        if (jobStatus === 'draft') {
                            await updateUserById(userData?.id, {
                                "walkthrough.draft": true
                            });
                        } else if (jobStatus === 'live') {
                            await updateUserById(userData?.id, {
                                "walkthrough.live": true
                            });
                        }
                        setIsJoyrideRunning(false)
                    }} 
                    className="px-7 py-1 bg-white border-none underline text-instant-teams-blue-D2 rounded-full"
                >
                    Skip
                </button>
                <div className='flex ml-3 items-center'>
                    {index > 0 && (
                        <button {...backProps} className="px-7 py-1 mr-2 text-white bg-instant-teams-blue-Main rounded-full">
                            Back
                        </button>
                    )}
                    {!isLastStep && (
                        <button {...primaryProps} className="px-7 py-1 bg-instant-teams-blue-Main rounded-full text-white ">
                            Next
                        </button>
                    )}
                    {isLastStep && (
                        <button {...primaryProps} className="px-7 py-1 bg-green-500 rounded-full text-white ">
                            Done
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};


const JoyrideDemo = ({ jobStatus, startWalkthrough, userData }) => {
    const [showStartPopup, setShowStartPopup] = useState(false);
    const [isJoyrideRunning, setIsJoyrideRunning] = useState(false); // Separate state to start Joyride

    // Steps for draft jobs
    const stepsForDraftJobs = [
        {
            target: '#editIcon',
            content: 'Click the Edit icon to update the details of your drafted job.',
        },
        {
            target: '#jobStatus',
            content: (
                <>
                    <p>Change job status as needed</p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Live: Make the job visible to job seekers.</li>
                        <li>On-Hold: Pause the job temporarily.</li>
                        <li>Draft: Save the job for later editing.</li>
                    </ul>
                </>
            )
        },
        {
            target: '#jobPreview',
            content: 'Preview how your job listing will appear to job seekers before publishing.',
        }
    ];

    // Steps for live jobs
    const stepsForLiveJobs = [
        {
            target: '#jobPreview',
            content: 'Preview how your job listing will appear to job seekers before publishing.',
        },
        {
            target: '#copySocialLink',
            content: 'Copy the social link to share your live job.',
        },
        {
            target: '#socialLink',
            content: 'See how your job appears on the social page before sharing.',
        },
    ];

    // Check if tour should be shown
    useEffect(() => {

        const draftTourCompleted =userData?.walkthrough?.draft?true:false;
        const liveTourCompleted = userData?.walkthrough?.live?true:false;

        if (jobStatus === 'draft' && !draftTourCompleted && startWalkthrough) {
            setShowStartPopup(true);
        } else if (jobStatus === 'live' && !liveTourCompleted && startWalkthrough) {
            setShowStartPopup(true);
        } else {
            setShowStartPopup(false);
        }
    }, [jobStatus, startWalkthrough,userData]);

    const handleJoyrideCallback = async (data) => {
        const { status } = data;

        if (status === 'skipped') {
            setIsJoyrideRunning(false); // Stop Joyride
            setShowStartPopup(false);  // Close popup if needed
    
            // Update user walkthrough status in backend
            if (jobStatus === 'draft') {
                await updateUserById(userData?.id, {
                    "walkthrough.draft": true
                });
            } else if (jobStatus === 'live') {
                await updateUserById(userData?.id, {
                    "walkthrough.live": true
                });
            }

            return;
        }

        if (status === 'finished') {
            if (jobStatus === 'draft') {
                await updateUserById(userData?.id, {
                    "walkthrough.draft": true
                });
            } else if (jobStatus === 'live') {
                await updateUserById(userData?.id, {
                    "walkthrough.live": true
                });
            }

            setIsJoyrideRunning(false);
            setShowStartPopup(false);
        }
    };


    const steps = jobStatus === 'live' ? stepsForLiveJobs : stepsForDraftJobs;

    return (
        <div>
            <Joyride
                steps={steps}
                continuous={true}
                disableBeacon={false}
                run={isJoyrideRunning}
                callback={handleJoyrideCallback}
                tooltipComponent={(props) => (
                    <CustomTooltip
                        {...props}
                        stepIndex={props.index}
                        stepsLength={steps.length}
                        setIsJoyrideRunning={setIsJoyrideRunning}
                        userData={userData}
                        jobStatus={jobStatus}
                    />
                )}
                spotlightClicks={true}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    spotlight: {
                        display: 'none', 
                    },
                }}
            />
            {showStartPopup && !isJoyrideRunning && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full text-center">
                        <h2 className="text-xl font-bold mb-4">Need help?</h2>
                        <p className="text-gray-600 mb-6">Click below to start a quick walkthrough of this page.</p>
                        <div className="flex justify-center gap-4">
                            <button
                                onClick={() => setIsJoyrideRunning(true)}
                                className="bg-instant-teams-blue-Main text-white px-6 py-2 rounded-lg"
                            >
                                Start Tutorial
                            </button>
                            <button
                                onClick={async() => { setShowStartPopup(false); await updateUserById(userData?.id, {
                                    "walkthrough.draft": true,
                                    "walkthrough.live": true
                                }); }}
                                className="border border-gray-300 px-6 py-2 rounded-lg"
                            >
                              Skip
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JoyrideDemo;
