import React from 'react';


const JobsSettingCard = ( {title,text,jobData,workType="",workSettings=""}:{title:string, text:any, jobData?:any,workType?:string,workSettings?:string}) => {
    return (
        <div className='h-[100%]  p-2 pl-5 bg-white rounded-lg py-3 px-3'>
            <div className=' w-12 h-12 rounded-full bg-gray-100 p-3 ml-[-5px]'>
                <img src="/images/jobdetail-vectore.png" alt="img" />
            </div>
            <div className='mt-4'>
                <p className='text-sm text-instant-teams-teal-L1 font-light'>
                   {title}
                </p>
                <p className='text-lg mt-2 font-semibold text-instant-teams-teal-Main'>{text}</p>
                {workType === "part_time" && <p className=' text-instant-teams-teal-Main text-xs '>{`${jobData?.hoursPerWeek.min} - ${jobData?.hoursPerWeek.max} hours/week`}</p>}
                {workSettings === "hybrid" && jobData?.hybridJobLocation && <p className=' text-instant-teams-teal-Main text-xs '>{jobData?.hybridJobLocation||""}</p>}

            </div>

        </div>
    );
}

export default JobsSettingCard;

