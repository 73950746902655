import { useState, useEffect } from "react";
import { seekerInfoSchema, pwdSchema, PASSWORD_REQ } from "./Schema/registrationSchema";
import { z } from "zod";
import {  useUserByEmail } from "../../../../../data/user";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useLookup } from "../../../../../data/lookup";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Origin, AuthStatus } from "../../../../../dataTypes/AuthOrigin";
import { createAuthOrigin, useAuthOrigin } from "../../../../../data/authOrigin";
import { friendlyFirebaseError } from "../../../../../providers/firebase-errors/friendlyFirebaseError";
import { filter } from "lodash";
import PwdCheck from "../../../../../components/custom-ui/pwd-check/PwdCheck";
import { useAuth } from "../../../../../providers/auth";
import "./style.css";
import { useGlobalState } from "../../../../../providers/globalState/GlobalStateProvider";
import InfoIcon from "./InfoIcon";
import { count } from "node:console";
import { USA_States } from "../../../utils";

function SeekerRegistrationStepOne({
  onDone,
}: {
  onDone: Function;
}) {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useGlobalState();
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);


  const [emailCheckTrigger, setEmailCheckTrigger] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [authOriginQueryTrigger, setAuthOriginQueryTrigger] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [preferredValue, setPreferredValue] = useState("")



  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isValid },
    control,
    getValues,
    watch,
    trigger
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      password: '',
      confirmPassword: '',
      state: "",
      city: "",
      zipCode: "",
      country: ""
    },
    resolver: zodResolver(seekerInfoSchema),
  });

  const watchEmail = watch("email") ?? "";
  const watchPassword = watch("password");

  const watchConfirmPassword = watch("confirmPassword");

  const { data: partnerList = [] } = useLookup("partner");

  const { data: authOriginRecord = [] } = useAuthOrigin(
    watchEmail?.toLowerCase(),
    Origin.seekerWeb,
    authOriginQueryTrigger,
  );




  useEffect(() => {
    const filtered = partnerList.filter((element: any) => element.isActive);
    setDropDownList(filtered);
  }, [partnerList]);

  const saveSeekerInfoAndAdvanceForm = async (data: SeekerInfoSchema) => {
    setPending(true);
    const hasAuthOriginRecord = !!authOriginRecord.length;
    const {
      email,
      name,
      terms,
      password,
      phone: mobilePhone,
      state,
      zipCode,
      country,
      city
    } = data;

    if (!hasAuthOriginRecord) {

      createAuthOrigin({
        email: email.toLowerCase(),
        origin: Origin.seekerWeb,
        status: AuthStatus.start,
        lastUpdated: Timestamp.now(),
      })
        .then(() => {
          localStorage.setItem("seekerEmail", JSON.stringify({ ...data, preferredName: preferredValue }));
          // onDone(data);
          auth
            .signup(email, password, name,
              preferredValue,
              terms,
              mobilePhone, selectedValue)
            .then(async(data: any) => {
              console.log(data, "jojgqergjpoqergoqwrgioqwrgoqr")
              console.log(email, name, preferredValue, "jorqtjoqjtoqjeoqirjoqerjqowjqoijqoijqo")
           

            })
            .catch((error: any) => {
              setFormAlert({
                type: "error",
                code: error.code,
                message: friendlyFirebaseError(error),
              });
            })

        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setPending(false));
      return;
    }
    // onDone(data);
  };

  const { data: existingEmail } = useUserByEmail(
    watchEmail.toLowerCase(),
    emailCheckTrigger,
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleTooltipVisibility = () => {
    setShowTooltip(!showTooltip);
  };

  const toggleConfirmTooltipVisibility = () => {
    setShowConfirmTooltip(!showConfirmTooltip);
  };


  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const emailExists = () => {
    if (Array.isArray(existingEmail)) {
      return !!existingEmail.length;
    }
    return false;
  };

  const handlePasswordChange = async (e: any) => {
    setPasswordValue(e.target.value);
  };

  type SeekerInfoSchema = z.infer<typeof seekerInfoSchema>;

  return (
    <div className="max-w-2xl mx-auto p-6  overflow-y-scroll no-scrollbar  bg-white">

      <form className="space-y-8" onSubmit={handleSubmit(saveSeekerInfoAndAdvanceForm)}
        autoComplete="off">

        {/* <input className="inp" placeholder="What needs to be done?👻"/> */}
        <div className="relative mb-7">

          <input
            type="email"
            name="email"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
              required: "Please enter an email",
            })}
            onBlur={() => {
              setEmailCheckTrigger(true);
              setAuthOriginQueryTrigger(true);
              trigger("email")
            }}
            onFocus={() => setEmailCheckTrigger(false)}

            //onBlur={async () => await trigger("email")} 

            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          ></input>
          <label
            htmlFor="email"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Email <span className="text-red-600">*</span>
          </label>
          {dirtyFields.email && errors.email && (
            <p className="mt-1 text-sm text-red-600">
              {errors.email.message}
            </p>
          )}

          {emailExists() && (
            <div className="rounded bg-amber-200 flex justify-center flex-col">
              <p className="text-center text-sm font-bold">
                Looks like you may already have an account.
              </p>

            </div>
          )}
        </div>
        <div className="relative mb-7">

          <PhoneInputWithCountry
            className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
            style={{ boxShadow: 'none' }}
            name="phone"
            international
            defaultCountry="US"
            countryCallingCodeEditable={false}
            control={control}
            //onChange={async () => await trigger("phone")}
            onBlur={async () => await trigger("phone")}

            rules={{
              required: "Phone number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
          />
          <label
            htmlFor="phone"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Phone Number <span className="mr-1 text-red-600">*</span>
          </label>
          {dirtyFields.phone && errors.phone && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.phone.message}
            </p>
          )}

        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="name"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your full name.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("name")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="name"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Full Name <span className="text-red-600">*</span>
          </label>
          {dirtyFields.name && errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}


        </div>


        <div className="relative mb-7">
          <input
            type="text"
            name="preferredName"
            placeholder=" "  // Empty placeholder for floating effect
            onChange={(e) => setPreferredValue(e.target.value)}
            //onBlur={async () => await trigger("preferredName")} 
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="preferredName"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Preferred Name
          </label>
        </div>
        <div className="  w-full grid grid-cols-2 space-x-4">
        <div>
            <div className={`flex  ${getValues("state") ? "font-bold w-[280px]" : "w-[240px]"}  flex-col  justify-start `}>
              <h1 className="text-[13px] mt-[-9px] z-40 text-[#15415E]">
                State <span className="text-red-500 text-[12px]">*</span>
              </h1>
              <select
                className={`px-3 ml-[-10px] mt-[-12px] text-[13px] border-2 border-gray-900  ${getValues("state") ? "font-bold w-full" : "w-[280px]"}  leading-8 focus:ring-1 focus:ring-gray-900 rounded-md`}
                style={{ color: '#15415E', boxShadow: 'none', borderColor: errors.state? 'red' : '' }}
                name="state"
                ref={register({
                  required: "Please select state.",
                })}
              >
                <option value="" selected disabled>
                  Select State
                </option>
                {USA_States?.map((state: any) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.state}
                  </option>
                ))}
              </select>
              <hr
                className={`mt-[-9px] border-t-2 border-gray-300 ${getValues("state") ? "font-bold w-[280px]" : "w-[240px]"} ${errors.state ? "border-red-600 bg-red-600" : ""
                  }`}
              />
            </div>
            {errors.state && (
              <p className="text-sm mt-1 text-left text-red-600">
                {errors.state.message}
              </p>
            )}
          </div>
          <div className="relative ">
            <input
              type="text"
              name="city"
              placeholder=" "  // Empty placeholder for floating effect
              ref={register({
                required: "Please enter your City.",
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("city")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: 'none' }}
            />
            <label
              htmlFor="City"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              City 
            </label>
            {dirtyFields.city && errors.city && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.city.message}
              </p>
            )}
          </div>
          

        </div>

        {watch("state") !== "Outside-USA" && <div className="relative ">
          <input
            type="text"
            name="zipCode"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your Zipcode.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("zipCode")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="City"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Zipcode <span className="text-red-600">*</span>
          </label>
          {dirtyFields.zipCode && errors.zipCode && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.zipCode.message}
            </p>
          )}
        </div>}

        {watch("state") === "Outside-USA" && <div className="relative ">
          <input
            type="text"
            name="country"
            placeholder=" "  // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your Country.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("country")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="City"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Country <span className="text-red-600">*</span>
          </label>
          {dirtyFields.country && errors.country && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.country.message}
            </p>
          )}
        </div>}

        <div className="relative mb-7">
          <input
            type={showPassword ? "text" : "password"} // Toggle input type
            name="password"
            placeholder=" "
            ref={register()}
            onChange={handlePasswordChange}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="password"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Password <span className="text-red-600">*</span>
          </label>

          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? <FontAwesomeIcon icon={faEye} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} /> : <FontAwesomeIcon icon={faEyeSlash} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} />}
          </div>

          <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer" onClick={toggleTooltipVisibility} style={{ marginRight: '-60px', marginTop: '-5px' }}>
            <InfoIcon viewDown={true} singupFlow={true} tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`} />
          </div>



          {dirtyFields.password && errors.password && <p className="mt-1 text-sm text-left text-red-600">{errors.password.message}</p>}

          {passwordValue && (
            <PwdCheck
              schema={pwdSchema}
              listStyles="text-sm text-instant-teams-gray-D1"
              value={watchPassword}
              pwdReq={PASSWORD_REQ}
            />


          )}

        </div>


        <div className="relative mb-7">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder=" "
            ref={register()}
            //onChange={async () => await trigger("confirmPassword")}
            onBlur={async () => await trigger("confirmPassword")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="confirmPassword"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Confirm Password <span className="text-red-600">*</span>
          </label>

          <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
            {showConfirmPassword ? <FontAwesomeIcon icon={faEye} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} /> : <FontAwesomeIcon icon={faEyeSlash} color="#15415E" className="w-4" style={{ marginTop: '-5px' }} />}
          </div>

          <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer" onClick={toggleConfirmTooltipVisibility} style={{ marginRight: '-60px', marginTop: '-5px' }}>
            <InfoIcon viewDown={true} singupFlow={true} tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`} />
          </div>
          {dirtyFields.confirmPassword && errors.confirmPassword && <p className="mt-1 text-sm text-left text-red-600">{errors.confirmPassword.message}</p>}
        </div>


        <div className="relative mb-7">
          <select
            className={`peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm custom-dropdown`}
            name="referredBy"
            style={{ fontSize: '13px', boxShadow: 'none', color: !selectedValue ? '#AFAFAF' : '#15415E' }}
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <option value="" className="text-red-600">
              Select Source
            </option>
            {dropDownList?.map((referral: any) => {
              return (
                <option key={referral.id} value={referral.code}>
                  {referral.label}
                </option>
              );
            })}
          </select>
          <label
            htmlFor="referredBy"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Source
          </label>
        </div>

        {/* Terms and Conditions */}
        <div className="relative mb-7  items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name="terms"
              ref={register({
                required: "Please agree to our terms of service.",
              })}
              className="h-4 w-4 text-[#15415E] focus:ring-gray-500 border-gray-600 rounded"
            />

            <label htmlFor="terms" className="text-[#616161] ml-3 text-sm">
              By creating an account, you agree to our{" "}
              <a href="https://instantteams.com/terms-and-conditions/" target="_blank" className="text-[#15415E] underline">
                Terms
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="https://instantteams.com/privacypolicy/" target="_blank" className="text-[#15415E] underline">

                Privacy Policy
              </a>.
            </label>

          </div>
          {dirtyFields.terms && errors.terms && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.terms.message}
            </p>
          )}
        </div>

        {/* Submit Button */}

        <div className="flex justify-center">
          <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
            style={{ width: '200px' }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>

              </div>
            ) : (
              "Sign Up"
            )}
          </button>
        </div>

      </form>
    </div>
  );
}

export default SeekerRegistrationStepOne;
