import { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { cn } from "../../../../../util/cn";
import Meta from "../../../../custom-ui/meta/Meta";
import {
  DataTable,
  IsSortedDataTableIcon,
} from "../../../../custom-ui/new-ui/DataTable";
import DialogWrapper from "../../../../custom-ui/dialog/Dialog_NewUI";
import BuyCredits from "../Component/CurrentPlan/BuyCredits";
import SemiCircularProgress from "../Component/CurrentPlan/SemiCircularProgress";
import BulletTextWrapper from "../Component/CurrentPlan/BulletTextWrapper";
import { useAuth } from "../../../../../providers/auth";
import {
  getCurrentPlanText,
  useEmployer,
  useEmployerCreditsByEmployerId,
} from "../../../../../data/employer";
import moment from "moment";
import { useAllEmployerJobsWithCreditUsedByEmployerIdFromJobDetailsNewCollection } from "../../../../../data/jobDetailsNewCollection";
import CancelMonthlyPlanModal from "../Component/CurrentPlan/CancelMonthlyPlanModal";
import CancelAnnualPlanModal from "../Component/CurrentPlan/CancelAnnualPlanModal";
import BuyCreditsAnnualPlanModal from "../Component/CurrentPlan/BuyCreditsAnnualPlan";
import { cancelStripeSubscription } from "../../../../../callable-cloud-functions/cloudFunctions";
import SpeakWithAccountExecutiveModal from "../Component/CurrentPlan/SpeakWithAccountExecutiveModal";
import SpeakWithAccountExecutiveThankYouModal from "../Component/CurrentPlan/SpeakWithAccountExecutiveThankYouModal";
import useModal from "../../../../../hooks/useModal";
import ContactSalesSupport from "../Component/CurrentPlan/ContactSalesSupport";

const ONE_DAY_SECONDS = 86400;

type CurrentPlanDatatable = {
  id: string;
  jobTitle: string;
  jobStatus: string;
  postedBy: string;
  postingDate: string;
  expiringDate: string;
};

type Props = {};

const CurrentPlan_NewUI = (props: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const employerId = user?.claims?.employerId;
  const { isShowing: showGetInTouchModal, toggle: toggleShowGetInTouchModal } =
    useModal();

  const { data: employer } = useEmployer(employerId ?? "");

  const { data: employerCredits, isLoading: isLoadingEmployerCredits } =
    useEmployerCreditsByEmployerId(employerId);

  const { data: jobsWithCreditUsed, isLoading: isLoadingJobsWithCreditUsed } =
    useAllEmployerJobsWithCreditUsedByEmployerIdFromJobDetailsNewCollection(
      employerId,
    );

  const [searchParams] = useSearchParams();

  const isBuyMoreCreditsRedirected = searchParams.get("buyMoreCredits");

  const [isBuyMoreCreditsModalVisible, setIsBuyMoreCreditsModalVisible] =
    useState(isBuyMoreCreditsRedirected === "true" ? true : false);
  const [
    isBuyMoreAnnualCreditsModalVisible,
    setIsBuyMoreAnnualCreditsModalVisible,
  ] = useState(false);
  const [isCancelMonthlyPlanModalVisible, setIsCancelMonthlyPlanModalVisible] =
    useState(false);
  const [isCancelAnnualPlanModalVisible, setIsCancelAnnualPlanModalVisible] =
    useState(false);
  const [
    isAnnualSpeakWithAccountExecutiveModalVisible,
    setIsAnnualAccountExecutiveLeads,
  ] = useState(false);
  const [
    isAnnualSpeakWithAccountExecutiveThankYouModalVisible,
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible,
  ] = useState(false);
  const [
    speakWithAccountExecutiveModalType,
    setSpeakWithAccountExecutiveModalType,
  ] = useState("request");
  const [totalCredits, setTotalCredits] = useState([]);
  const [usedCredits, setUsedCredits] = useState([]);
  const [creditExpiryData, setCreditExpiryData] = useState([]);

  const handleBuyMoreCredits = () => {
    if (["single", "monthly", null].includes(employer?.currentPlan)) {
      setIsBuyMoreAnnualCreditsModalVisible(false);
      setIsBuyMoreCreditsModalVisible(true);
    } else if (employer?.currentPlan === "annual") {
      setIsBuyMoreCreditsModalVisible(false);
      setIsBuyMoreAnnualCreditsModalVisible(true);
    }
  };

  const closeBuyMoreCreditsModal = () => {
    setIsBuyMoreCreditsModalVisible(false);
  };

  const closeBuyMoreAnnualCreditsModal = () => {
    setIsBuyMoreAnnualCreditsModalVisible(false);
  };

  const onSubmitBuyMoreAnnualCreditsModal = () => {
    setSpeakWithAccountExecutiveModalType("request");
    setIsBuyMoreAnnualCreditsModalVisible(false);
    setIsCancelAnnualPlanModalVisible(false);
    setIsAnnualAccountExecutiveLeads(true);
  };

  const handleCancelPlan = () => {
    if (employer?.currentPlan === "monthly") {
      setIsCancelAnnualPlanModalVisible(false);
      setIsCancelMonthlyPlanModalVisible(true);
    } else if (employer?.currentPlan === "annual") {
      setIsCancelMonthlyPlanModalVisible(false);
      setIsCancelAnnualPlanModalVisible(true);
    }
  };

  const closeCancelMonthlyPlanModal = () => {
    setIsCancelMonthlyPlanModalVisible(false);
  };

  const closeCancelAnnualPlanModal = () => {
    setIsCancelAnnualPlanModalVisible(false);
  };

  const onSubmitCancelAnnualPlanModal = () => {
    setSpeakWithAccountExecutiveModalType("cancel");
    setIsAnnualAccountExecutiveLeads(true);
    setIsCancelAnnualPlanModalVisible(false);
  };

  const onProceedCancelMonthlyPlanModal = async () => {
    try {
      // get the subscription from employer and supply it to the argument of the cancelStripeSubscription function
      const cancelPlan = await cancelStripeSubscription({
        subscriptionId: employer?.stripeData?.subscriptionId,
        employerId: employer.id,
      });
      setIsCancelMonthlyPlanModalVisible(false);
    } catch (error) {
      setIsCancelMonthlyPlanModalVisible(false);
    }
  };

  const closeAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const onCancelAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const onSubmitAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
    setIsCancelAnnualPlanModalVisible(false);
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(true);
  };

  const closeAnnualSpeakWithAccountExecutiveThankYouModal = () => {
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(false);
  };

  const onSubmitAnnualSpeakWithAccountExecutiveThankYouModal = () => {
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(false);
  };

  const handleUpdateCardClick = () => {
    navigate("/billing-information");
  };

  const getAnnualSpeakWithAccountExecutiveModalSource = useMemo(() => {
    if (speakWithAccountExecutiveModalType === "request") {
      return "annualSubscriptionRenewal";
    } else if (speakWithAccountExecutiveModalType === "cancel") {
      return "annualSubscriptionCancel";
    }

    return "annualSubscriptionRenewal";
  }, [speakWithAccountExecutiveModalType]);

  const currentPlanText = useMemo(
    () => getCurrentPlanText(employer?.currentPlan),
    [employer?.currentPlan],
  );

  const remainingCreditsCount = useMemo(
    () => totalCredits.length - usedCredits.length,
    [totalCredits, usedCredits],
  );

  const subscriptionExpiry = useMemo(() => {
    if (!employer?.lastMonthlyCreditAdded?.seconds) return null;

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const lastCreditAddedSeconds = employer.lastMonthlyCreditAdded.seconds;

    const newSubscriptionExpiry = lastCreditAddedSeconds + 30 * ONE_DAY_SECONDS;

    // Checking if the new subscription expiry is in the future then only setting the subscription expiry otherwise the subscription expiry will be null as then the current monthly or annual plan would have been expired and there will be no active plan
    if (currentTimeInSeconds < newSubscriptionExpiry) {
      return newSubscriptionExpiry;
    }

    return null;
  }, [employer]);

  useEffect(() => {
    if (isLoadingEmployerCredits) return;

    const totalCreditsData = employerCredits.filter((employerCredit: any) => {
      return (
        employerCredit.status !== "expired" &&
        employerCredit.expireAt.seconds > Math.floor(Date.now() / 1000)
      );
    });

    const usedCreditsData = totalCreditsData.filter((employerCredits: any) => {
      return employerCredits.status === "used";
    });

    setTotalCredits(totalCreditsData);
    setUsedCredits(usedCreditsData);

    const unusedCreditsData = totalCreditsData.filter(
      (employerCredits: any) => {
        return employerCredits.status === "unused";
      },
    );

    const mappedExpiryWithCreditsCount: { [key: number]: number } = {};

    unusedCreditsData.forEach((employerCredits: any) => {
      mappedExpiryWithCreditsCount[employerCredits.expireAt.seconds] =
        (mappedExpiryWithCreditsCount[employerCredits.expireAt.seconds] || 0) +
        1;
    });

    const creditExpiryEntries: any = [];

    for (let i in mappedExpiryWithCreditsCount) {
      creditExpiryEntries.push({
        credits: mappedExpiryWithCreditsCount[i],
        timestamp: i,
      });
    }

    creditExpiryEntries.sort((a: any, b: any) => a.timestamp - b.timestamp);
    setCreditExpiryData(creditExpiryEntries);
  }, [isLoadingEmployerCredits]);

  const jobsWithCreditUsedData = useMemo(() => {
    if (isLoadingJobsWithCreditUsed) return;

    // filtering the jobs which are published such that creditId is not there in the database
    const filteredJobsWithCreditUsed = jobsWithCreditUsed.filter((job: any) => {
      return job?.creditId;
    });

    const mappedJobsWithCreditUsed = filteredJobsWithCreditUsed.map(
      (job: any) => {
        return {
          id: job.id,
          jobTitle: job.jobName,
          jobStatus: job.jobState,
          postedBy: job?.postedBy?.userName || "",
          postingDate: job?.firstPublishedDate?.seconds
            ? moment(job?.firstPublishedDate?.seconds, "X").format(
                "DD MMM YYYY",
              )
            : "",
          expiringDate: job?.expirationDate?.seconds
            ? moment(job?.expirationDate?.seconds, "X").format("DD MMM YYYY")
            : "",
        };
      },
    );

    return mappedJobsWithCreditUsed;
  }, [isLoadingJobsWithCreditUsed]);

  const formatTimestamp = (timestamp: any, plan: string) => {
    if (!timestamp || !timestamp.seconds) return "";

    // Convert Firebase timestamp to JavaScript Date
    let date = new Date(timestamp.seconds * 1000);

    // Add 30 days if plan is "monthly"
    if (plan === "monthly") {
      date.setDate(date.getDate() + 30);
    }
    if (plan === "annual") {
      date.setDate(date.getDate() + 365);
    }

    // Format the date in "DD MMM YYYY"
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <>
      <Meta title="Current Plan" />
      <div className="flex flex-col">
        <div className="flex justify-between w-full items-center">
          <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
            {currentPlanText}
          </p>
          <button
            onClick={toggleShowGetInTouchModal}
            className="text-gray-700 px-8 py-1 rounded-full border-instant-teams-blue-Main border-2 font-[400] text-xl leading-[39.62px]"
          >
            Contact Sales Support
          </button>
        </div>

        {((!employer?.currentPlan && employer?.currentPlan == null) && (employer?.annualPlan != null)) && (
          <div
            className="w-full min-h-[60px] h-auto bg-[#FFD2D2] mt-[30px] mb-[15px] rounded-[10px] p-[10px] text-[#E02F2F] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] flex flex-row justify-between items-center gap-[10px]"
            style={{ boxShadow: "0px 0px 34px 0px #1E596D0A" }}
          >
            <p>
              Your annual subscription has automatically expired, To continue using our service, a new plan purchase is required.
            </p>

            <button
              className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] bg-[#15415E] text-[#FFFFFF] w-[250px] min-h-[40px] h-auto rounded-[70px] px-[21px] py-[10px] whitespace-normal"
              onClick={onSubmitBuyMoreAnnualCreditsModal}
            >
              Speak with an Account Executive
            </button>
          </div>
        )}

        {((employer?.stripeData?.subscriptionId &&
          employer?.stripeData?.subscriptionStatus === "past_due") ||
          (!employer?.stripeData?.subscriptionId &&
            employer?.stripeData?.subscriptionStatus === "canceled" &&
            employer?.stripeData?.reason !== "cancellation_requested")) && (
          <div
            className="w-full min-h-[60px] h-auto bg-[#FFD2D2] mt-[30px] mb-[15px] rounded-[10px] p-[20px] text-[#E02F2F] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] flex flex-row justify-between items-center gap-[10px]"
            style={{ boxShadow: "0px 0px 34px 0px #1E596D0A" }}
          >
            <p>
              Your subscription is currently paused due to a payment issue.
              Please update your card Information.
            </p>

            <button
              className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] bg-[#15415E] text-[#FFFFFF] w-[150px] min-h-[40px] h-auto rounded-[70px] px-[21px] py-[10px] whitespace-nowrap"
              onClick={handleUpdateCardClick}
            >
              Update Card
            </button>
          </div>
        )}

        <div className="flex flex-col mt-[20px] gap-[20px]">
          <div
            className={cn(
              "flex flex-row flex-wrap w-full bg-[#FFFFFF] h-auto min-h-[300px] rounded-[20px] p-[40px] gap-[30px]",
            )}
            style={{
              boxShadow: "0 0 34px rgba(30, 89, 109, 0.04)",
            }}
          >
            <div className="flex flex-col">
              <div className="h-[172px] w-[266px] flex justify-center items-center mt-[-20px] mb-[20px]">
                <SemiCircularProgress
                  usedCredits={usedCredits.length}
                  remainingCredits={remainingCreditsCount}
                  totalCredits={totalCredits.length}
                />
              </div>
              <div className="flex flex-row rounded-[20px] border-[1px] border-[rgba(138, 160, 174, 0.5)] p-[20px] justify-between w-[266px] font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] text-[#15415E]">
                <p>Total Credits:</p>
                <p>{totalCredits.length}</p>
              </div>
            </div>

            <div className="flex flex-col gap-[15px] border-[1px] border-[rgba(138, 160, 174, 0.5)] rounded-[20px] w-[266px] h-[237px] p-[20px] text-[#15415E]">
              <div className="flex flex-row justify-between font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]">
                {/* <p>Used Credits:</p>
                <p>{usedCredits.length}</p> */}
                <p>Remaining Credits:</p>
                <p>{remainingCreditsCount}</p>
              </div>
              <div className="h-[1px] bg-[#C8E3FB] w-full"></div>

              <div className="flex flex-1 flex-col gap-[15px] text-[#15415E] font-[400] overflow-y-auto pr-[10px] mr-[-10px]">
                {creditExpiryData.map((creditExpiry: any) => {
                  return (
                    <div
                      key={creditExpiry.timestamp}
                      className="flex flex-row justify-between items-center"
                    >
                      <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]">
                        {creditExpiry.credits}
                      </p>
                      <p className="font-[Gilroy-Medium] font-[400] text-[12px] leading-[14.56px]">
                        Expires On:{" "}
                        {moment(creditExpiry.timestamp, "X").format(
                          "DD MMM YYYY",
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>

              {remainingCreditsCount === 0 && (
                // {employer?.currentPlan && remainingCreditsCount === 0 && (
                <button
                  className="bg-[#15415E] w-full rounded-[70px] text-[#FFFFFF] h-[40px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] disabled:bg-[#8e9ba3]"
                  onClick={handleBuyMoreCredits}
                  // disabled={!employer?.currentPlan}
                >
                  Buy More Credits
                </button>
              )}
            </div>

            <div className="min-w-[686px] w-auto h-[236px] rounded-[15px] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] flex-1 flex flex-col text-[#FFFFFF] p-[20px]">
              <div className="flex flex-row justify-between items-center">
                {!employer?.currentPlan && (
                  <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                    <span className="font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] ">
                      You currently have no active plan.{" "}
                      {subscriptionExpiry && remainingCreditsCount !== 0 && (
                        <>
                          Your available credits are valid until{" "}
                          {moment(subscriptionExpiry, "X").format(
                            "DD MMM YYYY",
                          )}
                          .
                        </>
                      )}
                    </span>
                  </p>
                )}

                {employer?.currentPlan === "single" && (
                  <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                    <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                      Fuel Your Talent <br /> Pipeline with Ease
                    </span>
                  </p>
                )}

                {employer?.currentPlan === "monthly" && (
                  <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                    <span className="font-[Gilroy-Medium] font-[400]  text-cetner  text-[20px] leading-[24.26px] ">
                      Empower Your Hiring Strategy, Month After Month
                    </span>{" "}
                    <br />
                    <span className="font-[Gilroy-Medium] text-cetner  font-[400] text-[16px] leading-[24.26px] mt-1 block">
                      Auto Renewal Date:{" "}
                      {formatTimestamp(
                        employer?.lastMonthlyCreditAdded,
                        employer?.currentPlan,
                      )}
                    </span>{" "}
                  </p>
                )}

                {employer?.currentPlan === "annual" && (
                  <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                    <span className="font-[Gilroy-Medium] font-[400]  text-cetner  text-[20px] leading-[24.26px] ">
                      Your Gateway to <br /> Strategic Hiring Success
                    </span>
                    <span className="font-[Gilroy-Medium] text-cetner  font-[400] text-[16px] leading-[24.26px] mt-1 block">
                      Contract Expiration Date:{" "}
                      {formatTimestamp(
                        employer?.annualPlan?.startDate,
                        employer?.currentPlan,
                      )}
                    </span>{" "}
                  </p>
                )}

                {["monthly", "annual"].includes(employer?.currentPlan) && (
                  <button
                    className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] tracking-wider"
                    onClick={handleCancelPlan}
                  >
                    Cancel Plan
                  </button>
                )}
              </div>

              <div className="flex flex-col flex-1 gap-[10px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider mt-[20px]">
                {employer?.currentPlan === "single" && (
                  <>
                    <BulletTextWrapper>
                      Includes one 30-day job post
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Utilize anytime within a year
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Features employer profile and logo
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Funnels candidates directly to your ATS
                    </BulletTextWrapper>
                  </>
                )}

                {employer?.currentPlan === "monthly" && (
                  <>
                    <BulletTextWrapper>
                      Includes three (3) 30-day job post credits each month
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Credits refresh monthly to maintain active participation
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Features employer profile and logo
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Funnels candidates directly to your ATS
                    </BulletTextWrapper>
                    <BulletTextWrapper>
                      Automatic renewal and billing, cancel anytime
                    </BulletTextWrapper>
                  </>
                )}

                {employer?.currentPlan === "annual" && (
                  <>
                    <BulletTextWrapper>Customizable Packages</BulletTextWrapper>
                    <BulletTextWrapper>Seamless Renewals</BulletTextWrapper>
                    <BulletTextWrapper>
                      Expert Partnership & Sales Support
                    </BulletTextWrapper>
                  </>
                )}
              </div>

              {/* {employer?.currentPlan && (
                <button className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] mb-[5px] tracking-wider">
                  See All Benefits
                </button>
              )} */}
            </div>
          </div>

          <p className="text-[#15415E] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px]">
            Credits Used
          </p>

          <div>
            <DataTable
              columns={columns}
              data={jobsWithCreditUsedData || []}
              isloading={isLoadingJobsWithCreditUsed}
              isInactiveSelected={false}
              /* Commenting onRowClick Prop as it can be used later on */
              // onRowClick={(row) => {
              //   console.log(row, "========>row");
              // }}
            />
          </div>
        </div>
      </div>
      {isBuyMoreCreditsModalVisible && (
        <DialogWrapper
          title="Buy Credits"
          onClose={closeBuyMoreCreditsModal}
          displayCloseIcon={true}
          size="max-w-[1000px]"
        >
          <BuyCredits
            currentSubscription={employer?.currentPlan || ""}
            employerId={employerId}
            onDone={closeBuyMoreCreditsModal}
          />
        </DialogWrapper>
      )}

      {showGetInTouchModal && (
        <DialogWrapper
          title="Get In Touch"
          onClose={toggleShowGetInTouchModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[630px]"
        >
          <ContactSalesSupport
            onCancel={toggleShowGetInTouchModal}
            onDone={toggleShowGetInTouchModal}
            source={"contactSalesSupport"}
            type={"request"}
          />
        </DialogWrapper>
      )}
      {isBuyMoreAnnualCreditsModalVisible && (
        <DialogWrapper
          title="&nbsp;"
          onClose={closeBuyMoreAnnualCreditsModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={false}
          size="max-w-[630px]"
        >
          <BuyCreditsAnnualPlanModal
            onSubmit={onSubmitBuyMoreAnnualCreditsModal}
          />
        </DialogWrapper>
      )}
      {isCancelMonthlyPlanModalVisible && (
        <DialogWrapper
          title=""
          onClose={closeCancelMonthlyPlanModal}
          displayCloseIcon={false}
          size="max-w-[530px]"
        >
          <CancelMonthlyPlanModal
            onCancel={() => setIsCancelMonthlyPlanModalVisible(false)}
            onProceed={onProceedCancelMonthlyPlanModal}
            employer={employer}
          />
        </DialogWrapper>
      )}
      {isCancelAnnualPlanModalVisible && (
        <DialogWrapper
          title="&nbsp;"
          onClose={closeCancelAnnualPlanModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={false}
          size="max-w-[630px]"
        >
          <CancelAnnualPlanModal onSubmit={onSubmitCancelAnnualPlanModal} />
        </DialogWrapper>
      )}
      {isAnnualSpeakWithAccountExecutiveModalVisible && (
        <DialogWrapper
          title="Get In Touch"
          onClose={closeAnnualSpeakWithAccountExecutiveModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[630px]"
        >
          <SpeakWithAccountExecutiveModal
            onCancel={onCancelAnnualSpeakWithAccountExecutiveModal}
            onDone={onSubmitAnnualSpeakWithAccountExecutiveModal}
            type={speakWithAccountExecutiveModalType}
            source={getAnnualSpeakWithAccountExecutiveModalSource}
          />
        </DialogWrapper>
      )}
      {isAnnualSpeakWithAccountExecutiveThankYouModalVisible && (
        <DialogWrapper
          title=""
          onClose={closeAnnualSpeakWithAccountExecutiveThankYouModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[680px]"
        >
          <SpeakWithAccountExecutiveThankYouModal
            onDone={onSubmitAnnualSpeakWithAccountExecutiveThankYouModal}
          />
        </DialogWrapper>
      )}
    </>
  );
};

// @ts-ignore
const columns: ColumnDef<CurrentPlanDatatable>[] = [
  {
    accessorKey: "jobTitle",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Job Title
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("jobTitle")}</div>;
    },
  },
  {
    accessorKey: "jobStatus",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Job Status
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      const jobStatusValue: string = row.getValue("jobStatus");

      let jobStatusValueToShow = jobStatusValue;

      switch (jobStatusValue) {
        case "draft":
          jobStatusValueToShow = "Draft";
          break;
        case "on_hold":
          jobStatusValueToShow = "On Hold";
          break;
        case "live":
          jobStatusValueToShow = "Live";
          break;
        case "closed_job_expired":
          jobStatusValueToShow = "Expired";
          break;
        case "closed_filled":
          jobStatusValueToShow = "Closed";
          break;
        case "closed_cancelled":
          jobStatusValueToShow = "Closed";
          break;
        default:
          break;
      }

      return (
        <div
          className={cn(
            "rounded-[50px] w-[94px] h-[36px] p-[10px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px] flex justify-center items-center",
            {
              "bg-[#CFFFD8] text-[#3DAB51]": jobStatusValue === "live",
              "bg-orange-100 text-[#F7A400]": jobStatusValue === "draft",
              "bg-[#DBDBFF] text-[#6B6BEA]": jobStatusValue === "on_hold",
              "bg-[#FFD2D2] text-[#E02F2F]": [
                "expired",
                "closed_job_expired",
                "closed_cancelled",
                "closed_filled",
              ].includes(jobStatusValue),
            },
          )}
        >
          <p className="first-letter:uppercase">{jobStatusValueToShow}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "postedBy",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Posted By
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("postedBy")}</div>;
    },
  },
  {
    accessorKey: "postingDate",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Posting Date
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("postingDate")}</div>;
    },
  },
  {
    accessorKey: "expiringDate",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Expiring Date
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("expiringDate")}</div>;
    },
  },
];

export default CurrentPlan_NewUI;
