import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookupActive } from "../../../../../../data/lookup";
import { JobsFormSchemaStep4 } from "./JobFormSchemaStep4";
import { useNavigate } from "react-router-dom";
import {
  getIsSubmittingJobFirstTime,
  updateEntryForJobDetailsNewCollection,
  useJobDetailsNewCollection,
} from "../../../../../../data/jobDetailsNewCollection";

import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";

import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import { useMemo } from "react";
import InputCurrency_Emp from "../../Component-UI/InputCurrency_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";
import Select from "../../../../../custom-ui/new-ui/Select";

function JobInformation({
  currStep,
  setCurrentStep,
  setFormvalues,
  formvalues,
}: any) {
  const params = useParams();
  const jobId = params.id ?? "";
  const navigate = useNavigate();

  const [isInitialized, setIsInitialized] = useState(false);
  const [formSchema, setFormSchema] = useState(JobsFormSchemaStep4(false));

  const { data: job } = useJobDetailsNewCollection(jobId);

  const { data: payRateTypes = [] } = useLookupActive("payRateType");
  const isFieldLocked = isLockedJobNew(job?.jobState);

  const lockedJobStatusToolTipText = useMemo(() => {
    let jobState = job?.jobState;

    if (["closed_filled", "closed_cancelled"].includes(jobState)) {
      jobState = "closed";
    } else if (jobState === "on_hold") {
      jobState = "on hold";
    } else {
      jobState = "live";
    }
    return jobState;
  }, [job]);

  const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

  type FormValues = z.infer<ReturnType<typeof JobsFormSchemaStep4>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      payRateType: formvalues?.payRateType || "",
      salaryMin: formvalues?.salaryMin || "",
      hourlyMin: formvalues?.hourlyMin || "",
      hourlyMax: formvalues?.hourlyMax || "",
      isCommission: formvalues?.isCommission || false,
      commission: formvalues?.commission || "",
      unpaidInternship: formvalues?.unpaidInternship || "",
      salaryMax: formvalues?.salaryMax || "",
      additionalBenifits: "",
    },
    reValidateMode: "onBlur",
  });

  const isSubmittingJobFirstTime = useMemo(
    () => getIsSubmittingJobFirstTime(job),
    [job],
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  // Watch for the value of payRateType
  const payRateType = watch("payRateType");
  // Watch for the value of isCommission
  const isCommission = watch("isCommission");
  // Watch for the value of aspectsOfJob

  const additionalBenifits_watch = watch("additionalBenifits");

  useEffect(() => {
    if (additionalBenifits_watch) {
      setValue("commission", "");
    }
  }, [additionalBenifits_watch]);

  const onSubmit = async ({ ...values }: FormValues) => {
    console.log(values);

    setFormvalues({
      ...formvalues,
      payRateType: values.payRateType,
      salaryMin: values.salaryMin || "",
      salaryMax: values.salaryMax || "",
      hourlyMax: values.hourlyMax || "",
      hourlyMin: values.hourlyMin || "",
      isCommission: values.isCommission || "",
      commission: values.commission || "",
      additionalBenifits: values.additionalBenifits || "",
    });
    setCurrentStep(currStep + 1);
  };

  const setIntialValuesToForm = (jobData: any) => {
    if (!jobData || (jobData && !Object.keys(jobData).length)) return;
    setValue("payRateType", jobData?.payRate?.type ?? "");
    setValue("jobName", jobData?.jobName ?? "");
    setValue("solutionType", job?.solutionType ?? "");
    setValue("startDate", job?.startDate?.toDate() ?? "");
    setValue("openings", jobData?.openings ?? "");
    setValue("applicationFlow", jobData?.applicationFlow ?? "");
    setValue("workSettings", jobData?.workSettings ?? "");
    setValue("workTerm", jobData?.workTerm ?? "");
    setValue("workType", jobData?.workType ?? "");
    setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
    setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
    setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);
    setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
    setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
    setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");

    if (
      jobData?.jobDescription?.dayInLife &&
      jobData?.jobDescription?.dayInLife.length
    ) {
      setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
    }

    setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

    setTimeout(() => {
      if (jobData?.payRate?.type === "salary") {
        setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
        setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
      } else if (jobData?.payRate?.type === "hourly") {
        setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
        setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
      } else if (jobData?.payRate?.type === "commission") {
        setValue("commission", jobData?.payRate?.commission ?? "");
      } else if (jobData?.payRate?.type === "unpaid_internship") {
        setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
      }

      setTimeout(() => {
        setValue(
          "isCommission",
          jobData?.payRate?.commission?.length ? true : false,
        );

        setTimeout(() => {
          if (jobData?.payRate?.type === "salary") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          } else if (jobData?.payRate?.type === "hourly") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          }

          setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);

          setValue("aboutThisJob", jobData?.jobDescription?.aboutThisJob ?? []);

          setValue(
            "mustHavesCheckboxes",
            jobData?.jobDescription?.mustHavesCheckboxes ?? [],
          );

          if (jobData?.jobDescription?.aspectsOfJobOther?.length) {
            setValue("isAspectsOfJobOther", true);

            setTimeout(() => {
              setValue(
                "aspectsOfJobOther",
                jobData?.jobDescription?.aspectsOfJobOther ?? "",
              );
            }, 100);
          } else {
            setValue("isAspectsOfJobOther", false);
          }
        }, 100);
      }, 500);
    }, 1000);
  };

  const handleAddAboutThisJob = (
    code: string,
    labelLeft: string,
    labelRight: string,
    sliderValue: string,
    remove: boolean,
  ) => {};

  useEffect(() => {
    if (job && !isInitialized) {
      setIntialValuesToForm(job);
      setIsInitialized(true);
    }
  }, [job, isInitialized]);

  // Handle the statusId change and dynamically update the schema
  useEffect(() => {
    if (isSubmittingJobFirstTime) {
      const newSchema = JobsFormSchemaStep4(isSubmittingJobFirstTime);
      setFormSchema(newSchema);
      setIntialValuesToForm(job);
    } else {
      const newSchema = JobsFormSchemaStep4(false);
      setFormSchema(newSchema);
    }
  }, [isSubmittingJobFirstTime, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-[60vw] flex flex-col justify-center items-center ">
          <div className="w-[50vw]  ">
            <div className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 ">
              <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                Pay Rate<span className="text-red-500 text-base ml-1">*</span>
              </h1>

              <Controller
                control={control}
                name="payRateType"
                render={(field) => (
                  <RadioGroup_Emp
                    {...field}
                    options={payRateTypes}
                    disabled={isFieldLocked}
                    error={errors?.payRateType?.message}
                    required
                  />
                )}
              />

              {payRateType === "salary" && (
                <div className="flex mt-2 flex-col">
                  <div className="flex w-[71%] mt-4">
                    <Controller
                      control={control}
                      name="salaryMin"
                      render={(field) => {
                        return (
                          <InputCurrency_Emp
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMin?.message}
                            required
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name="salaryMax"
                      render={(field) => {
                        const { value, onChange, ...restField } = field;

                        return (
                          <InputCurrency_Emp
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex   mt-2"></div>
                  <div className="flex flex-col gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForSalary"
                          label="Additional Comp Incentives"
                          disabled={isFieldLocked}
                          error={errors?.commission?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2">
                        <div className="my-5">
                          <Controller
                            control={control}
                            name="additionalBenifits"
                            render={(field) => (
                              <Select
                                {...field}
                                options={[
                                  {
                                    code: "empComm",
                                    description: "PostJob",
                                    group: "emp_postjob",
                                    icon: null,
                                    isActive: true,
                                    label: "Commission",
                                    sortOrder: 1,
                                  },
                                  {
                                    code: "empBO",
                                    description: "PostJob",
                                    group: "emp_PostJob",
                                    icon: null,
                                    isActive: true,
                                    label: "Bonus",
                                    sortOrder: 2,
                                  },
                                ]}
                                display="Incentive Type"
                                placeholder="Select Incentive Type"
                                disabled={isFieldLocked}
                                error={errors?.additionalBenifits?.message}
                              />
                            )}
                          />
                        </div>
                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <Wysiwyg
                              key={additionalBenifits_watch}
                              {...field}
                              rows={10}
                              display={
                                additionalBenifits_watch === "empBO"
                                  ? "Bonus"
                                  : "Commission"
                              }
                              readOnly={isFieldLocked}
                              placeholder={
                                additionalBenifits_watch === "empBO"
                                  ? " Enter Bonus"
                                  : " Enter Commission"
                              }
                              directions=""
                              editorStyle={{ height: "240px" }}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "hourly" && (
                <div className="flex mt-2 flex-col">
                  <div className="flex w-[71%] mt-4">
                    <Controller
                      control={control}
                      name="hourlyMin"
                      render={(field) => {
                        return (
                          <InputCurrency_Emp
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMin?.message}
                            required
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="hourlyMax"
                      render={(field) => {
                        return (
                          <InputCurrency_Emp
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-[20px] mt-4">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForHourly"
                          label="Additional Comp Incentives"
                          disabled={isFieldLocked}
                          error={errors?.commission?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2 ">
                        <div className="my-5">
                          <Controller
                            control={control}
                            name="additionalBenifits"
                            render={(field) => (
                              <Select
                                {...field}
                                options={[
                                  {
                                    code: "empComm",
                                    description: "PostJob",
                                    group: "emp_postjob",
                                    icon: null,
                                    isActive: true,
                                    label: "Commission",
                                    sortOrder: 1,
                                  },
                                  {
                                    code: "empBO",
                                    description: "PostJob",
                                    group: "emp_PostJob",
                                    icon: null,
                                    isActive: true,
                                    label: "Bonus",
                                    sortOrder: 2,
                                  },
                                ]}
                                display="Incentive Type"
                                placeholder="Select Incentive Type"
                                disabled={isFieldLocked}
                                error={errors?.additionalBenifits?.message}
                              />
                            )}
                          />
                        </div>

                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <Wysiwyg
                              key={additionalBenifits_watch}
                              {...field}
                              rows={10}
                              display={
                                additionalBenifits_watch === "empBO"
                                  ? "Bonus"
                                  : "Commission"
                              }
                              readOnly={isFieldLocked}
                              placeholder={
                                additionalBenifits_watch === "empBO"
                                  ? " Enter Bonus"
                                  : " Enter Commission"
                              }
                              directions=""
                              editorStyle={{ height: "240px" }}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "commission" && (
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <Wysiwyg
                        {...field}
                        rows={10}
                        display="Commission"
                        readOnly={isFieldLocked}
                        placeholder={"Enter Commission"}
                        directions=""
                        editorStyle={{ height: "240px" }}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  />
                </div>
              )}

              {payRateType === "unpaid_internship" && (
                <div className="mt-2"></div>
              )}

              {/* job description Begins */}
            </div>
          </div>
        </div>

        <div className="mt-24 w-full flex flex-row justify-between ">
          <button
            type="button"
            disabled={isSubmitting}
            className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
            onClick={() => {
              reset();
              navigate("/jobs");
            }}
          >
            Cancel
          </button>

          <div className="flex justify-center items-center">
            <button
              onClick={() => setCurrentStep(currStep - 1)}
              className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
            >
              Back
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
            >
              {isSubmitting ? "Saving..." : "Next"}
            </button>
          </div>
        </div>
      </form>
      <ToastAlert />
    </>
  );
}

export default JobInformation;
