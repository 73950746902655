import { useState, useMemo } from "react";
import { cn } from "../../../../util/cn";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { employerBillingSchema } from "../registration/Schema/registrationSchema";
import { EmployerNew } from "../../../../dataTypes/Employer";
import { updateEmployerNew } from "../../../../data/employer";
import toast from "react-hot-toast";
import { useLookup } from "../../../../data/lookup";

type BillingProps = {
  onContinue: Function;
  currentPlan: string;
  employerInfo: any;
};

export const employerBillingSchema_II = z.object({
  email: z.string().email({
    message:
      "Please enter a valid email address. Example: username@example.com.",
  }).optional(),
  name: z
    .string({ required_error: "Please enter a full name." })
    .nonempty("Please enter a full name.").optional(),
  title: z.string({ required_error: "Please enter Designation" }).optional(),
  phone: z.string().refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
    message:
      "Please enter a valid phone number. The format should be [+][country code][number].",
  }).optional(),
  state: z.string({ required_error: "Please select State" }).min(1, {
    message: "Please select state",
  }),
  city: z
    .string({ required_error: "Please enter City" })
    .nonempty("Please enter City"),
  address: z.string({ required_error: "Please enter a Address." }).min(1, {
    message: "Please enter Address.",
  }),
  zipCode: z
    .string({ required_error: "Please enter Zip Code" })
    .length(5, { message: "Please enter valid 5-digit Zip Code" }),
});



const Options = [
  {
    id: "billing_same",
    title: "Same as Point of Contact",
  },
  {
    id: "billing_different",
    title: "New Billing Information",
  },
];

const BillingPage = ({ onContinue, currentPlan, employerInfo }: BillingProps) => {
  const [selectedOption, setSelectedOption] = useState("billing_same");
  const employerData = JSON.parse(localStorage.getItem("empPOCData") || "{}");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [pending, setPending] = useState(false);
  const { data: stateList = [] } = useLookup("state");
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control,
    trigger,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      email: employerData?.accounterOwnerDetails?.email || "",
      name: employerData?.accounterOwnerDetails?.name || "",
      title: employerData?.accounterOwnerDetails?.title || "",
      phone: employerData?.accounterOwnerDetails?.phone || "",
      address: "",
      state: "",
      city: "",
      zipCode: "",
    },
    resolver: zodResolver( selectedOption === "billing_different"?employerBillingSchema:employerBillingSchema_II),
  });

  console.log(getValues())
  useEffect(() => {


    if (selectedOption === "billing_different") {
      setIsFormVisible(true);
      reset({
        email: "",
        name: "",
        title: "",
        phone: "",
        address: "",
        state: "",
        city: "",
        zipCode: "",
      });
    } else {
      setIsFormVisible(false);
      reset({
        email: employerData?.accounterOwnerDetails?.email || "",
        name: employerData?.accounterOwnerDetails?.name || "",
        title: employerData?.accounterOwnerDetails?.title || "",
        phone: employerData?.accounterOwnerDetails?.phone || "",
        address: "",
        state: "",
        city: "",
        zipCode: "",
      });
    }

  }, [selectedOption]);

  const handleSelection = async (data: any) => {
    setPending(true);
    let employerCollectionData: EmployerNew;
    if (!isFormVisible) {
      employerCollectionData = {
        // currentPlan: currentPlan,
        accounterOwnerDetails: {
          name: employerData?.accounterOwnerDetails?.name || "",
          email: employerData?.accounterOwnerDetails?.email || "",
          title: employerData?.accounterOwnerDetails?.title || "",
          phone: employerData?.accounterOwnerDetails?.phone || "",
          // acOwnerAddress: data["address"] || "",
          zipCode: data["zipCode"]||""
        },
        billingContact: {
          email: employerData?.accounterOwnerDetails?.email || "",
          name: employerData?.accounterOwnerDetails?.name || "",
          title: employerData?.accounterOwnerDetails?.title || "",
          phone: employerData?.accounterOwnerDetails?.phone || "",
          address: data["address"],
          state: data["state"],
          city: data["city"],
          zipCode: data["zipCode"]
        },
      };
    } else {
      employerCollectionData = {
        // currentPlan: currentPlan,
        billingContact: {
          email: data["email"],
          name: data["name"],
          title: data["title"],
          phone: data["phone"],
          address: data["address"],
          state: data["state"],
          city: data["city"],
          zipCode: data["zipCode"]
        },
      };
    }

    await updateEmployerNew(
      employerInfo?.employerId,
      employerCollectionData,

    )
      .then(() => {
        toast.success("Form Signed");
        onContinue(employerInfo);
      })
      .catch(() => {
        toast.error("There was an error updating Data");
      })
      .finally(() => setPending(false));
  };

  return (
    <div className="max-w-2xl mx-auto p-6 overflow-y-scroll no-scrollbar bg-white">
      {/* Radio Buttons for Billing Options */}
      <div className="relative mb-7">
        <label className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-1 transition-all duration-200">
          Billing Information
        </label>
        <div className="flex flex-row space-x-6 mt-6 pt-6">
          {Options.map((item) => (
            <div key={item.id} className="flex mr-20 items-center">
              <input
                className="h-5 w-5 shadow text-instant-teams-blue-L1 bg-white rounded-full border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                id={item.id}
                name="billingOptions"
                type="radio"
                value={item.id}
                checked={selectedOption === item.id}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label
                className="ml-3 text-lg font-semibold text-instant-teams-blue-Main"
                htmlFor={item.id}
              >
                {item.title}
              </label>
            </div>
          ))}
        </div>
      </div>

      <form
        className="space-y-8 mb-[-50px]"
        onSubmit={handleSubmit(handleSelection)}
        autoComplete="off"
      >
        <div className="relative  mb-7">
          <div className="flex items-center">
            <input
              type="text"
              name="email"
              disabled={isFormVisible ? false : true}
              placeholder=" " // Empty placeholder for floating effect
              ref={register({
                required: "Please enter an email",
              })}
              onBlur={() => {
                trigger("email");
              }}
              className={`peer mt-1 block w-full px-3 inp py-2  ${!isFormVisible ? "text-[#a6a8a9]" : "]"} rounded-none sm:text-sm`}
              style={{ boxShadow: "none", flex: 1 }}
            />
          </div>
          <label
            htmlFor="email"
            className={`absolute -top-4 text-sm ${!isFormVisible ? "text-[#a6a8a9]" : "text-[#15415E]"} text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm`}
          >
            Email <span className="text-red-600">*</span>
          </label>
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="name"
            placeholder=""
            disabled={isFormVisible ? false : true}
            ref={register({
              required: "Please enter your full name.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("name")}
            className={`peer mt-1 block w-full px-3 inp py-2  ${!isFormVisible ? "text-[#a6a8a9]" : "]"} rounded-none sm:text-sm`}
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="name"
            className={`absolute -top-4 text-sm ${!isFormVisible ? "text-[#a6a8a9]" : "text-[#15415E]"} text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm`}
          >
            Full Name <span className="text-red-600">*</span>
          </label>
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="title"
            disabled={isFormVisible ? false : true}
            placeholder=" " // Empty placeholder for floating effect
            ref={register()}
            // onChange={(e) => settitleValue(e.target.value)}
            onBlur={async () => await trigger("title")}
            className={`peer mt-1 block w-full px-3 inp py-2  ${!isFormVisible ? "text-[#a6a8a9]" : "]"} rounded-none sm:text-sm`}
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="title"
            className={`absolute -top-4 text-sm ${!isFormVisible ? "text-[#a6a8a9]" : "text-[#15415E]"} text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm`}
          >
            Job Title
          </label>
        </div>
        <div className="relative mb-7">
          <PhoneInputWithCountry
            className={`peer mt-1 block w-full px-3 inp py-2  ${!isFormVisible ? "text-[#a6a8a9]" : "]"} rounded-none sm:text-sm`}
            style={{ boxShadow: "none" }}
            name="phone"
            international
            disabled={isFormVisible ? false : true}
            defaultCountry="US"
            countryCallingCodeEditable={false}
            control={control}
            //onChange={async () => await trigger("phone")}
            onBlur={async () => await trigger("phone")}
            rules={{
              required: "Phone number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
          />
          <label
            htmlFor="phone"
            className={`absolute -top-4 text-sm ${!isFormVisible ? "text-[#a6a8a9]" : "text-[#15415E]"} text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm`}
          >
            Phone Number <span className="mr-1 text-red-600">*</span>
          </label>
          {errors.phone && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.phone.message}
            </p>
          )}
        </div>
        <div className="relative">
          <input
            type="text"
            name="address"
            placeholder=" " // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your Address.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("name")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="name"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Address Line <span className="text-red-600">*</span>
          </label>
          {errors.address && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.address.message}
            </p>
          )}
        </div>

        <div className="w-full grid grid-cols-2 space-x-4">
          <div className=" relative mb-7">
            <input
              type="text"
              name="city"
              placeholder=" " // Empty placeholder for floating effect
              ref={register({
                required: "Please enter City.",
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("name")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: "none" }}
            />
            <label
              htmlFor="city"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              City <span className="text-red-600">*</span>
            </label>
            {errors.city && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.city.message}
              </p>
            )}
          </div>

          <div className=" ">
            <div className={` ${getValues("state") ? "font-bold w-[80px]" : "w-[140px]"} `}>
              <h1 className="text-[13px] mt-[-9px] z-50 text-[#15415E]">
                State <span className="text-red-500 text-[12px]">*</span>
              </h1>

              <select
                className={`px-3 ml-[-10px] mt-[-5px] text-[13px] border-2 z-10 border-gray-900  ${getValues("stateResidence") ? "font-bold w-[80px]" : "w-[140px]"}  leading-8 focus:ring-1 focus:ring-gray-900 rounded-md`}
                style={{ color: '#15415E', boxShadow: 'none' }}
                name="state"
                ref={register({
                  required: "Please select state.",
                })}
              >
                <option value="" selected disabled>
                  Select State
                </option>
                {stateList?.map((state: any) => (
                  <option key={state.id} value={state.code}>
                    {state.code}
                  </option>
                ))}
              </select>
            </div>
            {/* <hr
                  className={`mt-[-9px] z-50 border-t-2  ${getValues("state") ? "font-bold w-[80px]" : "w-[140px]"}}`}
                /> */}

            {errors.state && (
              <p className=" text-sm  mt-[-12px] text-left text-red-600">
                {errors.state.message}
              </p>
            )}
          </div>

        </div>


        <div className="relative mb-7  grid grid-cols-2  ">

          <div className=" mt-[-25px] mb-12">
            <input
              type="number"
              name="zipCode"
              placeholder=" "
              maxLength={5}// Empty placeholder for floating effect
              ref={register({
                required: "Please enter your Zip Code.",
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("name")}
              className="peer  block w-full px-3  inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: "none" }}
            />
            <label
              htmlFor="zipCode"
              className="absolute -top-4 mt-[-25px] text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Zip Code <span className="text-red-600">*</span>
            </label>
            {errors.zipCode && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.zipCode.message}
              </p>
            )}
          </div>

        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
            style={{ width: "200px" }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </form>



    </div>
  );
};

export default BillingPage;
