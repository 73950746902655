import React from 'react';
import { cn } from '../../../../../../util/cn';
import BulletTextWrapper from '../CurrentPlan/BulletTextWrapper';

const AnnualSubcriptionCard = ({ isSelected = false, noOfJobs, planAmt, setSelected, handleContinuePlan }: any) => {
    return (
        <div className="mt-3">
            <div
                className={cn(
                    "flex flex-col w-[280px] min-h-[210px] pt-[35px] font-bold pb-[30px] border-[1px] border-[rgba(138, 160, 174, 0.5)] rounded-[20px] items-center cursor-pointer",
                    {
                        "bg-gradient-to-r from-[#0B619A] to-[#4DAAE8]": isSelected,
                    }
                )}
                onClick={() => null}
            >

                <p
                    className={cn(
                        "mt-[10px] font-[Gilroy-Medium] text-center font-[400] text-[16px] leading-[19.41px]",
                        {
                            "text-[#15415E]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    <span className=' font-[Gilroy-Bold] font-[400] text-[40px] '>{noOfJobs}</span>

                </p>
                <p
                    className={cn(
                        " mt-3 font-[Gilroy-Medium] text-center font-[400] text-[16px] leading-[19.41px]",
                        {
                            "text-[#15415E]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    <span>30-day job Posting  <br /> Per Month</span>
                </p>
                <div
                    className={cn(
                        "mt-1 mx-[30px] flex-1 flex flex-col gap-[15px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px]",
                        {
                            "text-[#8AA0AE]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >

                </div>

             

                <button className=' h-[50px] mt-10 w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]'
                    onClick={() => handleContinuePlan(noOfJobs)}
                >
                    Continue
                </button>

            </div>
        </div>
    );
};

export default AnnualSubcriptionCard;

