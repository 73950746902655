import React, { useState } from 'react';
import FilterModal from './FilterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';

const JobsListingHeader = ({ jobsData, setFilteredJobs, bookmarks, filteredJobs, totalLiveJobs, setSortedBy }) => {


    const [showModal, setShowModal] = useState(false);
    const  [optionVal, setOptionVal] = useState()
    const [appliedFilter, setAppliedFilter] = useState(false)
    const [filters, setFilters] = useState({
        workSetting: '',
        workType: '',
        postedBy: '',
        salaryType: '',
        salaryRange: [0],
        multipleOpenings: false,
        bookmarkedJobs: false,
        instantTeamsJobs: false,
    });

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <div className="w-full bg-gray-100 relative">
            <div className="flex justify-between w-full items-center">
                <div onClick={() => setShowModal(true)} className={`flex ${appliedFilter ? "border-[1.5px] mb-1 bg-instant-teams-blue-Main rounded-md p-1 px-2 border-instant-teams-teal-Main" : ""}  items-center cursor-pointer`}>
                    <img className={`${appliedFilter ? 'w-4 h-4 mr-2 object-contain' : 'w-4 h-3 mr-2 object-contain'}`} src={appliedFilter ? "/images/page_info.png" : "/images/filtericon_newui.png"} />
                    <p className={`text-sm font-semibold ${appliedFilter ? "text-white" : "text-instant-teams-blue-Main"} `}>Filter</p>
                </div>
                <div className="text-sm text-gray-600">
                    <div className="relative">
                        <select
                            className={`block  ${ optionVal==="datePosted"? "w-[120px]":"w-full"} p-2 pr-7   text-sm leading-5 bg-gray-100 shadow-none text-gray-700 rounded-md focus:outline-none focus:ring-0 appearance-none`}
                            onChange={(e) => { setSortedBy(e.target.value); setOptionVal(e.target.value)}}
                        >
                            <option value="instanteamsJobs">Instant Teams Jobs Only</option>
                            <option value="datePosted">Most Recent</option>
                        </select>
                       
                    </div>


                </div>
            </div>
            <div className="text-sm mb-2 text-gray-600">{filteredJobs?.length || totalLiveJobs}    Jobs</div>

            {/* Conditional rendering of the modal */}
            {showModal && (
                <>
                    <div
                        className="fixed inset-0  bg-gray-500 bg-opacity-75 z-50"
                        onClick={handleCloseModal} // Clicking outside modal will close it
                    ></div>
                    <div className="absolute xs:top-1  top-20  z-50">
                        <FilterModal jobsData={jobsData}
                            setAppliedFilter={setAppliedFilter}
                            setFilters={setFilters}
                            bookmarks={bookmarks}
                            setSortedBy={setSortedBy}
                            filters={filters}
                            setFilteredJobs={setFilteredJobs}
                            closeModal={handleCloseModal} />
                    </div>
                </>
            )}
        </div>
    );
};

export default JobsListingHeader;
