import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import PrependMeta from './PrependMeta';

function Meta(props) {
  const { children, ...customPageMeta } = props;
  // const router = useRouter();
  const [title, setTitle] = useState(customPageMeta.title);
  const { globalState, setGlobalState } = useGlobalState();

  useEffect(() => {
    setGlobalState({ ...globalState, pageTitle: title });
  }, [title]);

  const location = useLocation();
  const globalMeta = {
    siteName: "Instant Teams Job Marketplace",
    domain: "https://jobs.instantteams.com",
    twitterHandle: "@Instant_Teams",
  };

  const defaultPageMeta = {
    title: "Instant Teams Job Marketplace",
    description: "We know skills-based hiring is the future of work. At Instant Teams, you’re valued for the skills you possess – not just the words on your resume. With our new app, you can apply for remote, in-person, or hybrid jobs; verify your skills; and accept offers. ",
    image: "/images/social.png",
    type: "website",
  };
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  return (
    <>
      <Helmet>
        <title>{customPageMeta.title}</title>
        {/* Open Graph */}
        {meta.domain && !meta.shareUrl && <link rel="canonical" href={`${meta.domain}${location.pathname}${location.search}`} key="canonical" />}
        {meta.shareUrl && <link rel="canonical" href={meta.shareUrl} key="canonical" />}
      </Helmet>
      <PrependMeta name="twitter:title" content={meta.postTitle || meta.title} keyValue="twitter-title"/>
      <PrependMeta name="twitter:description" content={meta.shareDescription} keyValue="twitter-description"/>
      <PrependMeta name="twitter:card" content="summary_large_image" keyValue="twitter-card"/>
      {meta.twitterHandle && <PrependMeta name="twitter:site" content={meta.twitterHandle} keyValue="twitter-site"/>}
      {meta.twitterImage && <PrependMeta name="twitter:image" content={meta.twitterImage} keyValue="twitter-image"/>}
      
      <PrependMeta property="og:title" content={`${meta.postTitle}`} keyValue="og-title"/>
      <PrependMeta property="og:description" content={meta.shareDescription} keyValue="og-description"/>
      <PrependMeta property="og:site_name" content={meta.siteName} keyValue="og-site-name"/>
      <PrependMeta property="og:type" content="website" keyValue="og-type"/>
      {meta.shareUrl? <PrependMeta property="og:url" content={meta.shareUrl} keyValue="og-url"/> : <PrependMeta property="og:url" content={window.location.href} keyValue="og-url"/>}
      <PrependMeta name="og:image" property="og:image" content={meta.images? meta.images : `${meta.domain}/${meta.image}`} keyValue="og-image"/>
      <PrependMeta property="og:image:secure_url" content={meta.images? meta.images : `${meta.domain}/${meta.image}`} keyValue="og-image:secure_url"/>
      <PrependMeta property="og:image:width" content="1200" keyValue="og-image:width"/>
      <PrependMeta property="og:image:height" content="630" keyValue="og-image:height"/>
      <PrependMeta property="og:image:alt" content={meta.postTitle} keyValue="og-image:alt"/>
      <PrependMeta property="og:image:type" content="image/jpg" keyValue="og-image:type"/>
      <PrependMeta content={meta.description} name="description" keyValue="description"/>      
    </>
  );
}

export default Meta;