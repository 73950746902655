import { customDataRouteObject } from "../dataTypes/ReactRouter";
import GeneralInfoPage_NewUI from "../pages/general-info/generalInfo_NewUI";

import MessagesPage from "../pages/messages/MessagesPage";
import NotificationsPage from "../pages/notifications/NotificationsPage_NewUI";
import SettingsPage from "../pages/users/settings";

import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../components/fallback/Fallback";
import SettingsPageNew from "../pages/users/settingsnew";
import MessagesPage_NewUI from "../pages/messages/MessagesPage_NewUI";
import SidebarWrapper_NewUI from "../components/layout/sidebar/SidebarWrapper_NewUI";

export const sharedChildren_newUi: customDataRouteObject[] = [
  {
    id: "FAQ",
    name: "FAQ",
    path: "/faq",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
     
           <GeneralInfoPage_NewUI />
         
       
      </ErrorBoundary>
    ),
  },
  {
    id: "Message",
    name: "Message",
    path: "/messages/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <MessagesPage_NewUI />
      </ErrorBoundary>
    ),
  },
  {
    id: "My Notifications",
    name: "My Notifications",
    path: "/notifications/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotificationsPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Settings",
    name: "Settings",
    path: "/settings/:section",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SettingsPage />
      </ErrorBoundary>
    ),
  },

  {
    id: "SettingsNew",
    name: "SettingsNew",
    path: "/settings/newprofile",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <SettingsPageNew />
        </SidebarWrapper_NewUI>

      </ErrorBoundary>
    ),
  },
  {
    id: "SettingsSeeker",
    name: "SettingsSeeker",
    path: "/settings/seeker/profile",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
          <SettingsPageNew />
      </ErrorBoundary>
    ),
  },

 
];
