import { useState, useEffect, useMemo } from "react";
import { useEmployer } from "../../../../../data/employer";
import { useAuth } from "../../../../../providers/auth";
import DialogWrapper from "../../../../custom-ui/dialog/Dialog_NewUI";
import Meta from "../../../../custom-ui/meta/Meta";
import BillingContactCard from "../Component/BillingInformation/BillingContactCard";
import SavedCardComponent from "../Component/BillingInformation/SavedCardComponent";
import ElementsProvider from "../Component/CurrentPlan/ElementsProvider";
import {
  DataTable,
  IsSortedDataTableIcon,
} from "../../../../custom-ui/new-ui/DataTable";
import { getPaymentHistoryListing } from "../../../../../callable-cloud-functions/cloudFunctions";
import moment from "moment";
import { ColumnDef } from "@tanstack/react-table";
import BillingInformationPagination from "../Component/BillingInformation/BillingInformationPagination";
import { stripePlansPrices } from "../../../../../util/stripe/stripeUtils";

type PaymentHistoryDatatable = {
  id: string;
  paymentDate: number;
  amount: number;
  paymentType: "single" | "monthly";
  creditCardEnding: string;
  paid: boolean;
  receiptUrl: string;
};

type Props = {};

const BillingInformation_NewUI = (props: Props) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoadingPaymentHistory, setIsLoadingPaymentHistory] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isModalRetryPayment, setIsModalRetryPayment] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [datatableDataPerPage, setDatatableDataPerPage] = useState(10);
  const [promoCodeData, setPromoCodeData] = useState(null);

  const handleRetryPayment = () => {
    // we are setting setPromoCodeData such that planPrice for monthly subscription is included in the object and as new subscription is going to be created and previous is going to be cancelled we are sending isPromoCodeApplied false and promotionCode as null
    const planPrice = stripePlansPrices["monthly"];

    setPromoCodeData({
      promotionCode: null,
      planPrice: planPrice,
      isPromoCodeApplied: false,
    });
    setIsModalRetryPayment(true);
  };

  const handleCloseRetryPayment = () => {
    setIsModalRetryPayment(false);
  };

  const fetchPaymentHistoryListing = async () => {
    try {
      setIsLoadingPaymentHistory(true);
      const result = await getPaymentHistoryListing({
        customerId: employer.customerId,
      });

      const data = result.data as PaymentHistoryDatatable[];

      setPaymentHistory(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPaymentHistory(false);
    }
  };

  const paymentHistoryData = useMemo(() => {
    if (isLoadingPaymentHistory) return [];

    const mappedPaymentHistory = paymentHistory?.paymentHistory?.map(
      (paymentHistoryObj: PaymentHistoryDatatable) => {
        return {
          id: paymentHistoryObj.id,
          paymentDate: moment(paymentHistoryObj?.paymentDate, "X").format(
            "DD MMM YYYY",
          ),
          amount: `$${(paymentHistoryObj.amount / 100).toFixed(2)}`,
          paymentType: paymentHistoryObj.paymentType,
          creditCardEnding: paymentHistoryObj.creditCardEnding,
          receiptUrl: paymentHistoryObj.receiptUrl,
          paid: paymentHistoryObj.paid,
        };
      },
    );

    return mappedPaymentHistory;
  }, [isLoadingPaymentHistory]);

  const indexOfLastJob = currentPage * datatableDataPerPage;
  const indexOfFirstJob = indexOfLastJob - datatableDataPerPage;
  const currentPaymentHistoryData = paymentHistoryData?.slice(
    indexOfFirstJob,
    indexOfLastJob,
  );
  const totalPages = Math.ceil(
    paymentHistoryData?.length / datatableDataPerPage,
  );

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleResultsPerPageChange = (e: any) => {
    setDatatableDataPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing results per page
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIsInitialized(true);
      fetchPaymentHistoryListing();
    }
  }, [employer, isInitialized]);

  return (
    <>
      <Meta title="Billing Information" />
      <div className="flex flex-col">
        <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
          Billing Information
        </p>
        {/* {employer?.billingContact} */}
        {!employer?.billingContact && (
          <div className="mt-2 py-4 rounded-lg px-4 w-full opacity-80 bg-[#f5bab3] ">
            <p className="text-red-600 font-[500]">
              Please add your billing information.
            </p>
          </div>
        )}
        {((employer?.stripeData?.subscriptionId &&
          employer?.stripeData?.subscriptionStatus === "past_due") ||
          (!employer?.stripeData?.subscriptionId &&
            employer?.stripeData?.subscriptionStatus === "canceled" &&
            employer?.stripeData?.reason !== "cancellation_requested")) && (
          <div
            className="w-full min-h-[60px] h-auto bg-[#FFD2D2] mt-[30px] -mb-[25px] rounded-[10px] p-[20px] text-[#E02F2F] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] flex flex-row justify-between items-center gap-[10px]"
            style={{ boxShadow: "0px 0px 34px 0px #1E596D0A" }}
          >
            <p>
              Your subscription is currently paused due to a payment issue.
              Please update your card Information and retry payment.
            </p>

            <button
              className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] bg-[#15415E] text-[#FFFFFF] w-[150px] min-h-[40px] h-auto rounded-[70px] px-[21px] py-[10px] whitespace-nowrap"
              onClick={handleRetryPayment}
            >
              Retry Payment
            </button>
          </div>
        )}

        <div className="flex flex-row lg:flex-col md:flex-col mt-[35px] gap-[30px]">
          <BillingContactCard />
          {employer?.currentPlan === "monthly" ||
          (employer?.stripeData?.subscriptionId === null &&
            employer?.stripeData?.subscriptionStatus === "canceled") ? (
            <SavedCardComponent />
          ) : (
            <></>
          )}
        </div>

        <p className="my-[20px] text-[#15415E] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px]">
          Payment History
        </p>

        <div>
          <DataTable
            columns={columns}
            data={currentPaymentHistoryData || []}
            isloading={isLoadingPaymentHistory}
            isInactiveSelected={false}
          />

          <div className="flex-shrink-0">
            <BillingInformationPagination
              handleResultsPerPageChange={handleResultsPerPageChange}
              paginate={paginate}
              currentPage={currentPage}
              datatableDataPerPage={datatableDataPerPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>

      {isModalRetryPayment && (
        <DialogWrapper
          title="Retry Payment"
          onClose={handleCloseRetryPayment}
          displayCloseIcon={true}
          size="max-w-[730px]"
        >
          <ElementsProvider
            employerId={employer.id}
            selectedSubscription="monthly"
            promoCodeData={promoCodeData}
          />
        </DialogWrapper>
      )}
    </>
  );
};

// @ts-ignore
const columns: ColumnDef<PaymentHistoryDatatable>[] = [
  {
    accessorKey: "paymentDate",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Payment Date
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("paymentDate")}</div>;
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Amount
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("amount")}</div>;
    },
  },
  {
    accessorKey: "paymentType",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Payment Type
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      const paymentTypeValue: string = row.getValue("paymentType");

      let paymentTypeValueToShow = paymentTypeValue;

      switch (paymentTypeValue) {
        case "single":
          paymentTypeValueToShow = "One Time";
          break;
        case "monthly":
          paymentTypeValueToShow = "Recurring";
          break;
        default:
          paymentTypeValueToShow = "One Time";
          break;
      }

      return <div>{paymentTypeValueToShow}</div>;
    },
  },
  {
    accessorKey: "creditCardEnding",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Credit Card
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>XXXX XXXX XXXX {row.getValue("creditCardEnding")}</div>;
    },
  },
  {
    accessorKey: "receiptUrl",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        ></button>
      );
    },
    cell: ({ row }) => {
      const paid = row.getValue("receiptUrl");

      return (
        <div>
          {paid ? (
            <a
              href={row.getValue("receiptUrl")}
              target="_blank"
              className="underline"
            >
              View Receipt
            </a>
          ) : (
            <p className="text-[red]">Payment Failed</p>
          )}
        </div>
      );
    },
  },
];

export default BillingInformation_NewUI;
