import { useState, useMemo } from "react";
import { cn } from "../../../../../../util/cn";
import CloudSVG from "../../../../../custom-ui/icons/CloudSVG";

type TCancelAnnualPlanModalProps = {
  onSubmit: () => void;
};

const CancelAnnualPlanModal = ({ onSubmit }: TCancelAnnualPlanModalProps) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center overflow-hidden mt-[-20px]",
        {},
      )}
    >
      <CloudSVG />
      <div className="flex flex-col gap-[15px] items-center mt-[30px]">
        <p className="w-[330px] font-[Gilroy-Bold] font-[400] text-[30px] leading-[37.14px] text-[#15415E] text-center">
          Cancel Plan
        </p>

        <p className="w-[330px] font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[23px] text-[#15415E] text-center">
          Contact your representative to cancel the plan
        </p>
      </div>

      <div className="flex flex-row justify-center items-center mt-[35px]">
        <button
          className="flex justify-center items-center h-[60px] w-[368px] py-[20px] px-[30px] rounded-[70px] bg-[#183852] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
          onClick={onSubmit}
        >
         Ready to Team Up? Let’s Talk!
        </button>
      </div>
    </div>
  );
};

export default CancelAnnualPlanModal;
