import { requireAuth } from "../../providers/auth";
import Meta from "../../components/custom-ui/meta/Meta";
import MessagingCenter from "../../components/messages/MessagingCenter";
import SeekerMessagingCenter_NewUI from "../../components/messages/SeekerMessagingCenter_NewUI";
import { useAuth } from '../../providers/auth';

const MessagesPage_NewUI = () => {
  const { user } = useAuth();
  
  return (
    <div className="bg-white w-full h-full">
      <Meta title="Messages" />
      {(user?.claims?.type == "S")|| (user?.claims?.type == "E")? (
               <SeekerMessagingCenter_NewUI />

      ):( <MessagingCenter />
      )}      
    </div>
  );
};

export default requireAuth(MessagesPage_NewUI);
