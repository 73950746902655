import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { faLock, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { updateAutoSave } from "../../../data/utility";
import { useUser } from "../../../providers/database";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import {
    multiFactor,
} from "firebase/auth";
import useModal from "../../../hooks/useModal";
import PasswordSettings from "./PasswordSettings";
import ProfileSettings from "./ProfileSettings";
import MFASettings from './MFASettings';
import AvatarSettings from "./AvatarSettings";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import {
    reauthenticateWithCredential,
    EmailAuthProvider
} from 'firebase/auth'
import { deleteUserAccount } from "../../../data/user";


function AccountSettings({
    onStatus,

}: {
    onStatus: Function;

}) {
    const auth = useAuth();
    const { user } = auth;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startCounter, setStartCoutner] = useState(false)
    const [counter, setCounter] = useState(5)
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isModalForgetPassword, setIsModalForgetPassword] = useState(false);
    const [isModalProfile, setIsModalProfile] = useState(false);
    const [isModalMFA, setIsModalMFA] = useState(false);
    const [isModalProfileImage, setIsModalProfileImage] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const { data: userData } = useUser(auth?.user?.uid);
    const [avatar, setAvatar] = useState(auth?.user?.auth?.currentUser?.photoURL);
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);
    /// console.log("userData", userData);
    const { isShowing: showDeleteAcMailConfirmation, toggle: toggleshowDeleteAcMailConfirmation } = useModal()
    const { isShowing: showDeleteAcModal, toggle: toggleDeleteAccModal } = useModal();
    const { isShowing: showAlert, toggle: toggleshowAlert } = useModal()
    const { isShowing: showProceedDeleteAcc, toggle: toggleShowProceedDeleteAccl } = useModal()

    const [triggerRefreshUser, setTriggerRefreshUser] = useState(true);
    const toggleTrigger = () =>
        setTriggerRefreshUser((prevState: boolean) => !prevState);

    const [multiFactorUser, setMultiFactorUser] = useState();

    useEffect(() => {

        setMultiFactorUser(multiFactor(user?.auth?.currentUser));

    }, [triggerRefreshUser]);

    const { enrolledFactors = [] } = multiFactorUser ?? {};
    const enrolledPhoneNumber = enrolledFactors?.[0]?.phoneNumber ?? "";





    const unenroll = () => {
        multiFactorUser
            .unenroll(enrolledFactors[0])
            .then(() => {
                // For
                toggleTrigger();
                setIsModalMFA(true)
            })
            .catch((error: any) => {
                if (error.code === "auth/requires-recent-login") {
                    // Handle re-authentication when credentials are too old
                    toast.error(`Login is too old. Please login again.`);
                    onStatus({
                        type: "error",
                        code: error.code,
                        callback: () => { },
                        message: `${error.message}`,
                    });

                } else {
                    setIsModalMFA(true)
                }

            });
    };


    const {

        control,
        setValue
    } = useForm({});

    useEffect(() => {
        if (enrolledPhoneNumber) {
            console.log("MultiFactor User: ", multiFactorUser);
            console.log("Enrolled Phone Number: ", enrolledPhoneNumber);
            setValue("mobilePhone", enrolledPhoneNumber);
        }
    }, [enrolledPhoneNumber, setValue]);

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setIsModalDelete(false);
        setIsModalForgetPassword(false)
        setIsModalProfile(false);
        setIsModalMFA(false);
        setIsModalProfileImage(false);
    };

    const handleDeactivation = async () => {
        await updateAutoSave(collectionNames.users, auth?.user?.uid, {
            status: "closed",
        }).then(() => {
            auth.signout();
        });
    };

    useEffect(() => {
        if (avatar === undefined || avatar == null) {
            setAvatar("/images/default_user.png");
        }
        setAvatar(userData?.photoURL);
    }, [userData]);

    useEffect(() => {
        if (startCounter) {
            const interval = setInterval(() => {
                if (counter > 0) {
                    setCounter((prevCount: any) => prevCount - 1);

                } else {
                    auth.signout();
                    deleteUserAccount(userData?.email)
                    toggleShowProceedDeleteAccl()
                    clearInterval(interval);

                }
            }, 1000);

            return () => clearInterval(interval);
        }

    }, [counter, startCounter]);

    const handleAuthentication = async () => {
        const credentials = EmailAuthProvider.credential(userData?.email, password);
        reauthenticateWithCredential(user, credentials).then(() => { })
            .catch((error) => {
                if (error.code == "auth/multi-factor-auth-required") {
                    toggleshowAlert()
                    toggleShowProceedDeleteAccl()
                } else if (error.code == "auth/too-many-requests") {
                    setError("Too many request attempted. Try again later or reset password")
                }
                else {
                    if (!password) {
                        setError("Enter a password")
                    } else {
                        setError("Incorrect password")
                    }

                }
            })
    }
    const handleDeleteAccount = () => {

        setStartCoutner(true)
        // auth.signout()
        return;
    }

    return (
        <>
            <div className="bg-white xs:w-[83vw] md:w-[80vw] xs:ml-[-18px] rounded-lg shadow xs:p-3 md:p-3 xs:mt-[-30px] md:mt-[-30px] md p-6">
                <div className="flex xs:flex-col md:flex-col  items-center justify-between w-full border-b  pb-4">
                    <div className="flex xs:flex-col md:flex-col items-center space-x-2 relative">
                        <div className="relative w-28 h-28 cursor-pointer" onClick={() => setIsModalProfileImage(true)}>
                            <img

                                className="w-28 h-28 rounded-full object-cover"
                                src={avatar ?? "/images/default_user.png"}
                                alt="Profile"
                            />
                            <img
                                src="/images/editImage.png"
                                alt="Edit"
                                className="absolute bottom-0 right-0 w-6"

                            />
                        </div>


                        <div>
                            <h2 className="text-2xl font-semibold text-[#15415E]">{userData.name}</h2>
                            <div className="flex items-center text-gray-500">
                                <p>{userData.email}</p>
                                <FontAwesomeIcon icon={faLock} className="ml-2" /> {/* Lock icon */}
                            </div>
                        </div>
                    </div>
                    <button className="bg-[#15415E] xs:mt-3 md:mt-3 text-white pl-[59px] py-2 top-0 right-0 mb-14 mr-[-18px] rounded-full flex items-center w-56 h-12" onClick={() => setIsModalProfile(true)}>
                        Edit Profile
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-4 h-4 ml-4"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-7.036a2.5 2.5 0 113.536 3.536L7.5 19.5H4v-3.536l11.732-11.732z" />
                        </svg>
                    </button>
                </div>
                <div className="mt-6 ">
                    <div className="mb-4">
                        <label className="text-gray-600 text-sm">Full Name</label>
                        <p className="border-b  w-3/12 py-2 text-sm">{userData?.name}</p>
                    </div>
                    <div>
                        <label className="text-gray-600 text-sm">Preferred Name</label>
                        <p className="border-b w-3/12 py-2 text-sm">{userData?.preferredName}</p>
                    </div>
                    <div className=" flex ">

                    </div>
                    <div>
                        <label className="text-gray-600 text-sm">State</label>
                        <p className="border-b w-3/12 py-2 text-sm">{userData?.seekerLocationInfo?.stateResidence||"No Data"}</p>
                    </div>
                  { userData?.seekerLocationInfo?.city && <div>
                        <label className="text-gray-600 text-sm">City</label>
                        <p className="border-b w-3/12 py-2 text-sm">{userData?.seekerLocationInfo?.city}</p>
                    </div>}
                   {userData?.seekerLocationInfo?.stateResidence !== "Outside-USA" && <div>
                        <label className="text-gray-600 text-sm">Zipcode</label>
                        <p className="border-b w-3/12 py-2 text-sm">{userData?.seekerLocationInfo?.zipCode||"No Data"}</p>
                    </div>}
                    {userData?.seekerLocationInfo?.stateResidence === "Outside-USA" &&  <div>
                        <label className="text-gray-600 text-sm">Country</label>
                        <p className="border-b w-3/12 py-2 text-sm">{userData?.seekerLocationInfo?.country||"noData"}</p>
                    </div>}
                </div>
            </div>

            <div className="bg-white rounded-lg xs:w-[83vw] xs:ml-[-16px] shadow p-6 mt-6 relative h-44">
                <h3 className="text-lg font-semibold mb-10 text-[#15415E]">Two Factor Authentication</h3>
                {/* <div className="flex items-center mb-10">
        <img
            src="https://flagcdn.com/w320/us.png"
            alt="US Flag"
            className="w-8 h-6"
        />
        <p className="ml-2 border-b py-2">+1 920-672-0466</p>
    </div> */}
                <div className="relative xs:w-[100%] md:w-[100%]  mb-7">

                    <PhoneInputWithCountry
                        className="peer mt-1 block w-3/12 xs:w-full md:w-full px-3 py-2 inp sm:text-sm border-none"
                        style={{ boxShadow: 'none' }}
                        name="phone"
                        international
                        defaultCountry="US"
                        defaultValue={userData?.phone || ""}
                        countryCallingCodeEditable={false}
                        control={control}
                        onChange={(code: any) => setValue("mobilePhone", code)}
                        readOnly

                    />
                    <label
                        htmlFor="phone"
                        className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                    >
                        Phone Number
                    </label>


                </div>
            
                <button
                    className="bg-[#15415E]  text-white absolute top-0 right-0 mt-6 mr-2 px-4 py-2 rounded-full w-56 xs:w-auto md:w-auto xs:h-10 md:h-10 xs:mt-14 md:mt-14 h-12"
                    //onClick={() => setIsModalMFA(true)}
                    onClick={unenroll}
                >
                    Change Number
                </button>
            </div>
         

            {/* Footer Buttons */}
            <div className="flex xs:flex-col md:flex-col  xs:space-y-3 md:space-y-3 justify-between mt-6">
                <button className="bg-[#15415E] text-white px-4 py-2 rounded-full w-64 h-12" onClick={() => setIsModalForgetPassword(true)}>
                    Change Password
                </button>
                <div className="space-x-4 xs:space-x-0 md:space-x-0">
                    <button className="border border-[#15415E] text-[#15415E] px-4 py-2 rounded-full w-64 h-12" onClick={() => { toggleshowDeleteAcMailConfirmation(); setIsChecked(false) }}>
                        Delete My Account    <FontAwesomeIcon icon={faTrashCan} className="ml-2" />
                    </button>
                    <button className="bg-red-600 xs:mt-3 md:mt-3 text-white px-4 py-2  rounded-full w-64 h-12" onClick={() => setIsModalOpen(true)}>
                        Deactivate My Account
                    </button>
                </div>
            </div>


            {isModalOpen && (
                <div className="fixed inset-0 xs:p-4 md:p-4 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform  duration-300 xs:w-full md:w-full ease-in-out bg-white rounded-2xl w-3/12 shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>
                        <div className="flex justify-around space-x-4">

                            <img src="/images/caution.png" alt="App Store" className="w-40" />

                        </div>
                        <h2 className="text-2xl font-bold text-center mb-6 text-[#15415E]">
                            Are you sure you want to<br />
                            deactivate your account?
                        </h2>

                        <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">
                            <ul className="list-disc text-[#15415E]">
                                <li>Disable your profile and remove your content from view.
                                </li>
                                <li>
                                    Prevent you from accessing certain features and services.</li>
                                <li>Disconnect your account from any linked platforms.</li>

                            </ul>
                        </div>
                        <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">

                            <button
                                className="py-2 bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full h-12 rounded-full"
                                onClick={() => closeModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className="py-2 bg-red-600 text-white w-full rounded-full h-12 "
                                onClick={handleDeactivation}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>


            )}


            {isModalDelete && (
                <div className="fixed inset-0 xs:p-4 md:p-4 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform  xs:p10 md:p-4 duration-300 ease-in-out bg-white rounded-2xl w-4/12 shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>

                        <h2 className="text-xl font-bold text-center mb-6 text-[#15415E]">
                            PERMANENTLY DELETE MY ACCOUNT
                        </h2>
                        <p className="text-base text-center text-[#15415E] mb-2">Are you sure you want to permanently delete the account for</p>
                        <p className="text-xl text-center font-bold text-[#15415E] mr-15">{userData.email}</p>

                        <div className="flex space-x-1 mt-7 ml-16">
                            <input
                                type="checkbox"
                                id="myCheckbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="outline-none focus:ring-0 rounded"

                            />

                            <p className="text-base text-[#15415E]" style={{ marginTop: '-5px' }}>I understand that I won't be able to recover my account.</p>
                        </div>



                        <div className="flex  justify-around space-x-10 mt-10 mb-4 mx-5">


                            <button
                                className="py-2 bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full h-12 rounded-full"
                                onClick={() => closeModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className={`py-2 px-2 w-full text-white uppercase ${!isChecked
                                    ? 'bg-red-300 cursor-not-allowed' // Change color and cursor when disabled
                                    : 'bg-instant-teams-red-Main hover:bg-instant-teams-red-L1'
                                    } rounded-full`}
                                onClick={handleDeactivation}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>


            )}

            {isModalForgetPassword && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform duration-300 ease-in-out bg-white rounded-2xl xs:w-[83vw] md:w-[83vw] w-3/12 shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>

                        <PasswordSettings closeModal={closeModal} />



                    </div>
                </div>


            )}

            {isModalMFA && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform duration-300 ease-in-out bg-white rounded-2xl w-3/12 xs:w-[83vw] md:w-[w-83]   shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>
                        {!enrolledFactors[0] && (

                            <MFASettings closeModal={closeModal} callback={toggleTrigger} />

                        )}





                    </div>
                </div>


            )}



            {isModalProfile && (
                <div className="fixed inset-0   flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform duration-300 ease-in-out bg-white xs:w-[83vw] md:w-[83vw] rounded-2xl w-[33vw] shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>
                        <ProfileSettings closeProfileModal={closeModal} />
                    </div>
                </div>


            )}

            {isModalProfileImage && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                    <div
                        className={`relative transform transition-transform duration-300 ease-in-out bg-white rounded-2xl xs:w-[83vw] md:w-[83vw] w-4/12 shadow-lg p-8`}

                    >
                        <button
                            className="absolute top-4 right-4 bg-white p-1 rounded-full"
                            onClick={() => closeModal()}
                        >
                            <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
                        </button>
                        <AvatarSettings closeModal={closeModal} />
                    </div>
                </div>


            )}

            {/* delete account code begins  */}
            {showDeleteAcMailConfirmation &&
                (
                    <DialogWrapper
                        title=""
                        onClose={toggleshowDeleteAcMailConfirmation}
                    >
                        <>
                            <div className="flex items-center text-gray-700 space-x-1 mb-4" >
                                <i className="fa fa-trash text-3xl mr-2" aria-hidden="true"></i>
                                <div className="flex items-center justify-center space-x-2 text-l" >
                                    <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                                </div>
                            </div>

                            <div className=" text-red mt-1">
                                <h5 className=" text-sm text-gray-600 ">
                                    Are you sure you want to permanently delete the account for
                                </h5>
                            </div>
                            <div className=" text-red mt-1">
                                <p className=" text-m text-gray-600  font-bold">
                                    {userData.email}
                                </p>
                            </div>
                            <div className="flex items-center  space-x-1 mb-1 mt-7 ">
                                <input
                                    type="checkbox"
                                    id="myCheckbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className="mr-2 outline-none focus:ring-0 rounded"

                                />

                                <p className="text-sm text-gray-500 ">I understand that I won't be able to recover my account.</p>
                            </div>

                            <div className="flex justify-around space-x-5 mt-10 mx=5 ">
                                <button
                                    className={`py-2 px-2 w-full text-white uppercase ${!isChecked
                                        ? 'bg-red-300 cursor-not-allowed' // Change color and cursor when disabled
                                        : 'bg-instant-teams-red-Main hover:bg-instant-teams-red-L1'
                                        } rounded-lg`}
                                    onClick={() => { toggleDeleteAccModal(); toggleshowDeleteAcMailConfirmation(); }}
                                    disabled={!isChecked}
                                >
                                    Delete My Account
                                </button>
                                <button
                                    className="py-2  px-2  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                                    onClick={() => toggleshowDeleteAcMailConfirmation()}
                                >
                                    CANCEL
                                </button>

                            </div>

                        </>

                    </DialogWrapper>
                )
            }

            {showDeleteAcModal &&
                <DialogWrapper
                    title=""
                    onClose={toggleDeleteAccModal}
                >
                    <>
                        <div className="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
                            <i className="fa fa-trash text-3xl " aria-hidden="true"></i>
                        </div>
                        <div className="flex items-center justify-center space-x-2 text-m" >
                            <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                        </div>
                        <div className="text-center text-red mt-2 mb-2">
                            <h5 className="text-instant-teams-red-Main text-l font-bold">
                                Warning this is permanent and cannot be undone!
                            </h5>
                        </div>
                        <div className="text-gray-500 text-sm mt-3">
                            <p className="mt-2">* All of your applications will be immediately deleted.</p>
                            <p className="mt-2">* Any offers that were released to you will be revoked.</p>
                            <p className="mt-2">* You will no longer be able to log in to Instant Teams Marketplace.</p>
                            <p className="mt-2">* Please note that if you are using this email address on 12M+ community websites, you will no longer be able to access the 12M+ community using this account.</p>
                        </div>

                        <div className="flex justify-around space-x-10 mt-5  mx-5">
                            <button
                                className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
                                onClick={() => { toggleshowAlert(); toggleDeleteAccModal(); }}
                            >
                                Continue
                            </button>
                            <button
                                className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                                onClick={() => toggleDeleteAccModal()}
                            >
                                CANCEL
                            </button>

                        </div>
                    </>

                </DialogWrapper>

            }

            {
                showAlert &&
                <DialogWrapper
                    title=""
                    onClose={toggleshowAlert}
                >
                    <>
                        <div className="flex items-center text-gray-700 justify-center space-x-2 mb-4" >
                            <i className="fa fa-trash text-3xl" aria-hidden="true"></i>
                        </div>
                        <div className="flex items-center justify-center space-x-2 mb-2 text-sm" >
                            <div className="font-bold">PERMANENTLY DELETE MY ACCOUNT</div>
                        </div>
                        <div className="text-gray-500 text-sm mt-3 px-2 ">

                            <p>Please enter the password</p>
                        </div>
                        <div>
                            <div>
                            </div>
                            {/* <SettingsMFA onStatus={props.onStatus}/> */}
                            <div className="px-2 mt-2">
                                <input
                                    className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                                    name="pass"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onFocus={() => setError(false)}
                                />
                                {error && <p className="mt-1 text-sm text-instant-teams-red-Main">{error}</p>}

                                {/* {errors.pass && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.pass.message}
              </p>
            )} */}
                            </div>
                        </div>
                        <div>
                            {/* <MultiFactorEnrollment onStatus={props.onStatus}   callback={()=>{}}
              buttonVariant={"buttonVariant"}/> */}

                        </div>


                        <div className="flex justify-around space-x-10 mt-5 mx-5 mt-8">
                            <button
                                className=" py-2 px-9 w-full text-white uppercase bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 rounded-lg"
                                onClick={() => { handleAuthentication() }}
                            >
                                Continue
                            </button>
                            <button
                                className="py-2  px-9  bg-white uppercase border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
                                onClick={() => toggleshowAlert()}
                            >
                                Cancel
                            </button>

                        </div>
                    </>

                </DialogWrapper>

            }

            {
                showProceedDeleteAcc &&
                <DialogWrapper
                    title=""
                    onClose={toggleShowProceedDeleteAccl}
                >
                    <>

                        <div className="flex items-center justify-center space-x-2 mb-4" >
                            <i className="fa-thin fa-circle-check  text-3xl"></i>
                        </div>
                        <div className="text-center text-red  mb-3">
                            <h5 className="mb-1">Done </h5>
                            <h5 className="text-instant-teams-red-Main text-l font-bold">
                                Your account will be shortly deleted
                            </h5>
                        </div>
                        <div className="text-gray-500 text-center text-sm mt-3 text-center">
                            <p className="mt-2">{`You will be redirected to career seeker portal within next  ${counter}  seconds.`}</p>
                        </div>

                        <div className="flex justify-around space-x-10 mt-5 mx-5">
                            <button
                                className=" py-2 px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black  rounded-lg"
                                onClick={() => handleDeleteAccount()}
                            >
                                Proceed
                            </button>
                            {/* <button
              className="py-2  px-9  bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full rounded-lg"
              onClick={() => toggleShowProceedDeleteAccl()}
            >
              Cancel
            </button> */}

                        </div>
                    </>

                </DialogWrapper>

            }


            <ToastAlert />
        </>
    );
}

export default AccountSettings;