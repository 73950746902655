import { forwardRef } from "react";
import { cn } from "../../../util/cn";
import "./newUiComponentStyles.css";
import LoaderSVG from "../icons/LoaderSVG";

interface PromoCodeInputProps {
  name: string;
  display: string;
  value: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  labelClassName?: string;
  showInvalidPromoCode?: boolean;
  isValidatingPromoCode?: boolean;
  isPromoCodeValid?: boolean;
  promoCode: string;
  onApply: () => void; // Function to handle the Apply button click
  onRemove: () => void; // Function to handle the Apply button click
  onFocus?: () => void; // Function to handle the Apply button click
}

const PromoCodeInput = forwardRef(
  (
    {
      name,
      display,
      value,
      placeholder = " ", // Empty placeholder for floating effect
      readOnly = false,
      maxLength,
      required = false,
      disabled = false,
      error,
      touched,
      labelClassName = "",
      showInvalidPromoCode = false,
      isValidatingPromoCode = false,
      isPromoCodeValid = false,
      promoCode = "",
      onApply,
      onRemove,
      onFocus,
      ...props
    }: PromoCodeInputProps,
    ref: any,
  ) => {
    return (
      <div id={name} className="flex-auto">
        <label
          htmlFor={name}
          className={cn(
            "block text-sm font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#1E596D] mb-[10px]",
            labelClassName,
          )}
        >
          {display}
        </label>

        <div
          className={cn(
            "relative flex items-center bg-[#F6F8F9] rounded-md pl-[10px] pr-[15px] py-[7px] w-full",
            {
              "bg-[#ED353533]": !isPromoCodeValid && showInvalidPromoCode,
            },
            {
              "bg-[#F4F7F8]": isPromoCodeValid,
            },
          )}
        >
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            ref={ref} // forwarding the ref
            className={cn(
              "flex-1 bg-transparent placeholder-[#37373766] text-[#1E596D] text-sm outline-none border-none font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px]",
              {
                "cursor-not-allowed": readOnly,
                "bg-transparent font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] text-[#ED3535]":
                  !isPromoCodeValid && showInvalidPromoCode,
              },
              {
                "font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px]":
                  isPromoCodeValid,
              },
            )}
            style={{ boxShadow: "none" }}
            disabled={readOnly || disabled || isPromoCodeValid}
            onFocus={() => {
              onFocus?.();
            }}
            value={isPromoCodeValid ? `Promo Applied: ${promoCode}` : value}
            {...props}
          />
          {isValidatingPromoCode && <LoaderSVG />}
          {!isPromoCodeValid ? (
            <button
              type="button"
              onClick={onApply}
              className="relative font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] ml-2 bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] bg-clip-text text-transparent after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-gradient-to-r after:from-[#0B619A] after:to-[#4DAAE8]"
              disabled={disabled || isValidatingPromoCode}
            >
              Apply
            </button>
          ) : (
            <button
              type="button"
              onClick={onRemove}
              className="relative font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] ml-2 bg-[#ED3535] bg-clip-text text-transparent after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-[#ED3535]"
              disabled={disabled}
            >
              Remove
            </button>
          )}
        </div>

        {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
      </div>
    );
  },
);

PromoCodeInput.displayName = "PromoCodeInput";

export default PromoCodeInput;
