import { useState } from "react";
import { useParams } from "react-router-dom";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { useUser } from "../../../providers/database";
import { useEffect } from "react";
import { getMemberSince } from "../../../data/login";
import { DisplayDate } from "../../../util/timestamps/timestamp";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import { useListing, useAllListings } from "../../../data/listings";
import {
  useApplicationsByUser,
  createApplication,
} from "../../../data/applications";
import NewConversationModal from "../../messages/NewConversationModal";
import useModal from "../../../hooks/useModal";
import {  usePositionRecord } from "../../../data/jobDescriptions";
import toast from "react-hot-toast";

function SeekerSidebarHeader() {
  const params = useParams();
 

  const [id, setId] = useState(params.seekerId ?? "");
  const [member, setMember] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [invite, setInvite] = useState("");

  const { data: listingData } = useAllListings(id);
  const { data: inviteData } = useListing(invite);
  const { data: currentApplications } = useApplicationsByUser(id);
  const { data: userData, error: userError } = useUser(id);

  const { isShowing: showModal, toggle: toggleModal } = useModal();
  const { isShowing: showConversationModal, toggle: toggleConversationModal } =
    useModal();

  useEffect(() => {
    const getMember = async (id: string) => {
      const memberSince = await getMemberSince(id);
      setMember(memberSince);
    };
    getMember(id);
  }, [id, member]);

  useEffect(() => {
    if (userError) {
      console.log(userError);
    }
  }, [userError]);

  useEffect(() => {
    setId(params.seekerId ?? "");
  }, [params.seekerId]);

  const positionData = usePositionRecord(inviteData?.jobId, inviteData?.positionId)

  function handleInvite() {

    if (inviteData && invite !== "" && positionData) {
      const application = {
        jobId: inviteData.jobId,
        positionId: inviteData.positionId,
        resumeId: id,
        invited: true,
        externalJob: positionData?.data?.urlActive?true:false
      };
      createApplication(application);
      toast.success("Seeker invited successfully")
      toggleModal();
      setInvite("");
      setDataLoaded(false);
    }
  }

  function formatDate(dateString: any) {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear(); // Full year

    return `${day}/${month}/${year}`;
  }

  return (
    <div className="">
      <div className="items-center pt-4 pr-3 flex flex-col">
        <img
          src={userData?.photoURL || "/images/default_user.png"}
          alt={userData?.preferredName}
          className={`rounded-full h-24 w-24  border-solid border-4 mx-auto ${userData?.status !== "active"
              ? " border-instant-teams-red-Main"
              : "border-instant-teams-green-D1"
            }`}
        />
      </div>
      <div className="items-center py-4 pr-3">
        <div className="text-center text-xl font-bold">{userData?.name}</div>
        <div className="text-center">
          <span className="text-sm">({userData?.preferredName})</span>
        </div>
        <div className="text-center mr-3 text-base items-center">
          Last Activity{"  "}:{"  "} {userData?.latestActivityByUser ? DisplayDate(userData?.latestActivityByUser):"No history"}
        </div>
      </div>
      <div className="items-center pl-3 pr-3">
        <AutoSaveStatusSelector
          status={userData?.status}
          collection="Users"
          recId={id}
          required={false}
          name="status"
          display="Status"
          placeholder=""
          directions=""
        />
        <div className="w-full flex-none items-center justify-center mt-2 mb-4">
          <button
            onClick={toggleModal}
            className="w-full flex items-center justify-center py-2 px-4 text-instant-teams-teal-Main font-bold bg-white rounded border-0 hover:text-instant-teams-green-D2 focus:outline-none"
          >
            Invite to Position
          </button>
        </div>
      </div>
      {showConversationModal && (
        <DialogWrapper
          title="Start a Conversation"
          onClose={toggleConversationModal}
        >
          <NewConversationModal
            toggle={toggleConversationModal}
            recipientId=""
          />
        </DialogWrapper>
      )}
      {showModal && (
        <DialogWrapper
          title="Invite Seeker to Listing"
          onClose={() => toggleModal()}
          size="w-1/2 mt-36"
        >
          <>
            <div className="transition-max-height ease-out duration-300 flex overflow-hidden p-5">
              <select
                className="w-full font-bold text-instant-teams-teal-Main"
                onBlur={(event) => {
                  setInvite(event.target.value);
                }}
              >
                {listingData &&
                  listingData.map((item: any) => {
                    const found = currentApplications.find(
                      (record: any) =>
                        record.jobId === item.jobId &&
                        record.positionId === item.positionId,
                    );
                    if (!found) {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.companyName} - {item.title} - {item.subTitle} -{" "}
                          {item.solutionType}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })}
              </select>
            </div>
            <div className="w-full mt-4 px-20">
              {dataLoaded && (
                <div className="grid grid-cols-3 gap-4 w-full items-center">
                  <div>{inviteData?.classification || null}</div>
                  <div>{inviteData?.model || null}</div>
                  <div>{inviteData?.term || null}</div>
                  <div>{inviteData?.positionPayType || null}</div>
                  <div>Hours Per Week: {inviteData?.hoursPerWeek || null}</div>
                </div>
              )}
            </div>
            <div className="w-full mt-10 px-20">
              {dataLoaded ? (
                <div className="w-full items-center">
                  <button
                    className="text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none w-full h-10"
                    onClick={() => handleInvite()}
                  >
                    Invite {userData?.name}
                  </button>
                </div>
              ) : (
                <div className="w-full items-center">
                  <button
                    className="text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none w-full h-10"
                    onClick={() => setDataLoaded(!dataLoaded)}
                  >
                    Search
                  </button>
                </div>
              )}
            </div>
          </>
        </DialogWrapper>
      )}
      <ToastAlert/>
    </div>
  );
}

export default SeekerSidebarHeader;
