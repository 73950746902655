import React, { useEffect, useState } from 'react';
import CardContainer from './CardContainer';
import { jobsDashboard } from '../../../callable-cloud-functions/cloudFunctions';
import LoadingSpinner from './LoadingIndicator/LoadingSpinner';



const JobsCard = () => {
    const [resData, setResData] = useState()
    useEffect(() => {
        (async () => {
            const res = await jobsDashboard();
            setResData(res?.data)

        })();
    }, []);
    const totalActiveJobs = resData?.totalActiveJobs || 0
    const bpoJobs = resData?.bpoJobs || 0
    const directHireJobs = resData?.directHireJobs || 0
    const totalActivePositions = resData?.totalActivePositions || 0
    const applicationFlowOn = resData?.applicationFlowOn || 0
    const applicationFlowOff = resData?.applicationFlowOff || 0

    return (
        <CardContainer bgColor="#E6F1FC" cardTitle={"Jobs"}
            infoText={"Position data across various live jobs. Application Flow ON implies the job application is outside of the marketplace (Eg.JazzHR) "}
        >

            {!resData ? (<div className="w-full h-[80%] items-center flex justify-center  ">
                <LoadingSpinner />
            </div>) : (
                <div className="w-full mt-4">
                    {/* Commenting the below row as it is not required anymore now */}
                    {/* <div className="grid grid-cols-3 gap-4 w-full mt-2">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Positions</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{totalActivePositions}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Application flow <span className='text-[10px]'>ON</span></p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{applicationFlowOn}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Application flow <span className='text-[10px]'>OFF</span> </p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{applicationFlowOff}</h1>
                        </div>
                    </div> */}
                    <div className="grid grid-cols-3 gap-4  w-full mt-4">
                        <div>
                            <p className="text-[#1E596D] text-[12px]">Active Jobs</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{totalActiveJobs}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[13px]">Direct Hire</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{directHireJobs}</h1>
                        </div>
                        <div>
                            <p className="text-[#1E596D] text-[12px]">BPO</p>
                            <h1 className="text-[#1E596D] font-extrabold text-2xl">{bpoJobs}</h1>
                        </div>
                    </div>
                </div>
            )}

        </CardContainer>
    )
}

export default JobsCard;