import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { useUser } from "../../providers/database";
import {
  useAllChatsByGroup,
  useAllChatsByRecipientId,
} from "../../data/messages";
import SeekerMessageTabContents from "./SeekerMessageTabContents";
import SeekerMessageTabContentsEmpty from "./SeekerMessageTabContentsEmpty";
import SeekerMessageTabContents_NewUI from "./SeekerMessageTabContents_NewUI";
import SeekerMessageTabContentsEmpty_NewUI from "./SeekerMessageTabContentsEmpty_NewUI";
import MessagePanel from "./MessagePanel";
import MessagePanel_NewUI from "./MessagePanel_NewUI";
import MessageSend from "./MessageSend";
import MessageSend_NewUI from "./MessageSend_NewUI";
import { useAllActiveMarketings } from "../../data/marketing";
import { Timestamp } from "firebase/firestore";
import Meta from "../custom-ui/meta/Meta";
import { Fragment } from "react/jsx-runtime";
import { cn } from "../../util/cn";

const SeekerMessagingCenter_NewUI = () => {
  const auth = useAuth();
  const employerId = useParams()?.employerId;
  const { userClaims, user } = auth;
  const [value, setValue] = useState("inbox");
  const [allChats, setAllChats] = useState([]);
  const [tacChats, setTACChats] = useState([]);
  const [csssChats, setCSSSChats] = useState([]);
  const [techChats, setTechChats] = useState([]);
  const [selected, setSelected] = useState(null);
  const [random, setRandom] = useState(null);
  const [curatedAds, setCuratedAds] = useState([]);
  const { data: recipientData } = useUser(selected?.recipientId);
  const { data: chats = [] } = useAllChatsByGroup(
    userClaims?.claims?.role,
    value,
  );
  const { data: receivedChats = [] } = useAllChatsByRecipientId(
    user?.id,
    value,
  );
  const { data: marketings } = useAllActiveMarketings();

  function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    setCuratedAds([]);
    if (marketings && marketings?.length > 0) {
      const today = Timestamp.fromDate(new Date());
      for (const ad of marketings) {
        if (ad.schedule.dateRange.startDate <= today) {
          if (
            ad.schedule.dateRange.endDate >= today ||
            ad.schedule.dateRange.endDate === null
          ) {
            setCuratedAds([...curatedAds, ad]);
          }
        }
      }
      if (curatedAds.length > 1) {
        const rndInt = randomIntFromInterval(1, curatedAds.length);
        setRandom(curatedAds[rndInt - 1]);
      } else {
        setRandom(curatedAds[0]);
      }
    }
  }, [marketings]);

  useEffect(() => {
    if (chats?.length === 0 && receivedChats?.length === 0) {
      setAllChats("");
    }
    if (
      (chats && chats?.length > 0) ||
      (receivedChats && receivedChats?.length > 0)
    ) {
      const newChats = [...chats, ...receivedChats];
      const uniqueChats = Array.from(
        new Set(newChats.map((chat) => chat.id)),
      ).map((id) => {
        return newChats.find((chat) => chat.id === id);
      });

      // Sort the uniqueChats array by the lastUpdated timestamp
      const sortedChats = uniqueChats.sort(
        (a, b) => b.lastUpdated - a.lastUpdated,
      );
      setAllChats(sortedChats);
    }
  }, [chats, receivedChats, value]);

  useEffect(() => {
    for (const chat of allChats) {
      if (chat.group === "tac") {
        setTACChats(chat);
      }
      if (chat.group === "csss") {
        setCSSSChats(chat);
      }
      if (chat.group === "sadmin") {
        setTechChats(chat);
      }
    }
  }, [allChats]);

  console.log(selected, "    ")

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center px-4 md:px-20">
      <Meta title="Support" />

      <div className="w-full max-w-4xl text-center pt-10 pb-5 mt-40">
        <div className="px-6 w-full flex justify-start border-b-[1px] border-b-[#C8E3FB]">
          <h1 className="text-[32px] font-bold text-[#15415E] mb-4">Support</h1>
        </div>
      </div>

      {/* Support Cards Section */}
      <div className="w-full max-w-4xl grid xs:grid-cols-1 sm:grid-cols-1 grid-cols-2 gap-6">
        {/* Technical Support Card */}
        {techChats && techChats.length === undefined ? (
          <div key={techChats?.id}>
            <SeekerMessageTabContents_NewUI
              chat={techChats}
              isSelected={selected?.id === techChats?.id}
              onClick={() => setSelected(techChats)}
            />
          </div>
        ) : (
          <SeekerMessageTabContentsEmpty_NewUI type="sadmin" />
        )}

        {/* Recruitment Card */}
        {tacChats && tacChats.length === undefined ? (
          <div key={tacChats?.id}>
            <SeekerMessageTabContents_NewUI
              chat={tacChats}
              isSelected={selected?.id === tacChats?.id}
              onClick={() => setSelected(tacChats)}
            />
          </div>
        ) : (
          <>
            {user?.claims?.type == "E" ? "" : <SeekerMessageTabContentsEmpty_NewUI type="tac" />}
          </>
        )}
      </div>

      {selected?.group === "tac" ? (
        <div
          className={cn(
            "w-full max-w-4xl no-scrollbar relative  p-16 rounded-[20px] mt-[20px]",
            {
              "bg-white": selected,
            },
          )}
        >
          <h1 className="text-instant-teams-teal-D1 text-[18px]">
            If you're applying for a position and have questions—including inquiries about your application status—please reach out to{" "}
            <a
              href="mailto:talent@instantteams.com"
              className="underline text-instant-teams-teal-D1 font-bold hover:text-instant-teams-blue-D2"
            >
              talent@instantteams.com
            </a>{" "}
            for assistance.
          </h1>

        </div>
      ) : (
        <div
          className={cn(
            "w-full max-w-4xl no-scrollbar relative h-[460px] pt-8 rounded-[20px] mt-[20px]",
            {
              "bg-white": selected,
            },
          )}
        >
          {selected ? (
            <>

              <div
                className="overflow-y-scroll overscroll-contain no-scrollbar"
                style={{ maxHeight: "75%" }}
              >
                <MessagePanel_NewUI
                  chat={selected}
                  key={selected?.id}
                  minimize={false}
                />
              </div>
            </>
          ) : (
            <div className="grid grid-cols-10 col-span-8 justify-center items-center w-full">

            </div>
          )}
          {selected && (
            <div
              className="w-full absolute bottom-0 right-0"
            // style={{ maxHeight: "15%" }}
            >
              <MessageSend_NewUI chat={selected} key={selected?.id} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SeekerMessagingCenter_NewUI;
