import { useState, useEffect, Fragment } from "react";
import { useAuth } from "../../../../providers/auth";
import { Transition, Dialog } from "@headlessui/react";
import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { updateAutoSave, updateStatusHistory } from "../../../../data/utility";

import {
  getClosedFilledByTypes,
  getClosedTypes,
  getJobStatesForJobNewStatusUpdate,
} from "../../../../util/search/getLabel";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { getJobStatusNoteNewSchema } from "../../../../schemas/JobStatusNoteNewSchema";
import SelectGeneric from "../../old-ui/SelectGeneric";
import RadioGroup from "../../old-ui/RadioGroup";
import TextArea from "../../old-ui/TextArea";

const CLOSED_TYPES = getClosedTypes();
const CLOSED_FILLED_BY_TYPES = getClosedFilledByTypes();

const INITIAL_VALUES = {
  statusId: "",
  closedType: "",
  closedFilledBy: "",
  closedReason: "",
};
interface StatusNoteNewProps {
  onDone: (message?: string, statusID?: string) => void;
  collection: string;
  id: string;
  jobData: any;
  name: string;
  placeholder?: string;
  status: string;
  required?: boolean;
  directions?: string;
  statusCollection?: any; // Update the type if you know what it is
  minLength?: number;
}

type FormValues = z.infer<ReturnType<typeof getJobStatusNoteNewSchema>>;

const StatusNoteNew = ({
  onDone,
  collection,
  id,
  jobData,
  name,
  placeholder,
  status,
  required,
  directions,
  statusCollection,
  minLength = 10,
}: StatusNoteNewProps) => {
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const [formSchema, setFormSchema] = useState(
    getJobStatusNoteNewSchema(false, false),
  );

  const [options, setOptions] = useState(
    Array.from(getJobStatesForJobNewStatusUpdate(status)) || [],
  );
  const [saved, setSaved] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema), // Initial schema
    defaultValues: INITIAL_VALUES,
  });

  const statusId = watch("statusId");
  const closedType = watch("closedType");

  const onSubmit = async ({ ...values }: FormValues) => {
    let statusIdValue = values.statusId;
    let closedTypeValue = values.closedType;
    if (statusIdValue === status) return;

    if (statusIdValue === "closed") {
      statusIdValue = values.closedType;
    }

    if (
      statusIdValue === "live" &&
      (jobData?.solutionType === null ||
        // jobData?.startDate === null ||
        jobData?.openings === null ||
        // || jobData?.applicationFlow === null ||
        jobData?.workSettings === null ||
        jobData?.workType === null ||
        jobData?.workTerm === null ||
        // jobData?.hoursPerWeek === null ||
        jobData?.payRate === null ||
        jobData?.jobDescription === null
      )
    ) {
      console.log("inside the checks of invalid schema");

      setError("statusId", {
        message: "Please submit the Job Information form first",
      });
      return;
    }

    let isLiveFirstTime = false;

    if (statusIdValue === "live" && jobData.makeLiveDate === null) {
      isLiveFirstTime = true;
    }

    await updateAutoSave(collection, id, {
      ...(statusIdValue !== "closed"
        ? {
            [name]: statusIdValue,
            closedFilledBy: "",
            closedReason: "",
            ...(isLiveFirstTime
              ? {
                  makeLiveDate: new Date(),
                }
              : {}),
          }
        : {
            [name]: statusIdValue,
            ...(closedTypeValue === "closed_filled"
              ? {
                  closedFilledBy: values.closedFilledBy,
                  closedReason: "",
                }
              : {
                  closedFilledBy: "",
                  closedReason: values.closedReason,
                }),
          }),
    });

    const history = {
      alteredBy: user.id,
      effectiveDate: Timestamp.now(),
      from: status,
      status: statusIdValue,
    };
    await updateStatusHistory(collection, id, history);

    setSaved(true);
    onDone("Status Updated", statusIdValue);
    window.location.reload();
  };

  useEffect(() => {
    if (status && !isInitialized) {
      setValue("statusId", status);
      setIsInitialized(true);
    }
  }, [status, isInitialized]);

  // Handle the statusId change and dynamically update the schema
  useEffect(() => {
    if (statusId === "closed") {
      const newSchema = getJobStatusNoteNewSchema(
        true,
        closedType === "closed_filled",
      );
      setFormSchema(newSchema);
      reset(
        {
          ...INITIAL_VALUES,
          statusId: "closed",
          closedType,
        },
        { errors: true, dirtyFields: true },
      ); // Keep current values and reset other state
    } else {
      const newSchema = getJobStatusNoteNewSchema(false, false);
      setFormSchema(newSchema);
    }
  }, [statusId, closedType, reset]);

  return (
    <Transition appear={true} show={true}>
      <Dialog
        className="overflow-y-auto fixed inset-0 z-50"
        onClose={() => onDone()}
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <Dialog.Title className="flex flex-row justify-between items-center text-lg font-semibold leading-6 text-gray-900 mb-4">
                <span>{statusId === "closed" ? "Close Job" : "Status"}</span>
                <button
                  className="w-[48px] h-[48px] rounded-full bg-[#F8F9FA] text-[#183852]"
                  onClick={() => onDone()}
                >
                  X
                </button>
              </Dialog.Title>

              {statusId != "closed" && <hr />}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-[30px]">
                  {statusId != "closed" ? (
                    <>
                      <Controller
                        control={control}
                        name="statusId"
                        render={(field) => (
                          <SelectGeneric
                            {...field}
                            options={options}
                            display="Status Transition"
                            placeholder="Select Status Transition"
                            error={errors?.statusId?.message}
                            required
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="closedType"
                        render={(field) => <input {...field} type="hidden" />}
                      />
                    </>
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name="statusId"
                        render={(field) => <input {...field} type="hidden" />}
                      />

                      <Controller
                        control={control}
                        name="closedType"
                        render={(field) => (
                          <RadioGroup
                            {...field}
                            options={CLOSED_TYPES}
                            error={errors?.closedType?.message}
                            required
                          />
                        )}
                      />

                      {closedType != "" && <hr className="my-[25px]" />}

                      {closedType === "closed_filled" ? (
                        <>
                          <p className="font-[400] text-[20px] text-[#15415E] mb-[20px] leading-[20px]">
                            Filled via
                          </p>
                          <Controller
                            control={control}
                            name="closedFilledBy"
                            render={(field) => (
                              <RadioGroup
                                {...field}
                                className="grid-cols-2"
                                options={CLOSED_FILLED_BY_TYPES}
                                error={errors?.closedFilledBy?.message}
                                required
                              />
                            )}
                          />
                        </>
                      ) : closedType === "closed_cancelled" ? (
                        <Controller
                          control={control}
                          name="closedReason"
                          render={(field) => (
                            <TextArea
                              {...field}
                              display="Reason"
                              placeholder="Please write here"
                              error={errors?.closedReason?.message}
                              required
                            />
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>

                {saved && (
                  <span className="absolute right-4 top-2 text-xs">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "green" }}
                    />
                  </span>
                )}

                {/* <div
                  className={
                    "mb-4 mx-auto w-5/6 text-center" +
                    (formAlert.type === "error" ? " text-red-600" : "") +
                    (formAlert.type === "success" ? " text-green-600" : "")
                  }
                >
                  {formAlert.message}
                </div> */}

                <div className="mt-[30px]">
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="py-2 px-4 w-[200px] rounded-[70px] text-white bg-instant-teams-blue-Main border-0 hover:bg-instant-teams-blue-L1 focus:outline-none z-50"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StatusNoteNew;
