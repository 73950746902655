import { useState } from "react";
import { useAuth } from "../../../providers/auth";
import { useEmployer, updateEmployer } from "../../../data/employer";
import CompanyInfoForm from "./forms/CompanyInfoForm";
import BillingPOCForm from "./forms/BillingPOCForm";
import AddressForm from "../../../components/address/AddressForm";
import CreateJobListingForm from "../../../components/jobs/parts/CreateJobListingForm";
import CreateJobDescriptionForm from "../../../components/jobs/parts/CreateJobDescriptionForm";
import SuccessCircleCheck from "../../../components/custom-ui/success/SuccessCircleCheck";
import ProgressDots from "../../../components/custom-ui/progress-dots/ProgressDots";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function EmployerOnboarding() {
  const auth = useAuth();
  const employerId = auth?.userClaims?.claims?.employerId;

  const [activeStep, setActiveStep] = useState(1);
  const [countdown, setCountdown] = useState(5);

  // use this to share job id across steps 4 and 5 (CreateJobListingForm and CreateJobDescriptionForm).
  const [jobListingId, setJobListingId] = useState();

  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(employerId);

  const handleDone = () => {
    setActiveStep((prevState: number) => prevState + 1);
  };

  // this updates the counter 5 - 0
  const onboardingCounter = () =>
    setInterval(() => {
      setCountdown((prevCountDown: number) => prevCountDown - 1);
    }, 1000);
  // auto sign off after 5 sec
  const completeOnboarding = () => setTimeout(auth.signout, 5000);

  // needs to stay up to date with active step
  // logs out the user when they complete onboarding.
  useEffect(() => {
    if (activeStep === 6) {
      completeOnboarding();
      onboardingCounter();
    }
    return () => {
      clearTimeout(completeOnboarding());
      clearInterval(onboardingCounter());
    };
  }, [activeStep]);

  const renderActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        return (
          <>
            {employer && (
              <CompanyInfoForm employer={employer} onDone={handleDone} />
            )}
          </>
        );
      case 2:
        return (
          <>
            <h2 className="mb-4 uppercase text-left font-semibold text-instant-teams-blue-Main">
              Mailing Address
            </h2>
            <AddressForm
              id={employerId}
              addressLabel="primary"
              onSubmit={updateEmployer}
              onDone={handleDone}
              buttonVariant="arrowButton"
              employerField={true}
            />
          </>
        );

      case 3:
        return (
          <>
            {employer && (
              <BillingPOCForm employer={employer} onDone={handleDone} />
            )}
          </>
        );
      case 4:
        return (
          <>
            {employer && (
              <CreateJobListingForm
                employer={employer}
                onDone={handleDone}
                setJobListingId={setJobListingId}
              />
            )}
          </>
        );

      case 5:
        return (
          <>
            {jobListingId && (
              <CreateJobDescriptionForm
                employer={employer}
                jobListingId={jobListingId}
                onDone={handleDone}
              />
            )}
          </>
        );

      case 6:
        return (
          <div>
            <h2 className="text-center uppercase mb-3 font-semibold text-instant-teams-blue-Main">
              Onboarding Complete
            </h2>
            <SuccessCircleCheck message="Thank you for completing the Customer Onboarding process. Your Talent Acquisition Coordinator will be in contact with you shortly." />
            <p className="mb-8 text-instant-teams-gray-D1 text-center">
              Onboarding will complete automatically in 5 seconds. If you wish
              you can click on the button to complete.
            </p>
            <Link
              to="/auth/signin"
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                auth.signout();
              }}
            >
              <button className="py-2 px-12 bg-instant-teams-blue-Main text-white rounded-lg hover:bg-instant-teams-blue-L1">
                Complete Onboarding in {countdown}s
              </button>
            </Link>
          </div>
        );
    }
  };

  return (
    <>
      <div className="py-12 w-2/3">
        <div className="container mx-auto max-w-lg text-center">
          {/* Forms should load any existing data to handle edits when going backwards */}
          {/* Company Info */}
          {/* Mailing Address - write company info and mailing address here*/}
          {/* Billing POC */}
          {/* Create Job Listing  */}
          {/* Upload and/or write Job Description - write job listing here */}
          {renderActiveStep(activeStep)}
          <div className="mt-4 flex justify-center">
            <ProgressDots totalSteps={5} activeStep={activeStep} />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployerOnboarding;
