import { Helmet } from 'react-helmet';

function CustomMetaForSocialShare({ title, brandImage, shareUrl }) {
    return (
        <>
            <Helmet>
                {/* General Meta */}
                <title>{`${title || "Instantteams Jobs"}`}</title>
                <meta name="description" content="We know skills-based hiring is the future of work. At Instant Teams, you’re valued for the skills you possess – not just the words on your resume. With our new app, you can apply for remote, in-person, or hybrid jobs; verify your skills; and accept offers." />

                {/* Open Graph (Facebook, WhatsApp, Google Hangouts) */}
                <meta property="og:title" content={title || "Instantteams Jobs"} />
                <meta property="og:description" content="We know skills-based hiring is the future of work. Join Instant Teams to apply for remote, in-person, or hybrid jobs, verify your skills, and get hired based on what you can do!" />
                <meta property="og:image" content={brandImage || "/images/InstantTeamsIcon05.png"} />
                <meta property="og:image:alt" content="InstantTeams logo or employer branding" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={shareUrl || "https://instantteams.com/jobs"} />

                {/* Twitter/X Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title || "Instantteams Jobs"} />
                <meta name="twitter:description" content="Instant Teams offers skills-based hiring opportunities. Apply for remote, in-person, or hybrid jobs today!" />
                <meta name="twitter:image" content={brandImage || "/images/InstantTeamsIcon05.png"} />
                <meta name="twitter:image:alt" content="InstantTeams logo or employer branding" />
                <meta name="twitter:site" content="@InstantTeams" />
                <meta name="twitter:creator" content="@InstantTeams" />
                <meta name="twitter:url" content={shareUrl || "https://instantteams.com/jobs"} />

                {/* Additional Meta for Google Search and Hangouts */}
                <meta itemprop="name" content={title || "Instantteams Jobs"} />
                <meta itemprop="description" content="Join Instant Teams to apply for remote, in-person, or hybrid jobs. Get hired based on your skills!" />
                <meta itemprop="image" content={brandImage || "/images/InstantTeamsIcon05.png"} />
                <meta itemprop="url" content={shareUrl || "https://instantteams.com/jobs"} />
            </Helmet>
        </>
    );
}

export default CustomMetaForSocialShare;
