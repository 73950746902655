import { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect } from "react";
import { employerPurchaseInformationSchema } from "../registration/Schema/registrationSchema";
import PromoCodeInput from "../../../custom-ui/new-ui/PromoCodeInput";
import PriceSummary from "./PriceSummary";
import { validateStripePromoCode } from "../../../../callable-cloud-functions/cloudFunctions";
import { stripePlansPrices } from "../../../../util/stripe/stripeUtils";
import LoaderSVG from "../../../custom-ui/icons/LoaderSVG";
import Select from "../../../custom-ui/new-ui/Select";

type TPromoCode = {
  message: string;
  valid: boolean;
  percent_off: number | null;
  amount_off: number | null;
};

type TPurchaseInformationProps = {
  onContinue: Function;
  currentPlan: string;
  employerInfo: any;
  hasQuantity?: boolean;
};

const PurchaseInformationPage = ({
  onContinue,
  currentPlan,
  employerInfo,
  hasQuantity = false,
}: TPurchaseInformationProps) => {
  const [isValidatingPromoCode, setIsValidatingPromoCode] = useState(false);
  const [pending, setPending] = useState(false);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [showInvalidPromoCode, setShowInvalidPromoCode] = useState(false);
  const [promotionCode, setPromotionCode] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [quantityError, setQuantityError] = useState(null);

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [formSchema] = useState(employerPurchaseInformationSchema());

  const planPrice = stripePlansPrices[currentPlan];

  const quantityOptions = useMemo(() => {
    let options = [];

    for (let i = 1; i <= 5; i++) {
      options.push({
        id: i,
        label: i,
      });
    }

    return options;
  });

  type FormValues = z.infer<
    ReturnType<typeof employerPurchaseInformationSchema>
  >;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      promoCode: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    trigger,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async (data: any) => {
    const validatePromoCodeResponse = await validatePromoCode(data.promoCode);

    const { valid, percent_off, amount_off } =
      validatePromoCodeResponse as TPromoCode;

    if (!valid) {
      setError("promoCode", {
        message: "Invalid Promo Code",
        shouldFocus: true,
      });

      setShowInvalidPromoCode(true);

      return;
    }

    setIsPromoCodeValid(true);

    setPromotionCode({
      ...validatePromoCodeResponse,
      planPrice: planPrice * (quantity ? Number(quantity) : 1),
    });

    return;
  };

  const handleOnRemove = () => {
    reset();
    setIsPromoCodeValid(false);
    setShowInvalidPromoCode(false);
    setPromotionCode(null);
  };

  const handleContinue = () => {
    if (hasQuantity && !quantity) {
      setQuantityError("Quantity is required.");
      return;
    }

    const dataToSend = {
      promotionCode,
      planPrice: planPrice * (quantity ? Number(quantity) : 1),
      isPromoCodeApplied: isPromoCodeValid,
      quantity: quantity ? quantity : 1,
    };

    onContinue(dataToSend);
  };

  const validatePromoCode = async (promoCode: string) => {
    try {
      const planQuantity = quantity ? quantity : 1;

      setIsValidatingPromoCode(true);
      const result = await validateStripePromoCode({
        employerId: employerInfo?.employerId,
        promoCode,
        currentPlan,
        quantity: planQuantity,
      });

      const data = result.data as TPromoCode;

      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsValidatingPromoCode(false);
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized]);

  return (
    <div className="max-w-2xl w-full mx-auto p-6 overflow-y-scroll no-scrollbar bg-white">
      <div className="relative mb-10">
        <label className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-0 transition-all duration-200">
          Purchase Information
        </label>
      </div>

      {hasQuantity && (
        <div className="my-[10px]">
          <Controller
            control={control}
            name="quantity"
            render={(field) => (
              <Select
                {...field}
                options={quantityOptions}
                display="Quantity"
                placeholder="Select Quantity"
                error={quantityError}
                required={true}
                onChange={(qty: number) => {
                  setQuantity(qty);
                  setQuantityError(null);
                  handleOnRemove();
                }}
              />
            )}
          />
        </div>
      )}

      <div className="flex flex-col gap-[5px]">
        <Controller
          control={control}
          name="promoCode"
          render={(field) => (
            <PromoCodeInput
              {...field}
              display="Promo Code"
              placeholder="Enter Promo Code"
              error={errors?.promoCode?.message}
              onApply={handleSubmit(onSubmit)}
              onRemove={handleOnRemove}
              showInvalidPromoCode={showInvalidPromoCode}
              isValidatingPromoCode={isValidatingPromoCode}
              isPromoCodeValid={isPromoCodeValid}
              promoCode={promotionCode?.code}
              onFocus={() => setShowInvalidPromoCode(false)}
            />
          )}
        />
        {promotionCode && (
          <p className="text-[#1E596D] font-[Gilroy-Medium] font-[400] text-[14px] leading-[16.98px]">
            Promo Saving{" "}
            {`$${(promotionCode?.discountAmount / 100)?.toFixed(2)}`}
          </p>
        )}
      </div>

      <div className="my-[20px]">
        <PriceSummary
          planPrice={planPrice}
          currentPlan={currentPlan}
          promotionCodeData={promotionCode}
          isPromoCodeValid={isPromoCodeValid}
          promoCode={promotionCode?.code}
          quantity={quantity ? Number(quantity) : 1}
          hasQuantity={hasQuantity}
        />
      </div>

      <div className="flex justify-center mt-[40px]">
        <button
          type="button"
          className="py-[20px] px-[90px] rounded-[70px] text-white font-[Gilroy-SemiBold] focus:outline-none focus:ring-2 bg-[#15415E] text-[16px] leading-[19.6px]"
          disabled={pending || isValidatingPromoCode}
          onClick={handleContinue}
        >
          {pending ? <LoaderSVG /> : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default PurchaseInformationPage;
