export const USA_States = [
  { state: "Alabama", abbreviation: "AL" },
  { state: "Alaska", abbreviation: "AK" },
  { state: "Arizona", abbreviation: "AZ" },
  { state: "Arkansas", abbreviation: "AR" },
  { state: "American Samoa", abbreviation: "AS" },
  { state: "California", abbreviation: "CA" },
  { state: "Colorado", abbreviation: "CO" },
  { state: "Connecticut", abbreviation: "CT" },
  { state: "Delaware", abbreviation: "DE" },
  { state: "District of Columbia", abbreviation: "DC" },
  { state: "Florida", abbreviation: "FL" },
  { state: "Georgia", abbreviation: "GA" },
  { state: "Guam", abbreviation: "GU" },
  { state: "Hawaii", abbreviation: "HI" },
  { state: "Idaho", abbreviation: "ID" },
  { state: "Illinois", abbreviation: "IL" },
  { state: "Indiana", abbreviation: "IN" },
  { state: "Iowa", abbreviation: "IA" },
  { state: "Kansas", abbreviation: "KS" },
  { state: "Kentucky", abbreviation: "KY" },
  { state: "Louisiana", abbreviation: "LA" },
  { state: "Maine", abbreviation: "ME" },
  { state: "Maryland", abbreviation: "MD" },
  { state: "Massachusetts", abbreviation: "MA" },
  { state: "Michigan", abbreviation: "MI" },
  { state: "Minnesota", abbreviation: "MN" },
  { state: "Mississippi", abbreviation: "MS" },
  { state: "Missouri", abbreviation: "MO" },
  { state: "Montana", abbreviation: "MT" },
  { state: "Nebraska", abbreviation: "NE" },
  { state: "Nevada", abbreviation: "NV" },
  { state: "New Hampshire", abbreviation: "NH" },
  { state: "New Jersey", abbreviation: "NJ" },
  { state: "New Mexico", abbreviation: "NM" },
  { state: "New York", abbreviation: "NY" },
  { state: "North Carolina", abbreviation: "NC" },
  { state: "North Dakota", abbreviation: "ND" },
  { state: "Northern Mariana Islands", abbreviation: "MP" },
  { state: "Ohio", abbreviation: "OH" },
  { state: "Oklahoma", abbreviation: "OK" },
  { state: "Oregon", abbreviation: "OR" },
  { state: "Pennsylvania", abbreviation: "PA" },
  { state: "Puerto Rico", abbreviation: "PR" },
  { state: "Rhode Island", abbreviation: "RI" },
  { state: "South Carolina", abbreviation: "SC" },
  { state: "South Dakota", abbreviation: "SD" },
  { state: "Tennessee", abbreviation: "TN" },
  { state: "Texas", abbreviation: "TX" },
  { state: "Trust Territories", abbreviation: "TT" },
  { state: "Utah", abbreviation: "UT" },
  { state: "Vermont", abbreviation: "VT" },
  { state: "Virginia", abbreviation: "VA" },
  { state: "Virgin Islands", abbreviation: "VI" },
  { state: "Washington", abbreviation: "WA" },
  { state: "West Virginia", abbreviation: "WV" },
  { state: "Wisconsin", abbreviation: "WI" },
  { state: "Wyoming", abbreviation: "WY" },
  { state: "Outside USA", abbreviation: "Outside-USA" },
];