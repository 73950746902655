import { useState } from "react";
import useModal from "../../../../hooks/useModal";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import PDFViewer from "../../../pdf/pdfViewer";
import { useParams } from "react-router-dom";
import {
  useFiles,
  deleteFile,
  useOffersByEmployee,
} from "../../../../data/seeker";
import { useEmploymentByEmployee } from "../../../../data/employee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-duotone-svg-icons";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import toast from "react-hot-toast";
import FileSaver from "file-saver";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SeekerFiles = ({ id, setFilesExist, isUploaded = false,handleClose }: { id: string, setFilesExist?:any, isUploaded?: boolean, handleClose?:any }) => {
  const params = useParams();
  const { data: employeeData } = useEmploymentByEmployee(
    params.employeeId || "",
  );
  const { data: files } = useFiles(id);
  useEffect(() => {
  setFilesExist(files)
},[files])
  const [title, setTitle] = useState("");
  const [pdf, setPDF] = useState("");
  const [fileToDelete, setFileToDelete] = useState({});
  const { toggle: viewToggle, isShowing: viewPDF } = useModal();
  const { toggle: deleteFileModalToggle, isShowing: isDeleteFileModalShowing } =
    useModal();
  const { data: offers } = useOffersByEmployee(
    employeeData?.userId,
    employeeData?.employerId,
  );

  function handleClick(file: any) {
    window.open(file?.file?.link, "_blank");
    // setTitle(file?.file?.label);
    // console.log("pdf",file?.file?.link)
    // setPDF(file?.file?.link);
    // viewToggle();
  }

  function handleFileDelete(resumeId: string, fileId: string) {
    deleteFile(resumeId, fileId);
    deleteFileModalToggle();
    toast.success("File Deleted.");
  }

  const handleDownloadOfferFile = (offer: any) => {
    const storage = getStorage();
    const signedOfferLetterRef = ref(
      storage,
      offer?.signedOfferLetterPath
        ? offer.signedOfferLetterPath
        : offer?.offerLetterUrl,
    );
    if (!!offer?.signedOfferLetterPath === false && offer?.offerLeterUrl) {
      return toast.error("Offer letter not found!");
    }
    getDownloadURL(signedOfferLetterRef)
      .then((url) => {
        FileSaver.saveAs(
          url,
          offer?.signedOfferLetterPath
            ? offer.signedOfferLetterPath
            : offer?.offerLetterUrl,
        );
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            console.error("File not found. Please check the file path.");
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.error("You don't have permission to access this file.");
            break;
          case "storage/canceled":
            // User canceled the download
            console.error("Download was canceled.");
            break;
          case "storage/quota-exceeded":
            // The user's storage quota has been exceeded
            console.error("Your storage quota has been exceeded.");
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            console.error("An unknown error occurred. Please try again later.");
            console.error("Server response:", error.serverResponse);
            break;
          default:
            // Handle other errors
            console.error(
              "An error occurred while downloading the file:",
              error,
            );
        }
      });
  };

  return (
    <>
      <div className="cursor-pointer bg-white rounded-lg pt-2">
        {offers &&
          offers.map((offer: any, index: number) => (
            <Tooltip text="Download Offer Letter" position="-top-8 -right-16">
              <div key={index}>
                <div
                  className="pr-4 text-instant-teams-teal-Main rounded-lg shadow-lg mb-2 w-full"
                  onClick={() => {
                    handleDownloadOfferFile(offer);
                  }}
                >
                  <div className="flex justify-between ">
                    <button className="h-full w-full rounded-full text-left px-4 py-2">
                      Offer Letter
                    </button>
                    <FontAwesomeIcon
                      icon={faFile}
                      className="h-5 w-5 my-auto "
                      swapOpacity
                    />
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}
        {files &&
          files.map((file: any, index: number) => (
            <div key={index}>
              <div className="pr-4 text-instant-teams-teal-Main rounded-lg shadow-sm py-2 items-center mb-2 w-full">
                <div className="flex justify-between">
                  <button
                    onClick={() => handleClick(file)}
                    className="h-full w-full rounded-full cursor-pointer text-left px-4 py-2"
                  >
                    {file?.file?.label}
                  </button>
                  <div
                    onClick={() => {
                      deleteFileModalToggle();
                      setFileToDelete(file);
                    }}
                    className="flex items-center cursor-pointer"
                  >

                    <p className="text-red-500 text-xl px-3 py-1">Remove </p>
                    {/* <FontAwesomeIcon
                      icon={faFile}
                      className="h-5 w-5 my-auto"
                      swapOpacity
                    />
                    <button
                      onClick={() => {
                        deleteFileModalToggle();
                        setFileToDelete(file);
                      }}
                      className="rounded-full h-3 w-3 hover:bg-instant-teams-red-L1 bg-instant-teams-red-Main text-white text-xxs -mt-5 -ml-1"
                    >
                      X
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {viewPDF && (
          <DialogWrapper
            title={""}
            size="w-1/2  mt-36"
            onClose={() => {
              handleClose();
            }}
          >
            <>
              <div className="w-full xs:mt-2 md:mt-2  flex justify-between items-start" >
                                    <h1 className='text-instant-teams-blue-Main text-xl text-center font-semibold'>{'        '}</h1>
                                    <h1 className='text-instant-teams-blue-Main text-xl text-center font-semibold'>View Resume</h1>
                                    <div
                                        className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                                        onClick={()=>handleClose()}
                                    >
                                        <FontAwesomeIcon icon={faTimes} color="#565656" />
                                    </div>
                                </div>
             {pdf && <PDFViewer file={pdf} label={title} />}
            </>
           
          </DialogWrapper>
        )}
        {isDeleteFileModalShowing && (
          <DialogWrapper
            title=""
            size="w-1/4 mt-36"
            onClose={() => {
              deleteFileModalToggle();
            }}
          >
            <div>
              <div>
                <p className="w-full mb-5 text-instant-teams-blue-Main text-xl">Delete File</p>
              </div>
              <div className="mb-4">
                Are you sure you want to delete the file{" "}
                {fileToDelete.file.label}?
              </div>
              <div className="w-full flex justify-between">
                <button
                  className="bg-instant-teams-blue-Main hover:bg-instant-teams-teal-L1 text-white mt-2 rounded-lg uppercase px-6 py-2 text-sm"
                  onClick={() => deleteFileModalToggle()}
                >
                  Cancel
                </button>
                <button
                  className="bg-instant-teams-red-Main hover:bg-instant-teams-red-L1 text-white mt-2 rounded-lg uppercase px-6 py-2 text-sm"
                  onClick={() => {
                    handleFileDelete(id, fileToDelete.id);
                  }}
                >
                  Delete File
                </button>
              </div>
            </div>
          </DialogWrapper>
        )}
      </div>
      <ToastAlert />
    </>
  );
};

export default SeekerFiles;
