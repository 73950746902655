import { useEffect, useState } from "react";
import { useAuth } from "../../../providers/auth";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../providers/database";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { useResume } from "../../../data/seeker";
import { useLookup } from "../../../data/lookup";
import { updateAutoSave, setAutoSave, updateAutoSaveForResume } from "../../../data/utility";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";

function WorkPreferences() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);  
    const [selections, setSelections] = useState({
        models: [],
        terms: [],
        classifications: []
    });
    const [trigger, setTrigger] = useState(false);

    const { data: userData } = useUser(auth?.user?.uid);
    const { data: seekerData, error: seekerError } = useResume(auth.user.uid);
    const { data: model = [] } = useLookup("model");
    const { data: term = [] } = useLookup("term");
    const { data: classification = [] } = useLookup("classification");

    useEffect(() => {
        if (seekerData) {
            setSelections({
                models: seekerData.models || [],
                terms: seekerData.terms || [],
                classifications: seekerData.classifications || []
            });
            setTrigger(true);
        }
    }, [seekerData]);

    const handleChange = (e: any) => {
        const { name, value, checked } = e.target;

        setSelections((prevSelections:any) => {
            const currentSelections = prevSelections[name] || [];

            const updatedSelections = checked
                ? [...currentSelections, value]
                : currentSelections.filter((item:any) => item !== value);

            return { ...prevSelections, [name]: updatedSelections };
        });
    };

    const handlePreferences = () => {
  
        if (isEditMode) {
   
            updateAutoSaveForResume(collectionNames.resume, auth.user.id, selections)
                .then(() => {
                    toast.success(`Preferences updated successfully`);
                    setIsEditMode(false);  
                    //window.location.reload();
                })
                .catch((error) => {
                    console.error("Update failed: ", error);
                    toast.error("Failed to update preferences");
                });
   
    }else{
        setIsEditMode(true); 
    }
    };

    const isSelected = (name: string, code: string) => {
        return selections[name]?.includes(code);
    };

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold text-[#15415E]">Work Preferences</h2>
                {/* <button className="flex items-center bg-[#15415E] text-white text-sm px-6 py-2 text-sm rounded-full w-44 h-10" onClick={handlePreferences}>
                    Update Preferences
                </button> */}

                <button className="bg-[#15415E] text-white px-6 py-2 rounded-full flex items-center w-34 h-10"  onClick={handlePreferences}>
                {isEditMode ? 'Save' : 'Edit'}
                {!isEditMode && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-4 h-4 ml-1"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-7.036a2.5 2.5 0 113.536 3.536L7.5 19.5H4v-3.536l11.732-11.732z" />
                </svg>
                )}
              </button>
            </div>

            <div className="mb-6 border-b border-gray-300 pb-4">
                <h3 className="text-lg font-medium text-[#15415E] mb-4">Work Setting</h3>
                {model?.map((setting:any) => (
                    <label key={setting?.id} htmlFor={setting?.id} className="flex items-center mb-3">
                        <span className="text-gray-700 w-60">{setting?.label}</span>
                        <input
                            id={setting?.id}
                            name="models"
                            type="checkbox"
                            value={setting?.code}
                            onChange={handleChange}
                            checked={isSelected("models", setting?.code)}
                            disabled={!isEditMode}
                            className={`form-checkbox h-5 w-5 ml-4 ${!isEditMode ? 'text-gray-400' : 'text-[#15415E]'}`}
                        />
                    </label>
                ))}
            </div>

            <div className="mb-6 border-b border-gray-300 pb-4">
                <h3 className="text-lg font-medium text-[#15415E] mb-4">Work Term</h3>
                {term?.map((terms:any) => (
                    <label key={terms?.id} htmlFor={terms.id} className="flex items-center mb-3">
                        <span className="text-gray-700 w-60">{terms?.label}</span>
                        <input
                            id={terms.id}
                            name="terms"
                            type="checkbox"
                            value={terms?.code}
                            onChange={handleChange}
                            checked={isSelected("terms", terms?.code)}
                            disabled={!isEditMode}
                            className={`form-checkbox h-5 w-5 ml-4 ${!isEditMode ? 'text-gray-400' : 'text-[#15415E]'}`}
                        />
                    </label>
                ))}
            </div>

            <div className="mb-6 border-gray-300 pb-4">
                <h3 className="text-lg font-medium text-[#15415E] mb-4">Work Type</h3>
                {classification?.map((type:any) => (
                    <label key={type?.id} htmlFor={type.id} className="flex items-center mb-3">
                        <span className="text-gray-700 w-60">{type?.label}</span>
                        <input
                            id={type?.id}
                            name="classifications"
                            type="checkbox"
                            value={type?.code}
                            onChange={handleChange}
                            checked={isSelected("classifications", type?.code)}
                            disabled={!isEditMode}
                            className={`form-checkbox h-5 w-5 ml-4 ${!isEditMode ? 'text-gray-400' : 'text-[#15415E]'}`}
                        />
                    </label>
                ))}
            </div>
            <ToastAlert />
        </>
    );
}

export default WorkPreferences;
