import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useShare } from '../../data/share';
import JobListingShare from '../../components/socials/JobsListingDetail_newUi';

const SocialPage_newUI = () => {
  const params = useParams();
  const [shareId, setShareId] = useState(params.shareId || "");
  const{ data: share } = useShare(params.shareId || "");

  return (
    <div className="bg-white w-full z-50">
      <div className="   ">
      {share && share.type === "job" && <JobListingShare id={share.typeId} share={share} />}
      
      </div>
    </div>
  );
};

export default SocialPage_newUI;