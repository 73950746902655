import { useEffect, useState } from "react";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog_NewUI";
import { useAuth } from "../../providers/auth";
import { createNewConversation } from "../../data/messages";
import { useUsersByRole } from "../../data/user";
import TechnicalSupportSvg from "../custom-ui/icons/TechnicalSupportSvg";
import ConnectWithRecruitmentSvg from "../custom-ui/icons/ConnectWithRecruitmentSvg";
import { useUser } from "../../providers/database";

const SeekerMessageTabContentsEmpty = ({ type }: { type: string }) => {
  const userId: string =
    localStorage.getItem("__user_id")?.replace(/"/g, "") || "";
  const [unreadIds, setUnreadIds] = useState([]);
  const { user } = useAuth();
  const { data: userData } = useUser(user?.uid);

  const [defaultText, setDefaultText] = useState("");
  const [avatar, setAvatar] = useState("");
  const [title, setTitle] = useState("");
  const [messageData, setMessageData] = useState("");
  const { isShowing, toggle } = useModal();
  const { data: usersByRole } = useUsersByRole(type);

  const getText = () => {
    switch (type) {
      case "tac":
        setTitle(`Recruitment`);
        setDefaultText(
          `If you have a question regarding a recent job application, interview, or assessment, tell us about it with as much detail as you can.`,
        );
        setAvatar("tac");
        return;

      case "sadmin":
        setTitle(`Technical Support`);
        setDefaultText(
          `If you've found a problem or something isn't working, tell us about it with as much detail as you can. If you have screenshots of the issue, please attach those as well.`,
        );
        setAvatar("helpdesk");
        return;
      default:
        return "";
    }
  };

  useEffect(() => {
    getText();
  }, []);

  const handleCreateConversation = () => {
    const newData = {
      ...messageData,
      body: `New web message from ${userData?.name}`,
      messageType: "group",
      group: type,
      unreadUsers: unreadIds,
      createdByName: userData?.name,
    };
    createNewConversation(newData);
  };

  useEffect(() => {
    setMessageData({
      recipientEmail: userData?.email,
      recipientId: user?.uid,
      recipientName: userData?.name,
      status: "inbox",
      userId: user?.uid,
    });
  }, [user]);

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item: any) => item.id)
      .filter((id: string) => id !== user?.uid);
    if (userIds?.length > 0) {
      setUnreadIds([...userIds]);
    }
  }, [usersByRole, user?.uid]);

  return (
    <>
      <div
        className="bg-white shadow-md p-6 flex space-x-4 rounded-[20px] hover:bg-slate-200"
        style={{ boxShadow: "0px 0px 34px 0px rgba(30, 89, 109, 0.04)" }}
        onClick={() => { type==="tac"?handleCreateConversation(): toggle()}}
      >
        <div className="bg-[#15415E] rounded-[20px]  p-6 w-1/5">
          {avatar === "helpdesk" && (
            <TechnicalSupportSvg className="w-[30px] h-[30px] text-[white]" />
          )}
          {avatar === "tac" && (
            <ConnectWithRecruitmentSvg className="w-[30px] h-[30px] text-[white]" />
          )}
        </div>
        <div className="h-[55px] w-4/5">
          <h2 className="text-[20px] leading-[29.71px] text-[#15415E] font-bold">
            {title}
          </h2>
          <p className="text-[#15415E] text-[12px] leading-[14.56px]">
            {defaultText}
          </p>
        </div>
      </div>
      {isShowing && (
        <DialogWrapper
          title="Support"
          onClose={toggle}
          displayCloseIcon={true}
          size="max-w-[600px]"
        >
          <div>
            <p className="text-[24px] font-[700] leading-[29.71px] text-center text-[#15415E]">
              Connect with {title}
            </p>
            <p className="text-[20px] leading-[24.26px] text-center text-[#A5BDC5] mt-[10px]">
              {defaultText}
            </p>

            <div className="flex justify-center mt-[40px]">
              <button
                type="submit"
                className="py-[17.5px] px-[40px] w-auto rounded-[70px] text-white bg-instant-teams-blue-Main border-0 hover:bg-instant-teams-blue-L1 focus:outline-none z-50"
                onClick={() => handleCreateConversation()}
              >
                Start New Conversation
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
    </>
  );
};

export default SeekerMessageTabContentsEmpty;
