import React from 'react';
import Input from '../../../../../custom-ui/new-ui/Input';

interface AccountOwnerInforCardProps {
    cardTitle: string;
    cardData: Object;
}
const AccountOwnerInforCard = ({ cardTitle = "", cardData = {} }: AccountOwnerInforCardProps) => {


    const formatKey = (key: any) => {
        // Insert space before uppercase letters and capitalize first letter
        return key
            .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
            .replace(/^./, (char: any) => char.toUpperCase()); // Capitalize first letter
    };
    return (
        <div className='w-full h-full mr-10 p-5  bg-white shadow-md rounded-lg' >
            <h1 className='font-[Gilroy-Bold] font-[400] text-[20px] mb-6 leading-[25px] text-[#15415E] uppercase tracking-wide'>{cardTitle} </h1>
            <div className=''>
                {Object.entries(cardData).map(([key, value]) => (
                    <div key={key} className="">
                        <h1 className="text-instant-teams-teal-D1 text-[18px] mb-1 font-[500]">
                            {formatKey(key)}
                        </h1>
                        <p className="text-gray-500 text-lg mb-2">{value}</p>
                        <hr className="bg-gray-200 h-[1px] mb-3" />
                    </div>
                ))}

            </div>
        </div>
    );
}

export default AccountOwnerInforCard;
