import * as z from "zod";

export const jobFormSchemaStep3 = (isSubmittingJobFirstTime: boolean) => {
  return z
    .object({
      workSettings: z
        .union([
          z.literal("remote"),
          z.literal("in_person"),
          z.literal("hybrid"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" && val !== null, {
          message: "Work Setting is required",
        }),

      workType: z
        .union([
          z.literal("full_time"),
          z.literal("part_time"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" && val !== null, {
          message: "Work Type is required",
        }),

      location: z.string().optional(),

      workTerm: z
        .union([
          z.literal("permanent"),
          z.literal("temporary"),
          z.literal("temp_to_perm"),
          z.literal(""),
          z.null(),
        ])
        .refine((val) => val !== "" && val !== null, {
          message: "Work Term is required",
        }),
    })
    .merge(
      z.object({
        hoursPerWeekMin: z
          .union([
            z.string().optional(),
            z
              .number({
                invalid_type_error: "Min (Hrs) must be a number",
              })
              .min(0, { message: "There should be a minimum of 0 Hr" }),
          ])
          .optional(),

        hoursPerWeekMax: z
          .union([
            z.string().optional(),
            z
              .number({
                invalid_type_error: "Max (Hrs) must be a number",
              })
              .max(100, { message: "There should be a maximum of 100 Hrs" }),
          ])
          .optional(),
      })
    )
    .superRefine((data, ctx) => {
      // Ensure location is required when work setting is hybrid
      if (data.workSettings === "hybrid" && (!data.location || data.location.trim() === "")) {
        ctx.addIssue({
          path: ["location"],
          message: "Location is required for hybrid work setting",
          code: "custom",
        });
      }

      // Ensure min and max hours for part-time jobs
      if (data.workType === "part_time") {
        if (data.hoursPerWeekMin === undefined || data.hoursPerWeekMin === "") {
          ctx.addIssue({
            path: ["hoursPerWeekMin"],
            message: "Min (Hrs) is required for part-time jobs",
            code: "custom",
          });
        }

        if (data.hoursPerWeekMax === undefined || data.hoursPerWeekMax === "") {
          ctx.addIssue({
            path: ["hoursPerWeekMax"],
            message: "Max (Hrs) is required for part-time jobs",
            code: "custom",
          });
        }
      }

      const hoursPerWeekMin =
        data.hoursPerWeekMin !== undefined &&
        data.hoursPerWeekMin !== null &&
        data.hoursPerWeekMin !== ""
          ? Number(data.hoursPerWeekMin)
          : null;

      const hoursPerWeekMax =
        data.hoursPerWeekMax !== undefined &&
        data.hoursPerWeekMax !== null &&
        data.hoursPerWeekMax !== ""
          ? Number(data.hoursPerWeekMax)
          : null;

      if (hoursPerWeekMin !== null && hoursPerWeekMax !== null) {
        if (hoursPerWeekMin > hoursPerWeekMax) {
          ctx.addIssue({
            code: "custom",
            message: "Min (Hrs) must be less than or equal to Max (Hrs)",
            path: ["hoursPerWeekMin"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Max (Hrs) must be greater than or equal to Min (Hrs)",
            path: ["hoursPerWeekMax"],
          });
        }
      }
    });
};
