import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentPage from "./PaymentPage";
import { createStripeClientSecretNew } from "../../../../../../callable-cloud-functions/cloudFunctions";
import { useEmployer } from "../../../../../../data/employer";
import { manageConfiguration } from "../../../../../../data/configuration";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.

const appearance = {
  theme: "stripe",
};
// Enable the skeleton loader UI for optimal loading.
const loader = "auto";

type ElementsProviderProps = {
  selectedSubscription: string;
  employerId: string;
  promoCodeData: any;
  hasQuantity?: boolean;
};

const ElementsProvider = ({
  selectedSubscription,
  employerId,
  promoCodeData,
  hasQuantity = false,
}: ElementsProviderProps) => {
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");

  const isPromoCodeApplied = promoCodeData?.isPromoCodeApplied ?? false;

  const { data: employer } = useEmployer(employerId ?? "");

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const configData = await manageConfiguration();
        const publishableKey = configData[0]?.stripe_live
          ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE
          : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

        setStripePromise(loadStripe(publishableKey || ""));

        const result = await createStripeClientSecretNew({
          employerId: employerId,
          newSubscriptionPlan: selectedSubscription,
          isPromoCodeApplied: isPromoCodeApplied,
          promotionCode: promoCodeData?.promotionCode?.code ?? "",
          quantity: promoCodeData?.quantity
            ? Number(promoCodeData?.quantity)
            : 1,
        });

        // Cast the response to the expected type
        const data = result.data as any;

        if (isMounted && data) {
          setClientSecret(data?.clientSecret);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [employerId, selectedSubscription]);

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          <div className="w-full flex flex-row justify-start">
            <PaymentPage
              currentPlan={selectedSubscription}
              employerId={employerId}
              employer={employer}
              isPromoCodeApplied={isPromoCodeApplied}
              promoCodeData={promoCodeData}
              hasQuantity={hasQuantity}
            />
          </div>
        </Elements>
      ) : (
        <div className="flex items-center justify-center bg-gray-100 h-[70vh]">
          <div className="bg-white p-8 rounded shadow-md text-center max-w-md">
            <h1 className="text-2xl font-bold text-gray-800">
              Redirecting you to payments page...
            </h1>
            <p className="mt-4 text-gray-600">
              Please do not refresh or close this page.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ElementsProvider;
