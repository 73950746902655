import { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import AddUserSVG from "../../../../custom-ui/icons/AddUserSVG";
import Meta from "../../../../custom-ui/meta/Meta";
import {
  DataTable,
  IsSortedDataTableIcon,
} from "../../../../custom-ui/new-ui/DataTable";
import { cn } from "../../../../../util/cn";
import { useAuth } from "../../../../../providers/auth";
import {
  useEmployer,
  useEmployerSideUsersByEmployerId,
} from "../../../../../data/employer";
import DeleteTrashBoxSVG from "../../../../custom-ui/icons/DeleteTrashBoxSVG";
import { useMemo } from "react";
import { updateUser } from "../../../../../data/user";
import DialogWrapper from "../../../../custom-ui/dialog/Dialog_NewUI";
import DeleteUserModal from "../Component/Users/DeleteUserModal";
import ToastAlert from "../../../../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";
import AddUserModal from "../Component/Users/AddUserModal";
import ResendEmailSVG from "../../../../custom-ui/icons/ResendEmailSVG";
import {
  deleteInvitation,
  resendInvitation,
  useActiveInvitationByEmployerNew,
} from "../../../../../data/invitation";
import { useCallback } from "react";
import { useAllEmployerJobsByEmployerIdFromJobDetailsNewCollection } from "../../../../../data/jobDetailsNewCollection";

const MAX_ALLOWED_USERS = 20;

export type TOnSendInvite = {
  successText?: string;
  errorText?: string;
};

type TUsersDatatable = {
  id: string;
  name: string;
  status: string;
  email: string;
  title: string;
  liveJobs: number;
  handleResendInvitationClick: (e: any, rowData: any) => void;
  handleDeleteTrashIconClick: (e: any, rowData: any) => void;
  type: "invitation" | "user";
};

type TUsers_NewUIProps = {};

const Users_NewUI = (props: TUsers_NewUIProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");
  const { data: users, isLoading: isLoadingUsers } =
    useEmployerSideUsersByEmployerId(employerId);
  const { data: invitations, isLoading: isLoadingInvitations } =
    useActiveInvitationByEmployerNew(employerId);
  const { data: liveJobs, isLoading: isLoadingLiveJobs } =
    useAllEmployerJobsByEmployerIdFromJobDetailsNewCollection(employerId);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(null);

  const handleResendInvitationClick = useCallback(
    async (e: any, rowData: any) => {
      e.stopPropagation();

      const invitationData = invitations.find(
        (inv: any) => inv.id === rowData.id,
      );

      if (!invitationData) {
        toast.error("Something went wrong!");
        return;
      }

      const updatedInvitationData = {
        ...invitationData,
        status: "pending",
      };

      try {
        await resendInvitation(updatedInvitationData);
        toast.success("Invitation resent successfully");
      } catch (error: any) {
        toast.error(error?.message);
      }
    },
    [invitations],
  );

  const handleDeleteTrashIconClick = useCallback(
    (e: any, rowData: any) => {
      e.stopPropagation();

      setDeleteRowData(rowData);
      setIsDeleteModalVisible(true);
    },
    [setDeleteRowData, setIsDeleteModalVisible],
  );

  const liveJobsCountMappedWithUserId = useMemo(() => {
    if (isLoadingLiveJobs) return {};

    let mappedObjWithUserIds: { [key: string]: any } = {};

    liveJobs.forEach((liveJob: any) => {
      if (liveJob?.postedBy?.userId) {
        const userId = liveJob.postedBy.userId;
        mappedObjWithUserIds[userId] = (mappedObjWithUserIds[userId] || 0) + 1;
      }
    });

    return mappedObjWithUserIds;
  }, [liveJobs, isLoadingLiveJobs]);

  const usersWithInvitations = useMemo(() => {
    if (!isLoadingUsers && !isLoadingInvitations) {
      const mappedInvitations = invitations.map((inv: any) => {
        return {
          id: inv.id,
          name: inv.name,
          status: inv.status,
          email: inv.email,
          title: inv.title,
          liveJobs: 0,
          createdAt: inv.createdAt?.seconds ?? Math.floor(Date.now() / 1000),
          handleResendInvitationClick: handleResendInvitationClick,
          handleDeleteTrashIconClick: handleDeleteTrashIconClick,
          type: "invitation",
        };
      });
      const mappedUsers = users.map((usr: any) => {
        return {
          id: usr.id,
          name: usr.name,
          status: usr.status,
          email: usr.email,
          title: usr.title,
          liveJobs: liveJobsCountMappedWithUserId[usr.id] || 0,
          createdAt: usr.createdAt?.seconds ?? Math.floor(Date.now() / 1000),
          handleResendInvitationClick: handleResendInvitationClick,
          handleDeleteTrashIconClick: handleDeleteTrashIconClick,
          type: "user",
        };
      });

      const totalUsers = [...mappedInvitations, ...mappedUsers];

      totalUsers.sort((a, b) => b.createdAt - a.createdAt);

      return totalUsers;
    }

    return [];
  }, [isLoadingUsers, isLoadingInvitations, invitations, users]);

  const onDelete = () => {
    if (!deleteRowData) return;

    if (deleteRowData.type === "invitation") {
      deleteInvitation(deleteRowData.id);
    } else if (deleteRowData.type === "user") {
      updateUser(deleteRowData.id, {
        status: "removed",
      });
    }

    toast.success("User deleted successfully");

    setIsDeleteModalVisible(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setDeleteRowData(null);
  };

  const openAddModal = () => {
    setIsAddModalVisible(true);
  };

  const closeAddModal = () => {
    setIsAddModalVisible(false);
  };

  const onSendInvite = ({ successText, errorText }: TOnSendInvite) => {
    if (successText) {
      toast.success(successText);
    }

    if (errorText) {
      toast.error(errorText);
    }

    closeAddModal();
  };

  return (
    <>
      <Meta title="Users" />
      <div className="flex flex-col gap-[30px]">
        <div className="flex flex-row justify-between items-center">
          <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
            Users
          </p>

          <div className="flex flex-row items-center gap-[20px]">
            <p className="font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] text-[#15415E]">
              Total Users:{" "}
              <span className="font-[Gilroy-Bold]">
                {usersWithInvitations?.length}
              </span>
              /20
            </p>
            <button
              className={cn(
                "bg-[#15415E] w-[253px] h-[60px] rounded-[70px] py-[20px] px-[50px] flex flex-row gap-[10px] items-center disabled:bg-[#636b71]",
                {
                  "cursor-not-allowed":
                    usersWithInvitations.length == MAX_ALLOWED_USERS,
                },
              )}
              onClick={openAddModal}
              disabled={usersWithInvitations.length == MAX_ALLOWED_USERS}
            >
              <AddUserSVG className="text-[#FFFFFF] w-[24px] h-[24px]" />
              <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[16px] text-[#FFFFFF]">
                Add New User
              </p>
            </button>
          </div>
        </div>

        <div>
          <DataTable
            columns={columns}
            data={usersWithInvitations || []}
            isloading={isLoadingUsers}
            isInactiveSelected={false}
            /* Commenting onRowClick Prop as it can be used later on */
            // onRowClick={(row) => {
            //   console.log(row, "========>row");
            // }}
          />
        </div>
      </div>

      {isDeleteModalVisible && (
        <DialogWrapper
          title="Delete User"
          onClose={closeDeleteModal}
          displayCloseIcon={true}
          size="max-w-[480px]"
        >
          <DeleteUserModal onCancel={closeDeleteModal} onDelete={onDelete} />
        </DialogWrapper>
      )}

      {isAddModalVisible && (
        <DialogWrapper
          title="Add New User"
          onClose={closeAddModal}
          displayCloseIcon={true}
          size="max-w-[730px]"
        >
          <AddUserModal onDone={onSendInvite} employer={employer} user={user} />
        </DialogWrapper>
      )}

      <ToastAlert />
    </>
  );
};

// @ts-ignore
const columns: ColumnDef<TUsersDatatable>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Name
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("name")}</div>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Status
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      const statusValue: string = row.getValue("status");

      let statusValueToShow = statusValue;

      switch (statusValue) {
        case "active":
          statusValueToShow = "Active";
          break;
        case "limited":
          statusValueToShow = "Active";
          break;
        case "sent":
          statusValueToShow = "Invited";
          break;

        default:
          break;
      }

      return (
        <div
          className={cn(
            "rounded-[50px] w-[94px] h-[36px] p-[10px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px] flex justify-center items-center",
            {
              "bg-[#C8E3FB] text-[#1792FF]": ["active", "limited"].includes(
                statusValue,
              ),
              "bg-[#FEF8BF] text-[#E9A300]": [
                "invited",
                "sent",
                "pending",
              ].includes(statusValue),
            },
          )}
        >
          <p className="first-letter:uppercase">{statusValueToShow}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Email
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("email")}</div>;
    },
  },
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Designation
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("title")}</div>;
    },
  },
  {
    accessorKey: "liveJobs",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Live Jobs
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("liveJobs")}</div>;
    },
  },
  {
    id: "actions",
    enableHiding: false,
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row gap-[15px]"
        >
          Action
        </button>
      );
    },
    cell: ({ row }) => {
      const rowData = row.original;

      return (
        <div className="flex flex-row items-center gap-[20px]">
          {rowData?.type == "invitation" ? (
            <button
              onClick={(e: any) =>
                rowData.handleResendInvitationClick(e, rowData)
              }
            >
              <ResendEmailSVG className="text-[#15415E] w-[24px] h-[24px]" />
            </button>
          ) : (
            <div className="w-[24px]"></div>
          )}
          <button
            onClick={(e: any) => rowData.handleDeleteTrashIconClick(e, rowData)}
          >
            <DeleteTrashBoxSVG className="text-[#ED3535] w-[24px] h-[24px]" />
          </button>
        </div>
      );
    },
  },
];
export default Users_NewUI;
