import { useState, useEffect, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { createStripePaymentIntent } from "../../../../callable-cloud-functions/cloudFunctions";
// import { createStripeClientSecret } from "../../../../callable-cloud-functions/cloudFunctions";
import { createStripeClientSecretNew } from "../../../../callable-cloud-functions/cloudFunctions";
import { useNavigate } from "react-router-dom";
import PaymentPage from "../Components/PaymentPage";
import { manageConfiguration } from "../../../../data/configuration";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(
//   "pk_test_51Qn2rzCZo4ua7nPXbqy30DGK6VcFW2OFjIdBBC5Mo4t5dPJHL2pYZcvrQa9QZZBnNdp2iTynCIOTerQUvssc8Y1e00J9EoYzKs",
// );

const appearance = {
  theme: "stripe",
};
// Enable the skeleton loader UI for optimal loading.
const loader = "auto";

type ElementsProviderProps = {
  currentPlan: any;
  employerInfo: any;
  promoCodeData: any;
  priceId: string;
};

const ElementsProvider = ({
  currentPlan,
  employerInfo,
  promoCodeData,
  priceId,
}: ElementsProviderProps) => {
  // useEffect(() => {
  //   employerInfo = localStorage.getItem(employerInfo) || {};
  // }, []);
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");

  const isPromoCodeApplied = promoCodeData?.isPromoCodeApplied ?? false;

  // const planPriceToFixed = useMemo(() => {
  //   return promoCodeData?.planPrice?.toFixed(2);
  // }, [isPromoCodeApplied]);

  // const discountToFixed = useMemo(() => {
  //   return isPromoCodeApplied
  //     ? promoCodeData?.promotionCode?.discount?.toFixed(2)
  //     : 0;
  // }, [isPromoCodeApplied]);

  // const total = (Number(planPriceToFixed) - Number(discountToFixed))?.toFixed(
  //   2,
  // );

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const configData = await manageConfiguration();
        const publishableKey = configData[0]?.stripe_live
          ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE
          : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

        setStripePromise(loadStripe(publishableKey || ""));

        const result = await createStripeClientSecretNew({
          employerId: employerInfo?.employerId,
          newSubscriptionPlan: currentPlan,
          isPromoCodeApplied: isPromoCodeApplied,
          promotionCode: promoCodeData?.promotionCode?.code ?? "",
        });

        // Cast the response to the expected type
        const data = result.data as any;

        if (isMounted && data) {
          setClientSecret(data?.clientSecret);
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [employerInfo?.employerId]);

  const handlePayment = (data: any) => {
    console.log(data, "======>data inside handlePayment");

    navigate("/home");
    // setRenderView(3)
    // onDone(data);
  };

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          {/* {navigate("/home")} */}
          <div className="w-full flex flex-row justify-start my-10">
            <PaymentPage
              onContinue={handlePayment}
              currentPlan={currentPlan}
              employerInfo={employerInfo}
              isPromoCodeApplied={isPromoCodeApplied}
              promoCodeData={promoCodeData}
              // planPriceToFixed={planPriceToFixed}
              // discountToFixed={discountToFixed}
              // total={total}
            />
          </div>
        </Elements>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white p-8 rounded shadow-md text-center max-w-md">
            <h1 className="text-2xl font-bold text-gray-800">
              Redirecting you to payments page...
            </h1>
            <p className="mt-4 text-gray-600">
              Please do not refresh or close this page.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ElementsProvider;
