import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { jobFormSchemaStep3 } from "./JobFormSchemaStep3";

import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useMemo } from "react";
import Input_Emp from "../../Component-UI/Input_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";
import { useJobDetailsNewCollection } from "../../../../../../data/jobDetailsNewCollection";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";



function AddJobStep3({ currStep, setCurrentStep, setFormvalues, formvalues, toggle }: any) {
    const params = useParams();
    const navigate = useNavigate()
    const jobId = params.id ?? "";
    const [formSchema, setFormSchema] = useState(jobFormSchemaStep3(false));
    const { data: job } = useJobDetailsNewCollection(jobId);
    const { data: workSetting = [] } = useLookup("workSetting");
    const { data: workType = [] } = useLookup("workType");
    const { data: workTerm = [] } = useLookup("workTerm");

    const isFieldLocked = isLockedJobNew(job?.jobState);

    type FormValues = z.infer<ReturnType<typeof jobFormSchemaStep3>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            workSettings: formvalues?.workSettings || "",
            workType: formvalues?.workType || "",
            workTerm: formvalues?.workTerm || "",
            hoursPerWeekMin: formvalues?.hoursPerWeekMin || "",
            hoursPerWeekMax: formvalues?.hoursPerWeekMax || "",
            location: formvalues?.hybridJobLocation || ""
        },
        reValidateMode: "onBlur",
    });
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isSubmitting },
    } = form;

    const onSubmit = async ({ ...values }: FormValues) => {
        setFormvalues({
            ...formvalues,
            "workSettings": values.workSettings,
            "workType": values.workType,
            "workTerm": values.workTerm,
            "hoursPerWeekMin": values.hoursPerWeekMin,
            "hoursPerWeekMax": values.hoursPerWeekMax,
            "hybridJobLocation":values?.location
        })
        setCurrentStep(currStep + 1)
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center ">
                    <div className="w-[50vw] flex justify-center  ">
                        <div className=" ml-20 text-center space-y-2">
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Settings <span className="text-red-500 text-base ml-1">*</span>
                                <div className="mt-1 ml-[-5px]">
                                    <InfoIcon tooltipText="Please mention the job location if the job type is Hybrid."/>
                                </div>
                            </h1>

                            <Controller
                                control={control}
                                name="workSettings"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workSetting}
                                        disabled={isFieldLocked}
                                        error={errors?.workSettings?.message}
                                        required
                                    />
                                )}
                            />
                            {form.watch("workSettings") === "hybrid" && (
                                <div className="w-full  justify-start">
                                    <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                        Please enter your hybrid location<span className="text-red-500 text-base ml-1">*</span>
                                    </h1>

                                    <div className="ml-[-70%]">



                                        <Controller
                                            control={control}
                                            name="location"
                                            render={(field) => (
                                                <Input_Emp
                                                    {...field}
                                                    display="Location"
                                                    placeholder="Enter location"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.location?.message}
                                                    required
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                            )}

                            <div className="border-b-[2px] border-gray-100 h-2 my-2 "></div>
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Type <span className="text-red-500 text-base ml-1">*</span>
                                <div className="mt-1 ml-[-5px]">
                                    <InfoIcon tooltipText="For part-time jobs, please specify the minimum and maximum hours per week."/>
                                </div>
                                
                            </h1>
                            <Controller
                                control={control}
                                name="workType"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workType}
                                        disabled={isFieldLocked}
                                        error={errors?.workType?.message}
                                        required
                                    />
                                )}
                            />
                            <div className="border-b-[2px] border-gray-100 h-2 my-2 "></div>
                            <h1 className="text-instant-teams-blue-Main text-lg mt-2 flex py-3 pb-4">
                                Work Per Week (Hrs)
                                <span className="text-base ml-1">(optional for Full-Time)</span>
                            </h1>

                            <div className="flex w-[65%] mt-1">


                                <Controller
                                    control={control}
                                    name="hoursPerWeekMin"
                                    render={(field) => {
                                        const { value, onChange, ...restField } = field;

                                        return (
                                            <Input_Emp
                                                {...restField}
                                                value={value === "" ? "" : value}
                                                onChange={(e: any) => {
                                                    const inputValue = e.target.value;
                                                    const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                        inputValue,
                                                    );
                                                    if (!isValidInput) return;
                                                    onChange(inputValue);
                                                }}
                                                display="Min (Hrs)"
                                                placeholder="Min (Hrs)"
                                                readOnly={isFieldLocked}
                                                error={errors?.hoursPerWeekMin?.message}
                                                required
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="hoursPerWeekMax"
                                    render={(field) => {
                                        const { value, onChange, ...restField } = field;

                                        return (
                                            <Input_Emp
                                                {...restField}
                                                value={value === "" ? "" : value}
                                                onChange={(e: any) => {
                                                    const inputValue = e.target.value;
                                                    const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                        inputValue,
                                                    );
                                                    if (!isValidInput) return;
                                                    onChange(inputValue);
                                                }}
                                                display="Max (Hrs)"
                                                placeholder="Max (Hrs)"
                                                readOnly={isFieldLocked}
                                                error={errors?.hoursPerWeekMax?.message}
                                                required
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="border-b-[2px] border-gray-100 h-2 my-2 "></div>
                            <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                                Work Term<span className="text-red-500 text-base ml-1">*</span>
                            </h1>

                            <Controller
                                control={control}
                                name="workTerm"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={workTerm}
                                        disabled={isFieldLocked}
                                        error={errors?.workTerm?.message}
                                        required
                                    />
                                )}
                            />
                            {/* <div className="border-b-[2px] border-gray-100 h-2 my-2 mt-10 mb-10 "></div> */}


                        </div>
                    </div>

                </div>
                <div className="mt-20 w-full flex flex-row justify-between ">
                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        onClick={() => { reset(); navigate("/jobs") }}
                    >
                        Cancel
                    </button>

                    <div className="flex justify-center items-center">
                        <button

                            onClick={() => setCurrentStep(currStep - 1)}
                            className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {isSubmitting ? "Saving..." : "Next"}
                        </button>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddJobStep3;
