import { useState, useEffect } from "react";
import {
  employerUserSchema,
  pwdSchema,
  PASSWORD_REQ,
} from "./Schema/registrationSchema";
import { z } from "zod";
import { useUserByEmail } from "../../../../data/user";
import { createNewEmployer } from "../../../../data/employer";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useLookup } from "../../../../data/lookup";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Origin, AuthStatus } from "../../../../dataTypes/AuthOrigin";
import { createAuthOrigin, useAuthOrigin } from "../../../../data/authOrigin";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import { filter } from "lodash";
import PwdCheck from "../../../../components/custom-ui/pwd-check/PwdCheck";
import { useAuth } from "../../../../providers/auth";
import { useGlobalState } from "../../../../providers/globalState/GlobalStateProvider";
import InfoIcon from "./InfoIcon";
import invitation from "../../../../pages/invitations/invitation";
import InputWithSuffix from "../../../custom-ui/new-ui/InputWithSuffix";
import "./style.css";
import { createStripeCustomer } from "../../../../callable-cloud-functions/cloudFunctions";

function EmployerRegistrationStepTwo({
  onDone,
  employerInfo,
  employerCompany,
}: {
  onDone: Function;
  employerInfo: any;
  employerCompany: any;
}) {
  const auth = useAuth();

  const navigate = useNavigate();
  const { globalState, setGlobalState } = useGlobalState();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [emailCheckTrigger, setEmailCheckTrigger] = useState(false);
  const [authOriginQueryTrigger, setAuthOriginQueryTrigger] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  // const [selectedValue, setSelectedValue] = useState("");
  // const [preferredValue, setTitleValue] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    control,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      title: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(employerUserSchema),
  });

  const watchEmail = watch("email") ?? "";
  const watchPassword = watch("password");

  const watchConfirmPassword = watch("confirmPassword");

  const { data: authOriginRecord = [] } = useAuthOrigin(
    `${watchEmail}@${employerCompany?.domain}`.toLowerCase(),
    Origin.employerSelfRegistration,
    authOriginQueryTrigger,
  );

  const saveEmployerInfoAndAdvanceForm = (data: EmployerUserSchema) => {
    setPending(true);

    const hasAuthOriginRecord = !!authOriginRecord.length;
    const { email, name, terms, password, title, phone: mobilePhone} = data;
    const info = employerCompany;

    const emailId = `${email}@${info.domain}`;
    if (!hasAuthOriginRecord) {
      const metrics = {
        createdAt: Timestamp.now(),
      };
      const companyData = {
        displayName: info.companyName,
        companyUrl: info.companyUrl,
        domain: info.domain,
        location: info.location||"",
        headquaterCity:info.location||"",
        headquaterState: info.stateResidence || "",
        companySize: info.companySize,
        hiringNeeds: info.hiringNeeds,
        jobsToFill: info.jobsToFill,
        status: "active",
        currentPlan: null,
        metrics: metrics,
        profile: { url: info.companyUrl },
        createdBy: emailId,
        accounterOwnerDetails: {
          name: name,
          email: emailId,
          title: title,
          phone: mobilePhone,
        }
      };
      localStorage.setItem('empPOCData',JSON.stringify(companyData));
      createNewEmployer(companyData)
        .then((res: any) => {
          const employerData = {
            email: emailId,
            password,
            name,
            title,
            terms,
            employerId: res.id,
            invitationId: "",
            phone: mobilePhone,
          };
          createAuthOrigin({
            email: emailId.toLowerCase(),
            origin: Origin.employerSelfRegistration,
            status: AuthStatus.start,
            lastUpdated: Timestamp.now(),
          })
            .then(async (authData) => {
              localStorage.setItem(
                "employerEmail",
                JSON.stringify(employerData),
              );
              auth
                .signupNewEmployer(employerData)
                .then((empData: any) => {
                  onDone(employerData);
                })
                .catch((error: any) => {
                  setFormAlert({
                    type: "error",
                    code: error.code,
                    message: friendlyFirebaseError(error),
                  });
                });
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => setPending(false)); // onDone();
        })
        .catch((error: any) => {
          setFormAlert(
            `There was a problem creating ${companyData.displayName}`,
          );
        });
      return;
    }
    // onDone(data);
  };

  const { data: existingEmail } = useUserByEmail(
    watchEmail.toLowerCase(),
    emailCheckTrigger,
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleTooltipVisibility = () => {
    setShowTooltip(!showTooltip);
  };

  const toggleConfirmTooltipVisibility = () => {
    setShowConfirmTooltip(!showConfirmTooltip);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const emailExists = () => {
    if (Array.isArray(existingEmail)) {
      return !!existingEmail.length;
    }
    return false;
  };

  const handlePasswordChange = async (e: any) => {
    setPasswordValue(e.target.value);
  };

  type EmployerUserSchema = z.infer<typeof employerUserSchema>;

  return (
    <div className="max-w-2xl mx-auto p-6  overflow-y-scroll no-scrollbar  bg-white">
      <form
        className="space-y-8"
        onSubmit={handleSubmit(saveEmployerInfoAndAdvanceForm)}
        autoComplete="off"
      >
        <div className="relative mb-7">
          <div className="flex items-center">
            <input
              type="text"
              name="email"
              placeholder=" " // Empty placeholder for floating effect
              ref={register({
                required: "Please enter an email prefix",
              })}
              onBlur={() => {
                setEmailCheckTrigger(true);
                setAuthOriginQueryTrigger(true);
                trigger("email");
              }}
              onFocus={() => setEmailCheckTrigger(false)}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: "none", flex: 1 }}
            />
            {/* <span className="pointer-events-none flex items-center pr-3 text-right bg-gray-100 text-gray-500 border border-l-0 border-gray-300 px-2 rounded-r">
              @{employerCompany.domain || "example.com"}
            </span> */}
            {employerCompany.domain && (
              <div className="pointer-events-none absolute right-0 flex items-center pr-3  text-right">
                <span className="font-[Gilroy-Medium] font-[400] text-[#37373766] text-[16px] leading-[19.41px] pl-2 whitespace-nowrap">
                  @{employerCompany.domain}
                </span>
              </div>
            )}
          </div>
          <label
            htmlFor="email"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Email <span className="text-red-600">*</span>
          </label>
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
          )}
          {emailExists() && (
            <div className="rounded bg-amber-200 flex justify-center flex-col">
              <p className="text-center text-sm font-bold">
                Looks like you may already have an account.
              </p>
            </div>
          )}
        </div>
        <div className="relative mb-7">
          <PhoneInputWithCountry
            className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
            style={{ boxShadow: "none" }}
            name="phone"
            international
            defaultCountry="US"
            countryCallingCodeEditable={false}
            control={control}
            //onChange={async () => await trigger("phone")}
            onBlur={async () => await trigger("phone")}
            rules={{
              required: "Phone number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
          />
          <label
            htmlFor="phone"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Phone Number <span className="mr-1 text-red-600">*</span>
          </label>
          {errors.phone && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.phone.message}
            </p>
          )}
        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="name"
            placeholder=" " // Empty placeholder for floating effect
            ref={register({
              required: "Please enter your full name.",
            })}
            //onChange={async () => await trigger("name")}
            onBlur={async () => await trigger("name")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="name"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Full Name <span className="text-red-600">*</span>
          </label>
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>
        <div className="relative mb-7">
          <input
            type="text"
            name="title"
            placeholder=" " // Empty placeholder for floating effect
            ref={register}
            // onChange={(e) => setTitleValue(e.target.value)}
            onBlur={async () => await trigger("title")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="title"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            What is your title
          </label>
        </div>
        <div className="relative mb-7">
          <input
            type={showPassword ? "text" : "password"} // Toggle input type
            name="password"
            placeholder=" "
            ref={register}
            onChange={handlePasswordChange}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="password"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Password <span className="text-red-600">*</span>
          </label>

          <div
            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                color="#15415E"
                className="w-4"
                style={{ marginTop: "-5px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                color="#15415E"
                className="w-4"
                style={{ marginTop: "-5px" }}
              />
            )}
          </div>

          <div
            className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
            onClick={toggleTooltipVisibility}
            style={{ marginRight: "-60px", marginTop: "-5px" }}
          >
            <InfoIcon
              viewDown={true}
              singupFlow={true}
              tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}
            />
          </div>

          {errors.password && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.password.message}
            </p>
          )}

          {passwordValue && (
            <PwdCheck
              schema={pwdSchema}
              listStyles="text-sm text-instant-teams-gray-D1"
              value={watchPassword}
              pwdReq={PASSWORD_REQ}
            />
          )}
        </div>
        <div className="relative mb-7">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder=" "
            ref={register}
            //onChange={async () => await trigger("confirmPassword")}
            onBlur={async () => await trigger("confirmPassword")}
            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: "none" }}
          />
          <label
            htmlFor="confirmPassword"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Confirm Password <span className="text-red-600">*</span>
          </label>

          <div
            className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                color="#15415E"
                className="w-4"
                style={{ marginTop: "-5px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                color="#15415E"
                className="w-4"
                style={{ marginTop: "-5px" }}
              />
            )}
          </div>

          <div
            className="absolute inset-y-0 right-10 flex items-center cursor-pointer"
            onClick={toggleConfirmTooltipVisibility}
            style={{ marginRight: "-60px", marginTop: "-5px" }}
          >
            <InfoIcon
              viewDown={true}
              singupFlow={true}
              tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}
            />
          </div>
          {errors.confirmPassword && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>

        {/* Terms and Conditions */}
        <div className="relative mb-7  items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name="terms"
              ref={register({
                required: "Please agree to our terms of service.",
              })}
              className="h-4 w-4 text-[#15415E] focus:ring-gray-500 border-gray-600 rounded"
            />

            <label htmlFor="terms" className="text-[#616161] ml-3 text-sm">
              By creating an account, you agree to our{" "}
              <a
                href="https://instantteams.com/terms-and-conditions/"
                target="_blank"
                className="text-[#15415E] underline"
                rel="noreferrer"
              >
                Terms
              </a>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://instantteams.com/privacypolicy/"
                target="_blank"
                className="text-[#15415E] underline"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {errors.terms && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.terms.message}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className={`py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]`}
            style={{ width: "200px" }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Sign Up"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmployerRegistrationStepTwo;
