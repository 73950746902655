import React, { useState } from "react";
import { useAuth } from "../../providers/auth";
import { createEmployerForNewEmpAdmin, useEmployerSearch } from "../../data/employer";
import { errorLogger } from "../../providers/clientLogger";
import { Transition, Dialog } from "@headlessui/react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function CreateEmployerForm({ onDone, fromNewEmployer = false }: { onDone: Function, fromNewEmployer?: boolean }) {
  const { user } = useAuth();
  const navigate = useNavigate()

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [domain, setDomain] = useState("");
  const [name, setName] = useState("");
  const [exists, setExists] = useState(false);
  const [validDomain, setValidDomain] = useState("");
  const [domainValid, setDomainValid] = useState(false);
  const [button, setButton] = useState("Create Employer");

  function isValidDomain() {
    const regex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return regex.test(domain);
  }

  const {
    data: indexDomain,
    status: indexDomainStatus,
    error: indexDomainError,
  } = useEmployerSearch("domain", validDomain);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (domainValid && !exists) {
      setPending(true);
      setButton("Creating Employer...");
      const metrics = {
        createdAt: Timestamp.now(),
      };
      const data = {
        metrics: metrics,
        domain: domain,
        displayName: name,
        status: "Active",
      };
      createEmployerForNewEmpAdmin(data, user.id)
        .then((docId:any) => {
          console.log(docId, "employer data")
          navigate(`/employers-new/${docId}/profile`)
          onDone(`Added ${data.displayName}`);
        })
        .catch((error:any) => {
          setFormAlert(`There was a problem creating ${data.displayName}`);
          errorLogger(error.toString(), {
            component: "CreateEmployerForm createEmployer()",
            flow: "Create Employer",
            userId: user.id,
          });
        })
        .finally(() => {
          setPending(false);
          setButton("Create Employer");
        });
    }
  };

  useEffect(() => {
    if (indexDomain !== undefined && indexDomain.length > 0) {
      setButton("Already Exists");
      setPending(true);
      setExists(true);
    } else {
      setButton("Create Employer");
      setPending(false);
      setExists(false);
    }
  }, [validDomain, indexDomain]);

  useEffect(() => {
    if (isValidDomain()) {
      setValidDomain(domain);
      setDomainValid(true);
      setPending(false);
    } else {
      setDomainValid(false);
      setPending(true);
    }
  }, [domain, validDomain, isValidDomain]);

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          onClose={() => onDone()}
          className="overflow-y-auto fixed inset-0"
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title className="text-lg  leading-6 text-instant-teams-blue-D1 font-semibold">
                  Create Employer
                </Dialog.Title>
                <div className="mt-4">
                  <div className="mb-2">
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="displayName"
                      type="text"
                      placeholder="company name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    {name === "" && (
                      <span className="absolute right-7 top-30 text-xs">
                        {"Required "}
                        <FontAwesomeIcon color="red" icon={faCircleExclamation} />
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="domain"
                      type="text"
                      placeholder="domain"
                      onChange={(e) => setDomain(e.target.value)}
                    />
                    {domain === "" && (
                      <span className="absolute right-7 top-30 text-xs">
                        {"Required "}
                        <FontAwesomeIcon color="red" icon={faCircleExclamation} />
                      </span>
                    )}
                    {!domainValid && domain && (
                      <span className="absolute right-7  top-30 text-xs">
                        {"Invalid domain "}
                        <FontAwesomeIcon color="red" icon={faCircleExclamation} />
                      </span>
                    )}
                  </div>
                  <button
                    className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                    disabled={pending}
                  >
                    {button}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default CreateEmployerForm;
