import React, { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { updateAutoSave } from "../../../data/utility";

import { useShareByTypeAndId } from "../../../data/share";
import { useAuth } from "../../../providers/auth";

import EditSocialMeta from "../../socials/EditSocailMeta";
import { getRoot } from "../../../util/urlHelper";
import { Timestamp } from "firebase/firestore";
import { createShare } from "../../../data/share";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import {
  faPenToSquare,
  faEye,
  faRotate,
  faTrash,
  faPlusCircle,
  faCopy
} from "@fortawesome/pro-solid-svg-icons";
import { renderJobToStaticHTML } from "../../../util/static/renderStatic";
import JobListingShare from "../../socials/JobListingDetails";
import { useActiveJobPositions } from "../../../data/positions";
import { useJobDescriptionSkills } from "../../../data/jobDescriptions";
import { useEmployerData } from "../../../hooks/useApplicationEmployerData";
import { useListingJobByDocId } from "../../../data/listings";
import Tooltip from "../../custom-ui/tooltip/tooltip_NewUI";


const JobListingSocials = ({ id }: { id: string }) => {
  const [copied, setCopied] = useState(false);
  const { user } = useAuth();


  const { data: share = {} } = useShareByTypeAndId("job", id);

  const { data }: any = useListingJobByDocId(id)


  const [shareId, setShareId] = useState(share[0]?.id ?? "");
  const [baseUrl, setBaseUrl] = useState(getRoot());
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    setShareId(share[0]?.id ?? "");
  }, [share]);

  async function shareJob() {
    const shareInfo = {
      type: "job",
      typeId: data?.id,
      data: {
        employer: {
          displayName: data?.companyFullName,
          logo: data?.companyLogo,
          payRate: data?.payRate,
          workSettings: data?.workSettings,
          workType: data?.workType
        },
        job: {
          description: data?.jobDescription,
          title: data?.jobName
        }
      },
      who: user?.uid,
      active: true,
      createdAt: Timestamp.fromDate(new Date()),
    };

    if (shareId) {
      await updateAutoSave("Shares", shareId, shareInfo);
    } else {
      await createShare(shareInfo);
    }
  }

  async function removeShareJob() {
    await updateAutoSave("Shares", shareId, {
      active: false,
    });
  }

  async function renderShareJob() {
    const staticFile = await renderJobToStaticHTML(
      share,
    );
  }

  const SOCIALSWITCH = "true"
  // const SOCIALSWITCH = process.env.REACT_APP_SOCIAL_SHARE;

  const handleCopyClick = () => {
    const urlToCopy = `${baseUrl}social/${share[0].id}`;
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="w-full ml-5">

      {SOCIALSWITCH !== undefined && SOCIALSWITCH === "true" && (
        <>
          {share && share.length && share[0].active ? (
            <div className="text-center flex mb-4">

              <>

                <Tooltip width="200px" text="Copy Social Link">
                  <button
                    id="copySocialLink"
                    className="text-instant-teams-blue-Main mr-4"
                    type="button"
                    onClick={handleCopyClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                  </button>
                </Tooltip>





                <Tooltip width="200px" text="Social link preview" >

                  <a
                    id="socialLink"
                    href={`${baseUrl}social/${share[0].id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="text-instant-teams-blue-Main mr-4"
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faEye as IconProp}
                        className="h-6 w-6"
                      />
                    </button>
                  </a>
                </Tooltip>




                {copied && (
                  <div className=" absolute mt-1 bg-instant-teams-blue-Main text-white px-2 py-1 rounded text-sm">
                    Social link copied to clipboard
                  </div>
                )}
              </>


            </div>
          ) : (

            <button
              className="text-instant-teams-blue-Main"
              type="button"
              onClick={shareJob}
            >
              <FontAwesomeIcon
                icon={faPlusCircle as IconProp}
                className="h-4 w-4"
              />
            </button>

          )}
        </>
      )}
      {isShowing && share[0] && (
        <DialogWrapper
          title="Share Details"
          onClose={toggle}
          size="w-1/3 h-auto mt-36"
        >
          <EditSocialMeta share={share[0]} />
        </DialogWrapper>
      )}
    </div>
  );
};

export default JobListingSocials;