import * as z from "zod";

export const jobFormSchemaStep2 = () => {
  

  return z
    .object({
      includeDate: z.boolean().default(false),
      startDate: z
        .union([
          z.date(),
          z.string().min(1, { message: "Start Date is required" }).datetime({ offset: true }),
        ])
        .optional()
        .refine((val) => !val || val !== "", {
          message: "Start Date is required",
        }),
      noATS: z.string().optional(),
      noATSContactEmail: z.string().optional(),
      applicationFlow: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.noATS === "noATS_true") {
        // Ensure applicationFlow is provided
        if (!data.applicationFlow) {
          ctx.addIssue({
            path: ["applicationFlow"],
            message: "Application Flow URL is required",
            code: "custom",
          });
        } else if (!/^https:\/\//.test(data.applicationFlow)) {
          ctx.addIssue({
            path: ["applicationFlow"],
            message: "Application Flow URL must use HTTPS",
            code: "custom",
          });
        }
      }

      if (data.noATS === "noATS_false") {
        // Ensure noATSContactEmail is provided and is a valid email
        if (!data.noATSContactEmail) {
          ctx.addIssue({
            path: ["noATSContactEmail"],
            message: "Email ID is required",
            code: "custom",
          });
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.noATSContactEmail)) {
          ctx.addIssue({
            path: ["noATSContactEmail"],
            message: "Invalid email format",
            code: "custom",
          });
        }
      }
    });
};
