import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useModal from "../../../hooks/useModal";
import { useEmployer } from "../../../data/employer";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import EmployerLogo from "../../employer/details/parts/employer-logo";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import HeaderLogItems from "./HeaderLogItems";
import { useAuth } from "../../../providers/auth";
function EmployerSidebarHeader({}) {
  const { userClaims } = useAuth();

  const { employers } = collectionNames;
  const params = useParams();
  const {
    data: employer,
    status: employerStatus,
    error: employerError,
  } = useEmployer(params?.employerId ?? userClaims.claims?.employerId);

  const [employerId, setEmployerId] = useState(params?.employerId ?? "");
  const { displayName } = employer ?? {};
  const { isShowing: showAvatarEditModal, toggle: toggleAvatarEditModal } =
    useModal();
  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const handleDone = () => {
    toggleNote();
  };

  useEffect(() => {
    setEmployerId(params.employerId ?? "");
  }, [params.employerId]);

  const [logo, setLogo] = useState(employer?.logo);
  const [id, setId] = useState(params.userId ?? "");

  useEffect(() => {
    if (employer?.logo === undefined || employer?.logo === null) {
      setLogo("/images/default-employer.jpg");
    } else {
      setLogo(employer?.logo);
    }
  }, [employer?.logo]);

  useEffect(() => {
    setId(params.employerId ?? "");
  }, [params.employerId]);

  return (
    <div className="">
      {userClaims?.claims?.type === "A" ? (
        <HeaderLogItems
          collection={employers}
          recId={employerId}
          viewableItems={["audit", "access", "notes"]}
        />
      ) : null}
      {/* don't remove the px-3 */}
      <div className="items-center pt-4 px-3 pb-3">
        <img
          id="employer-logo"
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
          onClick={() => toggleAvatarEditModal()}
        />

        {showAvatarEditModal ? (
          <EmployerLogo toggleModal={toggleAvatarEditModal} />
        ) : null}
        <div className="text-center text-xl font-bold pb-5 pt-3">
          {displayName}
         {employer?.tac_managed === true&&<p className="text-xs font-thin pt-2  "> Admin Created</p>}
        </div>
        {userClaims?.claims?.type === "A" && employer?.status ? (
          <div className="pl-3">
            <AutoSaveStatusSelector
              status={employer?.status}
              collection="Employer"
              recId={employer?.id}
              required={false}
              name="status"
              display="Status"
              placeholder=""
              directions=""
            />
          </div>
        ) : null}
      </div>
      {isShowingNote && (
        <GeneralNote onDone={handleDone} collection={employers} id={id} />
      )}
    </div>
  );
}

export default EmployerSidebarHeader;
