import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useJobDescription } from "../../data/jobDescriptions";
import { useEmployer, useEmployerDepartments } from "../../data/employer";
import { useLookup } from "../../data/lookup";
import { upload } from "../../storage/storage";
import AutoSaveDateRange from "../../components/custom-ui/input-fields/auto-save-date-range";
import AutoSaveCheckboxGroup from "../../components/custom-ui/input-fields/auto-save-checkbox-group";
import AutoSaveCheckboxIconsGroup from "../../components/custom-ui/input-fields/auto-save-checkbox-icons-group";
import AutoSaveSlider from "../../components/custom-ui/input-fields/auto-save-slider";
import AutoSaveText from "../../components/custom-ui/input-fields/auto-save-field";
import AutoSaveWysiwyg from "../../components/custom-ui/input-fields/auto-save-wysiwyg";
import AutoSaveToggle from "../../components/custom-ui/input-fields/auto-save-toggle";
import AutoSaveSelectGeneric from "../../components/custom-ui/input-fields/auto-save-select-generic";
import DialogWrapper from "../../components/custom-ui/dialog/Dialog";
import DragAndDropVariantA from "../../components/custom-ui/drag-and-drop/DragAndDropFile";
import TextAreaDisplay from "../../components/custom-ui/input-fields/modals/TextAreaDisplay";
import useModal from "../../hooks/useModal";
import folderNames from "../../storage/dictionary/folderNames";
import { collectionNames } from "../../data/dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";
import FadeInFromTop from "../../components/custom-ui/animations/transitions/FadeInFromTop";
import Meta from "../../components/custom-ui/meta/Meta";

function JobDetailsPage() {
  const params = useParams();
  const jobId = params.id ?? "";

  const { isShowing: isShowingTextArea, toggle: toggleTextArea } = useModal();

  const [textAreaEditorState, setTextAreaEditorState] = useState({});

  // Auto Save File upload
  const [file, setFile] = useState();
  useEffect(() => {
    if (file) {
      upload(file, folderNames.jobDescriptions, `${jobId}_${file?.name}`)
        .then((fileUrl) => {})
        .catch((error: Error) => {});
    }
  }, [file]);

  const openAndEditTextArea = (textAreaData: {
    // auto save text area requires these fields
    value: string;
    rows: number;
    collection: string;
    recId: string;
    required: boolean;
    name: string;
    display: string;
    placeholder: string;
    directions: string;
    readOnly: boolean;
  }) => {
    setTextAreaEditorState({ ...textAreaData });
    toggleTextArea();
    console.log("Text are editor state: ", textAreaEditorState);
  };

  const {
    data: job,
    status: jobQueryStatus,
    error: jobQueryError,
  } = useJobDescription(jobId);

  const {
    data: employer,
    status: employerQueryStatus,
    error: employerQueryError,
  } = useEmployer(job?.employer);

  const {
    data: dept,
    status: deptQueryStatus,
    error: deptQueryError,
  } = useEmployerDepartments(job?.employer);

  const {
    data: classification = [],
    status: classificationQueryStatus = "",
    error: classificationQueryError,
  } = useLookup("classification");

  const {
    data: model = [],
    status: modelQueryStatus = "",
    error: modelQueryError,
  } = useLookup("model");

  const {
    data: payType = [],
    status: payTypeQueryStatus = "",
    error: payTypeQueryError,
  } = useLookup("payType");

  const {
    data: term = [],
    status: termQueryStatus = "",
    error: termQueryError,
  } = useLookup("term");

  const {
    data: hardware = [],
    status: hardwareQueryStatus = "",
    error: hardwareQueryError,
  } = useLookup("hardware");

  const {
    data: os = [],
    status: osQueryStatus = "",
    error: osQueryError,
  } = useLookup("os");

  const {
    data: network = [],
    status: networkQueryStatus = "",
    error: networkQueryError,
  } = useLookup("network");

  // Has Commission
  const hasCommission = () => {
    if (Array.isArray(job?.payType)) {
      return !!job.payType.find((item: string) => item === "COMM");
    }
    return false;
  };

  // Has Seasonal Schedule
  const hasSeasonal = () => {
    if (Array.isArray(job?.term)) {
      return !!job.term.find((item: string) => item === "SEA");
    }
    return false;
  };

  return (
    <div className="mt-36">
      <Meta title={job.title ? `${job.title} Details` : `Job Details`} />
      <div>{employer?.displayName}</div>
      <div>{job?.title}</div>
      <div className="mx-4 grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Column 1 */}
        <div>
          <div className="mx-2  mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
            Job Title
          </div>
          <div className="mb-2">
            <AutoSaveText
              value={job?.title}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={true}
              name="title"
              display="Job Title"
              placeholder={"Enter Job Title"}
              directions=""
              readOnly={false}
            />
          </div>
          <div className="mb-8">
            <div className="mx-2  mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Job Description
            </div>
            <div className="text-center mb-2">
              <DragAndDropVariantA setFile={setFile} />
            </div>
            {/* Job Description */}
            <TextAreaDisplay
              displayText={job?.description}
              label=""
              onClick={() =>
                openAndEditTextArea({
                  value: job?.description,
                  rows: 5,
                  collection: collectionNames.jobDescriptions,
                  recId: jobId,
                  required: false,
                  name: "description",
                  display: "Job Description",
                  placeholder: "Job Description",
                  directions: "",
                  readOnly: false,
                })
              }
            />
          </div>
          <div className="mb-2">
            {/* Day in the Life */}

            <TextAreaDisplay
              displayText={job?.dayInTheLife}
              label="Day In The Life"
              onClick={() =>
                openAndEditTextArea({
                  value: job?.dayInTheLife,
                  rows: 5,
                  collection: collectionNames.jobDescriptions,
                  recId: jobId,
                  required: false,
                  name: "dayInTheLife",
                  display: "Day In The Life",
                  placeholder: "Day In the life",
                  directions: "",
                  readOnly: false,
                })
              }
            />
          </div>
          <div className="mb-2">
            {/* Training */}

            <TextAreaDisplay
              displayText={job?.training}
              label="Training"
              onClick={() =>
                openAndEditTextArea({
                  value: job?.training,
                  rows: 5,
                  collection: collectionNames.jobDescriptions,
                  recId: jobId,
                  required: false,
                  name: "training",
                  display: "Training",
                  placeholder: "Training",
                  directions: "",
                  readOnly: false,
                })
              }
            />
          </div>
          <div className="mb-2">
            {/* Must Haves */}
            <TextAreaDisplay
              displayText={job?.mustHaves}
              label="Must Haves"
              onClick={() =>
                openAndEditTextArea({
                  value: job?.mustHaves,
                  rows: 5,
                  collection: collectionNames.jobDescriptions,
                  recId: jobId,
                  required: false,
                  name: "mustHaves",
                  display: "MustHaves",
                  placeholder: "Must Haves",
                  directions: "",
                  readOnly: false,
                })
              }
            />
          </div>
          <div className="mb-2">
            <TextAreaDisplay
              displayText={job?.benefits}
              label="Benefits"
              onClick={() =>
                openAndEditTextArea({
                  value: job?.benefits,
                  rows: 5,
                  collection: collectionNames.jobDescriptions,
                  recId: jobId,
                  required: false,
                  name: "benefits",
                  display: "Benefits",
                  placeholder: "Benefits",
                  directions: "",
                  readOnly: false,
                })
              }
            />
          </div>
        </div>

        {/* Column 2 */}
        <div>
          <div className="mx-2  mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
            Department
          </div>
          <div className="mb-2">
            <AutoSaveSelectGeneric
              value={job?.department??dept[0]?.id}
              options={dept}
              collection={collectionNames.jobDescriptions}
              recId={jobId}
              required={false}
              name="department"
              display="Department"
              placeholder="Select a Department"
              directions=""
            />
          </div>
          <div className="mb-2">
            <div className="py-1 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Work Settings
            </div>
            <AutoSaveCheckboxGroup
              name="model"
              options={model}
              initialValues={job?.model}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
          </div>
          <div className="mb-2">
            <div className="py-1 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Work Type
            </div>
            <AutoSaveCheckboxGroup
              name="classification"
              options={classification}
              initialValues={job?.classification}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
          </div>
          <div className="mb-4">
            <div className="py-1 mb-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Work Term
            </div>
            <div className="mb-2">
              <AutoSaveCheckboxGroup
                name="term"
                options={term}
                initialValues={job?.term}
                collection={collectionNames.jobDescriptions}
                id={jobId}
              />
            </div>
            <FadeInFromTop show={hasSeasonal()}>
              <div>
                <AutoSaveDateRange
                  disabled={!hasSeasonal()}
                  initialStart={
                    job?.startDate instanceof Timestamp
                      ? job.startDate.toDate()
                      : null
                  }
                  initialEnd={
                    job?.endDate instanceof Timestamp
                      ? job.endDate.toDate()
                      : null
                  }
                  name="information.seasonalDateRange"
                  collection={collectionNames.jobDescriptions}
                  id={jobId}
                />
              </div>
            </FadeInFromTop>
          </div>
          <div className="mb-24">
            <div className="py-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Hours Per Week
            </div>
            <div className="mt-12">
              <AutoSaveSlider
                initialValues={job?.hoursPerWeek ?? []}
                collection={collectionNames.jobDescriptions}
                field={"hoursPerWeek"}
                id={jobId}
                min={10}
                max={60}
                step={1}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="py-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Pay Rate
            </div>
            <AutoSaveCheckboxGroup
              name="payType"
              options={payType}
              initialValues={job?.payType}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
            <div className="mb-24 mt-14">
              <AutoSaveSlider
                initialValues={job?.payRate ?? []}
                collection={collectionNames.jobDescriptions}
                field={"payRate"}
                id={jobId}
                min={10}
                max={150}
                step={10}
              />
            </div>
            <FadeInFromTop show={hasCommission()}>
              <div className="mb-2">
                <TextAreaDisplay
                  displayText={job?.commissionDetails}
                  label="Commission Details"
                  onClick={
                    hasCommission()
                      ? () =>
                          openAndEditTextArea({
                            value: job?.commissionDetails,
                            rows: 5,
                            collection: collectionNames.jobDescriptions,
                            recId: jobId,
                            required: false,
                            name: "commissionDetails",
                            display: "Commission Details",
                            placeholder: "Commission Details",
                            directions: "",
                            readOnly: false,
                          })
                      : () => {}
                  }
                />
              </div>
            </FadeInFromTop>
          </div>

          <div className="mb-2">
            <div className="py-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Phone Support
            </div>
            <div className="center-items mb-4 w-full">
              {/* AutoSave Toggle tries to save immediately on mount, then subsequent changes by user would like to save only on user action */}
              <AutoSaveToggle
                value={job?.phoneSupport ?? false}
                collection={collectionNames.jobDescriptions}
                id={jobId}
                name={"phoneSupport"}
                on={"Required"}
                off={"Not Required"}
              />
            </div>
          </div>
        </div>

        {/* Column 3 */}
        <div>
          <div className="mb-2 ">
            <div className="py-1 uppercase block text-left font-semibold text-instant-teams-blue-Main">
              Technology
            </div>
            <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
              Hardware
            </div>
            <AutoSaveCheckboxIconsGroup
              name="hardware"
              options={hardware}
              initialValues={job?.hardware}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
          </div>
          <div className="mb-2">
            <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
              OS
            </div>
            <AutoSaveCheckboxGroup
              name="operatingSystem"
              options={os}
              initialValues={job?.operatingSystem}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
          </div>
          <div className="mb-2">
            <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
              Network
            </div>
            <AutoSaveCheckboxGroup
              name="network"
              options={network}
              initialValues={job?.network}
              collection={collectionNames.jobDescriptions}
              id={jobId}
            />
          </div>
          <div className="mb-4">
            <div className="py-1  block text-left font-semibold text-instant-teams-blue-Main">
              Minimum Connection Speed (Mbps)
            </div>
            <div className="mb-24 mt-14">
              <AutoSaveSlider
                initialValues={job?.minConnectSpeed ?? []}
                collection={collectionNames.jobDescriptions}
                field={"minConnectSpeed"}
                id={jobId}
                min={3}
                max={1000}
                step={10}
                numberOfHandles={1}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Document Editor Modal */}
        {/* Training */}
        {isShowingTextArea && (
          <DialogWrapper
            onClose={() => {
              toggleTextArea();
            }}
            title="Editor"
            size="5xl"
          >
            <>
              <AutoSaveWysiwyg
                editorStyle={{ height: "65vh" }}
                {...textAreaEditorState}
              />
            </>
          </DialogWrapper>
        )}
      </div>
    </div>
  );
}

export default JobDetailsPage;
