import React, { useMemo, useState } from 'react';
import { useLookup } from '../../../../../../data/lookup';
import { getMappedDataWithLookupCode } from '../../../../../seeker/details/New_UI/Components/util';
import { sanitizeMarkup } from '../../../../../../util/sanitize/sanitize-html';
import SliderWithCheckbox from '../../../../../custom-ui/old-ui/SliderWithCheckbox';

const JobDescription = ({ jobData }: any) => {
    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    const { data: mustHaves = [] } = useLookup("mustHaves");
    const aspectsOfJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aspectsOfJob), [aspectsOfJob])
    const aboutThisJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aboutThisJob), [aboutThisJob])
    const mustHavesMappedWithCode = useMemo(() => getMappedDataWithLookupCode(mustHaves), [mustHaves])

    return (
        <div className="mt-4">
        {
            jobData?.jobDescription?.whatsInItForYou === null && jobData?.jobDescription?.dayInLife && <div className="mt-4 mb-[30px]">
                <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                    Job Summary
                </p>
                <div className="mt-[5px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">

                    {jobData?.jobDescription?.dayInLife}
                </div>
            </div>
        }


        {
           jobData?. jobDescription?.whatsInItForYou === null
                ? (
                    <div className="flex flex-col">
                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                            Why Work Here
                        </p>
                        <div className="mt-[20px] flex flex-row gap-[20px] flex-wrap">
                            {
                                jobData?.jobDescription?.aspectsOfJob.map((aspectsOfJobCode:any) => {
                                    return (
                                        <div key={aspectsOfJobMappedWithCode[aspectsOfJobCode]?.id} className="bg-[#F4F7F8] py-[16px] px-[24px] rounded-[70px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#373737]">
                                            {aspectsOfJobMappedWithCode[aspectsOfJobCode]?.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            jobData?.jobDescription?.aspectsOfJobOther && (
                                <div className="mt-[30px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">
                                    {jobData?.jobDescription?.aspectsOfJobOther}
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <>
                        <div>
                            <h1 className='text-instant-teams-teal-Main'>
                                What's in it for you?
                            </h1>
                        </div>
                        <p className="text-gray-600 text-sm mt-2 leading-relaxed">
                            <div
                                className="dangerously list-inside pl-0  ml-0"
                                dangerouslySetInnerHTML={sanitizeMarkup(
                                    jobData?.jobDescription?.whatsInItForYou,
                                )}
                            ></div>
                        </p>
                    </>
                )
        }


        {
            // jobDescription?.dayInLife === null
            jobData?.jobDescription?.aboutThisJob && jobData?.jobDescription?.aboutThisJob.length > 0
                ? (
                    <div className="flex flex-col mt-[30px]">
                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                            Instant Job Insights
                        </p>
                        <div className='w-2/3 pl-[10px]'>
                            {jobData?.jobDescription?.aboutThisJob.map((option:any) => {
                                return (
                                    <SliderWithCheckbox
                                        key={option.code}
                                        option={option}
                                        value={option.value}
                                        isChecked={true}
                                        onSelectionChange={() => { }}
                                        isViewOnly={true}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <>
                        {jobData?.jobDescription?.dayInLife && <div className="mt-4">
                            <h3 className="text-instant-teams-teal-Main mb-2">A Day in the Life</h3>
                            <p className="text-gray-500 text-sm">
                                <div
                                    className="dangerously text-sm"
                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                        jobData?.jobDescription?.dayInLife
                                    )}
                                ></div>

                            </p>
                        </div>}
                    </>
                )
        }


        {
            jobData?.jobDescription?.mustHaves === null
                ? (
                    <div className="flex flex-col mt-[30px]">
                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                            Must Haves for Success
                        </p>

                        <div className='mt-[20px] flex flex-row gap-[20px] flex-wrap justify-start'>
                            {
                                jobData?.jobDescription?.mustHavesCheckboxes.map((mustHavesCode:any) => {
                                    return (
                                        <div key={mustHavesCode}>
                                            <img
                                                src={`/images/must-haves-images/${mustHavesCode}.png`}
                                                alt="Image"
                                                className="w-[150px] h-auto"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : (
                    <>
                        {jobData?.jobDescription?.mustHaves && <div className="mt-4">
                            <h3 className="text-instant-teams-teal-Main mb-2">Must Haves/What You Bring to the Table</h3>
                            <p className="text-gray-500 text-sm">
                                <div
                                    className="dangerously"
                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                        jobData?.jobDescription?.mustHaves
                                    )}
                                ></div>

                            </p>
                        </div>}
                    </>
                )
        }


        {
            jobData?.payRate?.commission && <>
                <div  className='mt-5'>
                    <h1 className='text-instant-teams-teal-Main mb-2'>
                       {jobData?.payRate?.additionalBenifits==="empComm"? "Commission":"Bonus"}
                    </h1>
                </div>
                <p className="text-gray-500 text-sm">
                    <div
                        className="dangerously"
                        dangerouslySetInnerHTML={sanitizeMarkup(
                            jobData?.payRate?.commission
                        )}
                    ></div>

                </p>

            </>
        }


    </div >
    );
}

export default JobDescription;
