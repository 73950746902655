import * as z from "zod";

export const getBillingContactSchema = () => {
  return z
    .object({
      email: z
        .string({
          required_error: "Email field is required",
        })
        .email({
          message:
            "Please enter a valid email address. Example: username@example.com.",
        })
        .min(1, {
          message: "Email is required",
        }),
      name: z
        .string({
          required_error: "Full Name field is required",
        })
        .min(1, {
          message: "Full Name is required",
        }),
      // phone: z
      //   .string({
      //     required_error: "Phone Number field is required",
      //   })
      //   .min(1, {
      //     message: "Phone Number is required",
      //   }),

      phone: z
        .string()
        .min(1, {
          message: "Phone Number is required",
        })
        .refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
          message:
            "Please enter a valid phone number. The format should be [+][country code][number].",
        }),
      title: z
        .string({
          required_error: "Designation field is required",
        })
        .min(1, {
          message: "Designation is required",
        }),
       state: z.string({ required_error: "Please select State" }).min(1, {
          message: "Please select State",
        }),
        city: z
          .string({ required_error: "Please enter City" })
          .nonempty("Please enter City"),
        address: z.string({ required_error: "Please enter Address." }).min(1, {
          message: "Please enter a Address.",
        }),
        zipCode: z
          .string({ required_error: "Please enter Zip Code" })
          .length(5, { message: "Please enter valid 5-digit Zip Code" }),
    })
    .superRefine((data, ctx) => {});
};
