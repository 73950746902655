import React, { useEffect, useRef, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { formatRateRange, formatString, formatTimeDifference, getDeviceType } from '../util';
import { useAuth } from '../../../../../../providers/auth';
import { updateUserBookmarks, getUserBookmarks } from '../../../../../../data/user';
import { applyNowMetric } from '../../../../../../callable-cloud-functions/cloudFunctions';
import { sanitizeMarkup } from '../../../../../../util/sanitization/sanitize-html';
import DialogWrapper from '../../../../../custom-ui/dialog/Dialog';
import useModal from '../../../../../../hooks/useModal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SliderWithCheckbox from '../../../../../custom-ui/old-ui/SliderWithCheckbox';
import { cn } from '../../../../../../util/cn';
import Resume from '../../../onboarding/Resume';
import JobDetailsPage from '../../../../../../pages/jobs/job-details';
import ToastAlert from '../../../../../custom-ui/toast-alert/ToastAlert';

const JobsDetails = ({ jobsDetailData, bookmarks, setBookmarks, aspectsOfJobMappedWithCode, aboutThisJobMappedWithCode, mustHavesMappedWithCode }) => {
    const data = useAuth();
    const { isShowing, toggle: toggleShow } = useModal();
    const [loading, setLoading] = useState(false)
    const commissionRef = useRef(null);
    useEffect(() => {
        const fetchBookmarks = async () => {
            const userBookmarks = await getUserBookmarks(data?.user?.id);
            setBookmarks(userBookmarks);
        };
        fetchBookmarks();
    }, [data]);


    const {
        companyLogo,
        companyFullName,
        jobName,
        openings,
        workType,
        workTerm,
        hybridJobLocation,
        workSettings,
        makeLiveDate,
        payRate,
        startDateInclude,
        applicationFlow,
        jobDescription,
        solutionType,
        hoursPerWeek,
        noATS,
        startDate
    } = jobsDetailData || {};

    const timeDifference = formatTimeDifference(makeLiveDate);
    const platform = getDeviceType()

    const timestamp = { seconds: 1731349800, nanoseconds: 0 };
    const date = new Date(startDate?.seconds * 1000);
    const options = { month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(' ', ' ');


    const handleApplyJobs = async () => {
        setLoading(true)
        window.open(applicationFlow, '_blank');
        try {
            await applyNowMetric({ jobId: jobsDetailData?.id, userId: data?.user?.id, platform: platform, solutionType: solutionType === "job_board" ? "DH" : "BPO" });
            setLoading(false)
            toggleShow()

        } catch (error) {
            console.error("Error applying for the job:", error);
        }
    };

    const handleBookMark = async () => {
        await updateUserBookmarks(data?.user?.id, jobsDetailData?.id);

        // Update local bookmarks state
        setBookmarks((prevBookmarks) => {
            if (Array.isArray(prevBookmarks) && prevBookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id)) {
                return prevBookmarks.filter((bookmark) => bookmark.jobId !== jobsDetailData?.id);
            } else {
                return [...prevBookmarks, { jobId: jobsDetailData?.id, createdAt: new Date() }];
            }
        });
    };



    const scrollToCommission = () => {
        commissionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <div
            style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#b3b3b3 #f0f0f0',
            }}
            className="w-full mx-auto bg-white   rounded-lg overflow-y-auto overflow-x-hidden h-[92%] xs:h-ful md:h-fulll p-6 xs:p-4 md:p-4 xs:mt-0 md:mt-0 mt-12">
            {/* Header Section */}
            {jobsDetailData ? (
                <>
                    <div className="flex justify-between xs:flex-col md:flex-col items-start">
                        <div className="flex">
                            <div className="mr-4">
                                <img
                                    src={solutionType == "bpo" ? "/images/InstantTeamsIcon-05.png" : companyLogo}
                                    alt="Company Logo"
                                    className="w-16 h-16 rounded object-contain "
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold text-instant-teams-blue-Main">
                                    {jobName}
                                    {(timeDifference?.includes("h ago") || timeDifference?.includes("m ago")) && <span className="text-red-500 ml-2">🔥</span>}
                                </h2>
                                <p className="text-gray-500 text-sm font-thin">{solutionType == "bpo" ? "Instant Teams" : companyFullName}</p>
                            </div>
                        </div>
                        <div className="flex xs:mt-2 md:mt-2  items-center space-x-4">
                            {openings > 1 && (
                                <span className="bg-instant-teams-teal-D2 xs:hidden md:hidden text-gray-600 text-xs px-3 py-1 rounded-full">
                                    Multiple Openings
                                </span>
                            )}
                            {/* Bookmark Icon */}
                            <div className="flex-shrink-0 bg-gray-100 xs:mt-2 md:mt-2 flex items-center justify-center w-[50px] h-[50px] rounded-full ml-4 ">
                                <button onClick={handleBookMark} className="text-gray-500 pl-[14px] pt-[14px] hover:text-gray-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 42 42"
                                        stroke="currentColor"
                                        className="w-8 h-8"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            fill={Array.isArray(bookmarks) && bookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id) ? "#19405E" : ""}
                                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3-7 3V5z"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <button onClick={toggleShow} className="bg-instant-teams-blue-Main text-white py-[16px] px-[34px] rounded-full flex items-center text-[16px]">
                                <span className='w-[107px]'>
                                    Apply Now <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 flex xs:mt-8 md:mt-8 xs:flex-col md:flex-col items-center xs:space-y-3 md:space-y-3 space-x-5">
                        <div className='flex space-x-3 '>
                            <div className='grid grid-cols-2 gap-3'>
                                <div className="bg-[#1F385133] xs:w-full md:w-full py-2 px-6  xs:ml-5 md:ml-5 items-center rounded-lg ">
                                    <p className="text-instant-teams-blue-Main text-center  text-sm">Work Type</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center ">{formatString(workType)}</p>
                                    {workType === "part_time" && <p className=' text-instant-teams-blue-Main text-xs text-center'>{`${hoursPerWeek.min} - ${hoursPerWeek.max} hours/week`}</p>}
                                </div>
                                <div className="bg-[#15415E33] xs:w-full items-center md:w-full py-1 px-6 rounded-lg ">
                                    <p className="text-instant-teams-blue-Main text-sm  text-center">Work Setting</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center">{formatString(workSettings)}</p>
                                    {workSettings === "hybrid" && hybridJobLocation && <p className=' text-instant-teams-blue-Main text-xs text-center'>{hybridJobLocation||""}</p>}

                                </div>
                            </div>
                            {startDateInclude && <div className="bg-[#026AAF33] items-center xs:w-full md:w-full  py-2 px-6 rounded-lg ">
                                <p className="text-instant-teams-blue-Main text-sm  text-center">Start Date</p>
                                <p className="font-semibold text-instant-teams-blue-Main text-center">{formattedDate}</p>
                            </div>}
                            {payRate?.salary && (
                                <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className={`${payRate?.commission ? "cursor-pointer" : ""} bg-[#3E4C9533] py-2 px-6 xs:w-full items-center md:w-full  rounded-lg `}>

                                    <p className="text-instant-teams-blue-Main text-center text-sm">{(payRate?.commission || payRate?.additionalBenifits) ? `Salary + ${(payRate?.additionalBenifits === "empBO") ? "Bonus" : "Commision"}` : "Salary"}</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center">{formatRateRange("salary", payRate?.salary)}</p>
                                </div>
                            )}
                            {payRate?.hourly && (
                                <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className="bg-[#3E4C9533] items-center py-2 px-6 xs:w-full md:w-full rounded-lg ">
                                    <p className="text-instant-teams-blue-Main text-center text-sm">{(payRate?.commission || payRate?.additionalBenifits) ? `Salary + ${(payRate?.additionalBenifits === "empBO") ? "Bonus" : "Commision"}` : "Salary"}</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center">{formatRateRange("hoursPerWeek", payRate?.hourly)}</p>
                                </div>
                            )}
                            {payRate?.commission && !payRate?.salary && !payRate?.hourly && (
                                <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6 items-center  xs:w-full md:w-full rounded-lg ">
                                    <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center">Commission Based</p>
                                </div>
                            )}
                            {payRate?.type == "unpaid_internship" && (
                                <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6 items-center xs:w-full md:w-ful  rounded-lg ">
                                    <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                    <p className="font-semibold text-instant-teams-blue-Main text-center">Unpaid Internship</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Job Description */}
                    <div className="mt-6 border-t pt-6">
                        {
                            jobDescription?.whatsInItForYou === null && jobDescription?.dayInLife && <div className="mt-4 mb-[30px]">
                                <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                    Job Summary
                                </p>
                                <div className="mt-[5px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">

                                    {jobDescription?.dayInLife}
                                </div>
                            </div>
                        }


                        {
                            jobDescription?.whatsInItForYou === null
                                ? (
                                    <div className="flex flex-col">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Why Work Here
                                        </p>
                                        <div className="mt-[20px] flex flex-row gap-[20px] flex-wrap">
                                            {
                                                jobDescription?.aspectsOfJob.map(aspectsOfJobCode => {
                                                    return (
                                                        <div key={aspectsOfJobMappedWithCode[aspectsOfJobCode]?.id} className="bg-[#F4F7F8] py-[16px] px-[24px] rounded-[70px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#373737]">
                                                            {aspectsOfJobMappedWithCode[aspectsOfJobCode]?.label}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            jobDescription?.aspectsOfJobOther && (
                                                <div className={cn("font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]", {
                                                    "mt-[30px]": jobDescription?.aspectsOfJob.length > 0
                                                })}>
                                                    {jobDescription?.aspectsOfJobOther}
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <h1 className='text-instant-teams-teal-Main'>
                                                What's in it for you?
                                            </h1>
                                        </div>
                                        <p className="text-gray-600 text-sm mt-2 leading-relaxed">
                                            <div
                                                className="dangerously list-inside pl-0  ml-0"
                                                dangerouslySetInnerHTML={sanitizeMarkup(
                                                    jobDescription?.whatsInItForYou,
                                                )}
                                            ></div>
                                        </p>
                                    </>
                                )
                        }


                        {
                            // jobDescription?.dayInLife === null
                            jobDescription?.aboutThisJob && jobDescription?.aboutThisJob.length > 0
                                ? (
                                    <div className="flex xs:w-full flex-col mt-[30px]">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Instant Job Insights
                                        </p>
                                        <div className='w-2/3 pl-[10px]'>
                                            {jobDescription?.aboutThisJob.map((option) => {
                                                return (
                                                    <SliderWithCheckbox
                                                        key={option.code}
                                                        option={option}
                                                        value={option.value}
                                                        isChecked={true}
                                                        onSelectionChange={() => { }}
                                                        isViewOnly={true}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {jobDescription?.dayInLife && <div className="mt-4">
                                            <h3 className="text-instant-teams-teal-Main mb-2">A Day in the Life</h3>
                                            <p className="text-gray-500 text-sm">
                                                <div
                                                    className="dangerously text-sm"
                                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                                        jobDescription?.dayInLife
                                                    )}
                                                ></div>

                                            </p>
                                        </div>}
                                    </>
                                )
                        }


                        {
                            jobDescription?.mustHaves === null
                                ? (
                                    <div className="flex flex-col mt-[30px]">
                                        <p className="font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                            Must Haves for Success
                                        </p>

                                        <div className='mt-[20px] flex flex-row gap-[20px] flex-wrap justify-start'>
                                            {
                                                jobDescription?.mustHavesCheckboxes.map(mustHavesCode => {
                                                    return (
                                                        <div key={mustHavesCode}>
                                                            <img
                                                                src={`/images/must-haves-images/${mustHavesCode}.png`}
                                                                alt="Image"
                                                                className="w-[150px] h-auto"
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {jobDescription?.mustHaves && <div className="mt-4">
                                            <h3 className="text-instant-teams-teal-Main mb-2">Must Haves/What You Bring to the Table</h3>
                                            <p className="text-gray-500 text-sm">
                                                <div
                                                    className="dangerously"
                                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                                        jobDescription?.mustHaves
                                                    )}
                                                ></div>

                                            </p>
                                        </div>}
                                    </>
                                )
                        }


                        {
                            payRate.commission && <>
                                <div ref={commissionRef} className='mt-5'>
                                    <h1 className='text-instant-teams-teal-Main mb-2'>
                                        {payRate?.additionalBenifits === "empComm" ? "Commission" : "Bonus"}
                                    </h1>
                                </div>
                                <p className="text-gray-500 text-sm">
                                    <div
                                        className="dangerously"
                                        dangerouslySetInnerHTML={sanitizeMarkup(
                                            payRate.commission
                                        )}
                                    ></div>

                                </p>

                            </>
                        }


                    </div >
                </>
            ) : (
                <div className='flex items-center justify-center  h-full  '><h1 className='text-2xl text-gray-400 '> Select a Job</h1></div>
            )}



            {
                isShowing && (
                    <DialogWrapper
                        size={jobsDetailData?.noATS ? 'w-[50vw] h-auto' : "max-w-md"}
                        displayCloseIcon={true}
                        onClose={toggleShow}
                    >
                        {/* Sticky Header */}
                        <div className="w-full sticky top-0 bg-white z-10 px-4 py-3 flex justify-between items-center ">
                            <h1 className="text-instant-teams-blue-Main text-xl font-semibold">Apply Now</h1>
                            <div
                                className="flex justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                                onClick={toggleShow}
                            >
                                <FontAwesomeIcon icon={faTimes} color="#565656" />
                            </div>
                        </div>

                        {/* Scrollable Content */}
                        <div className="overflow-y-auto px-4 py-3 no-scrollbar " style={{ maxHeight: 'calc(90vh - 56px)' }}>
                            {(noATS !== true) ? (
                                <div className="p-3">
                                    <p className="text-[14px] text-gray-600">
                                        Thank you for exploring career opportunities with Instant Teams! We proudly offer customer experience (CX) roles within our own teams and partner with amazing brands to connect you with additional opportunities. If you don’t find the perfect fit today, be sure to check back often.
                                    </p>
                                    <p className="text-[14px] mt-4 text-gray-600">
                                        You’ll now be redirected to the application stage for this position, which will open in a new tab. Please ensure your popup blocker settings allow the page to open. Note that external sites may have privacy policies different from the Instant Teams Marketplace. We appreciate your trust as you navigate your career journey!
                                    </p>
                                    <div className="w-full flex justify-center items-center">
                                        <button
                                            onClick={handleApplyJobs}
                                            className={`py-2 px-8 text-white mt-8 font-medium rounded-full w-[200px] h-12 ${loading ? "bg-gray-400" : "bg-[#15415E]"}`}
                                        >
                                            {loading ? "..." : "Continue"}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className=" p-3">
                                    <p className="text-instant-teams-blue-Main mb-2 text-lg font-bold">Company Name</p>
                                    <p className="text-gray-400 text-base mb-3">{companyFullName}</p>
                                    <hr className="w-full" />
                                    <p className="text-instant-teams-blue-Main mb-2 mt-3 text-lg font-bold">Job Title</p>
                                    <p className="text-gray-400 text-base mb-3">{jobName}</p>
                                    <hr className="w-full" />
                                    <Resume userId={data?.user?.id} deviceType={platform} handleDone={toggleShow} jobData={jobsDetailData} />
                                </div>
                            )}
                        </div>
                    </DialogWrapper>
                )
            }
            <ToastAlert />
        </div >
    );
};

export default JobsDetails;

