import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import toast from "react-hot-toast";
import { assignAnnualSubscriptionToEmployer } from "../../../../../../callable-cloud-functions/cloudFunctions";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useLookup } from "../../../../../../data/lookup";

export const AddAnnualPlanSchema = (isSubmittingJobFirstTime: boolean) => {
  return z.object({
    includeDate: z.boolean().default(true),
    startDate: z.union([
      z.date(),
      z
        .string()
        .min(1, { message: "Start Date is required" })
        .datetime({ offset: true }),
    ]).optional(),
    orderNumber: z.string().optional(),
    email: z.string().optional(),
    name: z.string().optional(),
    title: z.string().optional(),
    phone: z.string().optional(),
    state: z.string().optional(),
    city: z.string().optional(),
    address: z.string().optional(),
    zipCode: z.string().optional()
  });
};

function AddAnnualSubcriptionForm({
  empId,
  toggleShowSubcriptionFormModal,
  selectedPlan,
}: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [formSchema, setFormSchema] = useState(AddAnnualPlanSchema(false));
  const { data: stateList = [] } = useLookup("state");

  type FormValues = z.infer<ReturnType<typeof AddAnnualPlanSchema>>;
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startDate: "",
      orderNumber: "",
      email: "",
      name: "",
      title: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      zipCode: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    control,
    register,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    setLoading(true);
    await assignAnnualSubscriptionToEmployer({
      employerId: empId,
      jobsPerMonth: selectedPlan,
      orderNumber: values?.orderNumber,
      startDate: values?.startDate,
      billingContact: {
        email: values?.email,
        name: values?.name,
        title: values?.title,
        phone: values?.phone,
        address: values?.address || "",
        state: values?.state || "",
        city: values?.city || "",
        zipCode: values?.zipCode || "",
      },
    })
      .then((res: any) => {
        toast.success("Annual plan added successfully");
        toggleShowSubcriptionFormModal();
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Having problem with adding annual plan, please try again");
        setLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white w-[900px] h-[700px] p-6 rounded-xl shadow-lg overflow-auto">
        <button
          onClick={toggleShowSubcriptionFormModal}
          className="absolute right-4 text-gray-500 hover:text-gray-700"
        >
          X
        </button>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Add a Plan
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Start Date
            </h3>
            <hr />
            <Controller
              control={control}
              name="startDate"
              render={(field) => (
                <DateGeneric
                  {...field}
                  display="Start Date"
                  timestamp={true}
                  error={errors?.startDate?.message}
                  required
                //   className="w-full border rounded-lg p-2"
                />
              )}
            />
          </div>
          <div className=" w-full items-center">
            <Controller
              control={control}
              name="orderNumber"
              render={(field) => (
                <FloatingLabelInput
                  {...field}
                  display="Order Number"
                  placeholder="Enter order number"
                  error={""}
                />
              )}
            />
            {errors?.orderNumber?.message && (
              <p className="text-red-500 mt-[-20px]  text-sm">
                {errors?.orderNumber?.message}
              </p>
            )}
          </div>
          <div className=" pt-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Billing Information
            </h3>
            <hr />
            <div className=" w-full mt-5 items-center">
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <FloatingLabelInput
                    {...field}
                    display="Email"
                    placeholder="Enter Email"
                    error={""}
                  />
                )}
              />
              {errors?.email?.message && (
                <p className="text-red-500 mt-[-20px]  text-sm">
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className=" w-full items-center">
              <Controller
                control={control}
                name="name"
                render={(field) => (
                  <FloatingLabelInput
                    {...field}
                    display="Full Name"
                    placeholder="Enter Full Name"
                    error={""}
                  />
                )}
              />
              {errors?.name?.message && (
                <p className="text-red-500 mt-[-20px]  text-sm">
                  {errors?.name?.message}
                </p>
              )}
            </div>
            <div className=" w-full items-center">
              <Controller
                control={control}
                name="title"
                render={(field) => (
                  <FloatingLabelInput
                    {...field}
                    display="Job Title"
                    placeholder="Enter Job Title"
                    error={""}
                  />
                )}
              />
              {errors?.title?.message && (
                <p className="text-red-500 mt-[-20px]  text-sm">
                  {errors?.title?.message}
                </p>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 font-medium mb-1">
                Phone Number
              </label>
              <PhoneInputWithCountry
                className="w-full border rounded-lg p-2"
                name="phone"
                international
                defaultCountry="US"
                countryCallingCodeEditable={false}
                control={control}
                onBlur={() => trigger("phone")}
              />
              {/* {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone.message}</p>
              )} */}
            </div>
            <Controller
              control={control}
              name="address"
              render={(field) => (
                <FloatingLabelInput
                  {...field}
                  display="Address Line"
                  type="text"
                  placeholder="Enter address"
                  error={errors?.address?.message}
                />
              )}
            />
            <div className="grid grid-cols-2 mt-6 gap-4">
              <Controller
                control={control}
                name="city"
                render={(field) => (
                  <FloatingLabelInput
                    {...field}
                    display="City"
                    placeholder="Enter City"
                    error={errors?.city?.message}
                  />
                )}
              />

              <div className={`flex  ${getValues("state") ? "font-bold w-[80px]" : "w-[140px]"}  flex-col  justify-center `}>
               
              <h1 className=" text-[13px]  z-40 text-[#15415E]">
                  State
                </h1>
                <select
                  className={`px-3 ml-[-10px] text-[13px] mt-[-9px] border-2 border-gray-900  ${getValues("stateResidence") ? "font-bold w-[80px]" : "w-[140px]"}  leading-8 focus:ring-1 focus:ring-gray-900 rounded-md`}
                  style={{ color: '#15415E', boxShadow: 'none' }}
                  name="state"
                  ref={register({
                    required: "Please select state.",
                  })}
                >
                  <option value="" selected disabled>
                    Select State<span className="text-red-500">*</span>
                  </option>
                  {stateList?.map((state: any) => (
                    <option key={state.id} value={state.code}>
                      {state.code}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {errors.state && (<div className="w-full flex ml-[-20px]  mt-[-40px] justify-end">
              {/* <hr
                  className={`mt-[-9px] border-t-2`}
                /> */}
              <p className=" text-base text-left text-red-500">
                {errors.state.message}
              </p>

            </div>)}
            <div className="">

              <Controller
                control={control}
                name="zipCode"
                render={(field) => (
                  <FloatingLabelInput
                    {...field}
                    display="ZipCode"
                    maxlength={5}
                    type="text"
                    placeholder="Enter Zip Code"
                    error={errors?.zipCode?.message}
                  />
                )}
              />
            </div>

          </div>
          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              disabled={loading}
              className="w-60 h-12 bg-[#15415E] text-white rounded-full hover:bg-[#1d5174] transition-all"
            >
              {loading ? "Adding Annual Plan..." : "Continue"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAnnualSubcriptionForm;
