type TPriceSummary = {
  planPrice: number;
  currentPlan: string;
  promoCode: string;
  isPromoCodeValid: boolean;
  promotionCodeData: any;
  hasQuantity?: boolean;
  quantity?: number;
};

const PriceSummary = ({
  planPrice,
  currentPlan,
  promoCode,
  isPromoCodeValid,
  promotionCodeData,
  hasQuantity = false,
  quantity = 1,
}: TPriceSummary) => {
  const planPriceToFixed = promotionCodeData
    ? (promotionCodeData?.subtotal / 100)?.toFixed(2)
    : (planPrice * quantity)?.toFixed(2);

  const discountToFixed = promotionCodeData
    ? (promotionCodeData?.discountAmount / 100)?.toFixed(2)
    : Number(0).toFixed(2);

  const total = promotionCodeData
    ? (promotionCodeData?.total / 100)?.toFixed(2)
    : (planPrice * quantity)?.toFixed(2);

  return (
    <div className="bg-[#E8F6FE] rounded-[20px] p-[20px] w-full text-[#1E596D]">
      <div className="flex justify-between text-[16px] leading-[19.81px] font-[400] font-[Gilroy-Medium]">
        <p>
          <span className="font-[Gilroy-Bold] capitalize">{currentPlan}</span>{" "}
          Job Posting in a year
        </p>
        {hasQuantity ? (
          <div className="flex flex-row gap-[10px]">
            <span className="font-bold">${planPrice?.toFixed(2)}</span>
            <span className="font-bold">x{quantity}</span>
          </div>
        ) : (
          <span className="font-bold">${planPriceToFixed}</span>
        )}
      </div>

      <div className="mt-[15px] flex justify-between font-bold text-[16px] leading-[19.81px] font-[400] font-[Gilroy-Bold]">
        <span>SubTotal</span>
        <span>${planPriceToFixed}</span>
      </div>

      <div className="mt-[5px] flex justify-between text-[16px] leading-[19.2px] font-[400] font-[Gilroy-Regular]">
        <span>Discount</span>
        <p>
          {isPromoCodeValid && (
            <span className="font-[Gilroy-Bold] font-[400] text-[14px] leading-[17.33px] text-[#1E596D]">
              Promo Applied: {promoCode}
            </span>
          )}

          <span className="ml-[20px]">-${discountToFixed}</span>
        </p>
      </div>

      <hr className="my-[20px] border-[#FFFFFF]" />

      <div className="flex justify-between text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]">
        <span>Total</span>
        <span>${total}</span>
      </div>
    </div>
  );
};

export default PriceSummary;
