import { useState, useEffect } from "react";
import CompanyProfileCard from "./CompanyProfileCardWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { getCompanyDetailsSchema } from "../../../../../../schemas/CompanyDetailsSchema";
import Input from "../../../../../custom-ui/new-ui/Input";
import Select from "../../../../../custom-ui/new-ui/Select";
import { useLookup } from "../../../../../../data/lookup";
import { EmployerNew } from "../../../../../../dataTypes/Employer";
import {
  updateEmployerNew,
  useEmployer,
} from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";
import EmployerDropzone from "../../../parts/employer-dropzone";
import DialogWrapper from "../../../../../custom-ui/dialog/Dialog_NewUI";
import { useParams } from "react-router-dom";

type CompanyDetailsCardProps = {};

const CompanyDetailsCard = ({ }: CompanyDetailsCardProps) => {
  const { user } = useAuth();
  const params = useParams();
  const employerId: any = user.claims.type === "A" ? params?.employerId : user.claims.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalProfileImage, setIsModalProfileImage] = useState(false);
  const [avatar, setAvatar] = useState("/images/default-employer.jpg");
  const [formSchema, setFormSchema] = useState(getCompanyDetailsSchema());

  const { data: companySizeOptions = [] } = useLookup("companySize");
  const { data: empIndustryTypes } = useLookup("emp_industryType")
  const { data: companyHiringNeedsOptions = [] } =
    useLookup("companyHiringNeeds");
  const { data: companyJobsToFillOptions = [] } =
    useLookup("companyJobsToFill");

  const closeModal = () => {
    setIsModalProfileImage(false);
  };

  type FormValues = z.infer<ReturnType<typeof getCompanyDetailsSchema>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyName: employer?.displayName ?? "",
      companyWebsite: employer?.profile?.url ?? "",
      location: employer?.location ?? "",
      industryType: employer?.industryType ?? "",
      companySize: employer?.companySize ?? "",
      hiringNeeds: employer?.hiringNeeds ?? "",
      jobsToFill: employer?.jobsToFill ?? "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    const {
      companyName,
      companyWebsite,
      location,
      industryType,
      companySize,
      hiringNeeds,
      jobsToFill,
      ...restValues
    } = values;

    const employerCollectionData: EmployerNew = {
      displayName: companyName,
      location,
      companySize: companySize || null,
      hiringNeeds: hiringNeeds || null,
      jobsToFill: jobsToFill || null,
      profile: {
        url: companyWebsite,
        sector: industryType || null,
        facebook: employer?.profile?.facebook ?? null,
        linkedin: employer?.profile?.linkedin ?? null,
        intro: employer?.profile?.intro ?? null,
        twitter: employer?.profile?.twitter ?? null,
        overview: employer?.profile?.overview ?? null,
        mission: employer?.profile?.mission ?? null,
      },
    };



    await updateEmployerNew(employerId, employerCollectionData)
      .then(() => {
        toast.success("Company Details updated successfully");
        setIsEditing(false);
      })
      .catch(() => {
        toast.error("There was an error updating Company Details");
      });
  };

  const handleButtonEdit = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    reset();
    setIntialValuesToForm(employer);
    setIsEditing(false);
  };

  const setIntialValuesToForm = (employerData: any) => {
    if (!employerData || (employerData && !Object.keys(employerData).length))
      return;

    if (employer?.logo) {
      setAvatar(employer.logo);
    }

    setValue("companyName", employer?.displayName ?? "");
    setValue("companyWebsite", employer?.profile?.url ?? "");
    setValue("location", employer?.location ?? "");
    setValue("industryType", employer?.profile?.sector ?? "");
    setValue("companySize", employer?.companySize ?? "");
    setValue("hiringNeeds", employer?.hiringNeeds ?? "");
    setValue("jobsToFill", employer?.jobsToFill ?? "");
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIntialValuesToForm(employer);
      setIsInitialized(true);
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CompanyProfileCard
        title="Company Details"
        onButtonClick={isEditing ? handleSubmit(onSubmit) : handleButtonEdit}
        onCancelClick={handleCancelClick}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        className={user.claims.type === "A" ? "h-fit w-full" : "h-fit "}

      >
        <div className="flex  flex-col gap-[30px]">
          <div className="flex items-center space-x-2 relative self-center mt-[30px]">
            <div
              className="relative w-28 h-28 cursor-pointer"
              onClick={() => setIsModalProfileImage(true)}
            >
              <img
                className="w-28 h-28 rounded-full object-cover"
                src={avatar ?? "/images/default-employer.jpg"}
                alt="Profile"
              />
              <img
                src="/images/editImage.png"
                alt="Edit"
                className="absolute bottom-0 right-0 w-6"
              />
            </div>
          </div>

          <form>
            <div className="flex flex-col gap-[30px]">
              <Controller
                control={control}
                name="companyName"
                render={(field) => (
                  <Input
                    {...field}
                    display="Company Name"
                    placeholder="Enter Company Name"
                    readOnly={!isEditing}
                    error={errors?.companyName?.message}
                    required={isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="companyWebsite"
                render={(field) => (
                  <Input
                    {...field}
                    display="Company Website"
                    placeholder="Enter Company Website"
                    readOnly={!isEditing}
                    error={errors?.companyWebsite?.message}
                    required={isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="location"
                render={(field) => (
                  <Input
                    {...field}
                    display="Location"
                    placeholder="Enter Location"
                    readOnly={!isEditing}
                    error={errors?.location?.message}
                    required={isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="industryType"
                render={(field) => (
                  <Select
                    {...field}
                    options={empIndustryTypes}
                    display="Industry Type"
                    placeholder="Select Industry Type"
                    disabled={!isEditing}
                    error={errors?.industryType?.message}
                  />
                )}
              />

              {/* <Controller
                control={control}
                name="industryType"
                render={(field) => (
                  <Input
                    {...field}
                    display="Industry Type"
                    placeholder="Enter Industry Type"
                    readOnly={!isEditing}
                    error={errors?.industryType?.message}
                  />
                )}
              /> */}

              <Controller
                control={control}
                name="companySize"
                render={(field) => (
                  <Select
                    {...field}
                    options={companySizeOptions}
                    display="Company Size"
                    placeholder="Select Company Size"
                    disabled={!isEditing}
                    error={errors?.companySize?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="hiringNeeds"
                render={(field) => (
                  <Select
                    {...field}
                    options={companyHiringNeedsOptions}
                    display="What best describes your hiring needs?"
                    placeholder="Select What best describes your hiring needs?"
                    disabled={!isEditing}
                    error={errors?.hiringNeeds?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="jobsToFill"
                render={(field) => (
                  <Select
                    {...field}
                    options={companyJobsToFillOptions}
                    display="How many jobs do you need to fill?"
                    placeholder="Select How many jobs do you need to fill?"
                    disabled={!isEditing}
                    error={errors?.hiringNeeds?.message}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </CompanyProfileCard>

      {isModalProfileImage && (
        <DialogWrapper
          title="Add logo"
          onClose={closeModal}
          displayCloseIcon={true}
          size="max-w-[600px]"
        >
          <>
            
            <div className=" w-full flex justify-center items-center my-2">
             <p > Upload image upto 8MB</p>  
            </div>

           
            <EmployerDropzone
              closeModal={closeModal}
              onDone={(empLogo: string) => setAvatar(empLogo)}
            />
          </>

        </DialogWrapper>
      )}
      <ToastAlert />
    </>
  );
};

export default CompanyDetailsCard;
