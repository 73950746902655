import { useState, useEffect } from "react";
import { useAuth } from "../../../../../providers/auth";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import InfoIcon from "../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";
import AuthActionTemplate from "../../../../../pages/auth-action-templates/auth-action";
import { getAuth, verifyPasswordResetCode } from "firebase/auth";
import {useUserByEmail2 } from "../../../../../data/user";


function ForgetPasswordComponent_NewUI() {
    const location = useLocation();
    const modeset = useSearchParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const oobCode: any = searchParams.get("oobCode");
    const [mode, setMode] = useState(searchParams.get("mode"));
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [email, setEmail] = useState();

    const { data: userData } = useUserByEmail2(email)

    // console.log(userData[0], "User data")

    const auth = useAuth();
    useEffect(() => {
        const auth1 = getAuth()
        console.log(oobCode)
        if (oobCode) {
            verifyPasswordResetCode(auth1, oobCode)
                .then((email) => {
                    setEmail(email)
                })
                .catch((error) => {
                    console.error("Error verifying oobcode:", error);
                });
        }
    }, [oobCode]);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSavePassword = () => {
        auth
            .confirmPasswordReset(password)
            .then(() => {
                setPassword("");
                setConfirmPassword("");
                if (userData[0]?.claims?.role === "COMM") {
                    window.location.href = "https://community.instantteams.com/";
                } else if (userData[0]?.claims?.type === "S") {
                    window.location.href = "https://jobs.instantteams.com/"; 
                } else {
                    navigate("/auth/signin")
                }

            })
            .catch((error: any) => {
                console.log("Error:", error);
            });
    };


    return (


        <>
            {
                mode == "resetPassword" ? <>
                    <div className="flex min-h-screen  sm:flex-col xs:flex-col ">
                        {/* Left Section with Background Image */}
                        <div
                            className="w-1/2 xs:w-full sm:w-full  xs:h-1/2 sm:h-1/2  bg-cover bg-center bg-white flex justify-center items-center  h-screen "

                        >
                            <div className="flex justify-center items-center  w-full h-full">
                                <img
                                    src="/images/bannerimg_new_MP.png"
                                    alt="Instant Teams Logo"
                                    className="w-[85%] h-[78%]"
                                />
                            </div>
                        </div>

                        {/* Right Section with Form */}
                        <div className="w-1/2 sm:w-full xs:w-full flex flex-col rounded-l-full justify-center px-16 sm:px-2 xs:px-2 py-8 bg-white">
                            <div className="justify-center items-center">
                                <h1 className="text-2xl text-center text-instant-teams-blue-Main text-lg font-semibold mb-2">
                                    Create New Password
                                </h1>
                                <p className="text-gray-300 text-center mb-6">Please enter a new password</p>
                            </div>

                            {/* Password Input */}
                            <div className="mb-4 w-full flex justify-center relative">
                                <div className="w-[70%]">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1 relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-3 flex items-center"
                                            onClick={handleTogglePassword}
                                        >
                                            <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
                                        </button>
                                        <div
                                            className="absolute inset-y-0 right-10 flex items-center"
                                        >
                                            <InfoIcon
                                                viewDown={true}
                                                singupFlow={true}
                                                tooltipText={`
                                        <ul>
                                            <li>At least 8 characters</li>
                                            <li>At least one capital letter</li>
                                            <li>At least one lowercase letter</li>
                                            <li>At least one number or symbol</li>
                                        </ul>`}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6 relative">
                                        <label
                                            htmlFor="confirm-password"
                                            className="block mt-4 text-sm font-medium text-gray-700"
                                        >
                                            Confirm Password <span className="text-red-500">*</span>
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                id="confirm-password"
                                                className="w-full px-3 py-2 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-3 flex items-center"
                                                onClick={handleToggleConfirmPassword}
                                            >
                                                <i className={`fas fa-eye${showConfirmPassword ? "" : "-slash"}`}></i>
                                            </button>
                                            <div
                                                className="absolute inset-y-0 right-10 flex items-center"
                                            >
                                                <InfoIcon
                                                    viewDown={true}
                                                    singupFlow={true}
                                                    tooltipText={`
                                            <ul>
                                                <li>At least 8 characters</li>
                                                <li>At least one capital letter</li>
                                                <li>At least one lowercase letter</li>
                                                <li>At least one number or symbol</li>
                                            </ul>`}
                                                />
                                            </div>
                                        </div>

                                        {/* Save Password Button */}
                                        <div className="mt-14 flex justify-center">
                                            <button
                                                onClick={handleSavePassword}
                                                className="w-auto rounded-full bg-instant-teams-blue-Main text-white py-2 px-10 hover:bg-blue-800 transition-colors"
                                            >
                                                Save Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <AuthActionTemplate />
            }

        </>


    );
}




export default ForgetPasswordComponent_NewUI;
