import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmployerJobsForEmployerNew } from "../../../../data/employer";
import { useAllListingsJobForEmp } from "../../../../data/listings";
import JobNewListingLineComponent_test from "./EmpJobNewListingLineComponent_NewUI";
import useModal from "../../../../hooks/useModal";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import CreateJobNewBasicForm from "../../../jobs/parts/CreateJobNewBasicForm";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const EmployerJobs_NewUI = () => {
  const params = useParams();
  const [id, setId] = useState("");
  console.log(id)
  const { isShowing, toggle } = useModal();

  const {
    data: jobs = [],
  } = useAllListingsJobForEmp(id);
  useEffect(() => {
    setId(params.employerId);
  }, [params.employerId]);

  const handleFormAlert = (alert: string) => {
    // alert {type:string, message:string}

    if (alert === "error") {
      toast.error("There was an error creating your job");
    } else if (alert === "success") {
      toast.success("New Job (New) successfully created");
    }
  };

  return (
    <>

      <div className='min-h-screen'>
        <button
          className=" px-5 h-10 mb-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          onClick={toggle}
        >
          Create New Job
        </button>
        <div className="grid grid-cols-4 gap-4 w-full">
          {id && jobs && jobs?.length > 0
            ? jobs.map((job: any) =>

            (
              <JobNewListingLineComponent_test key={job?.id} row={job} />
            ))
            : <div className="w-[70vw] h-[70vh]  flex justify-center items-center">
              <h1 className=" text-2xl font-bold text-instant-teams-blue-Main">
                No Jobs
              </h1>

            </div>}
        </div>


      </div>

      {isShowing && (
        <DialogWrapper title="" onClose={() => toggle()}>
          <>
            <div className="w-full xs:mt-2 md:mt-2 mb-8 flex  justify-between ">
              <h1 className="text-instant-teams-blue-Main text-xl text-center font-semibold">
                Create New Job
              </h1>
              <div
                className="flex justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                onClick={toggle}
              >
                <FontAwesomeIcon icon={faTimes} color="#565656" />
              </div>
            </div>

            <CreateJobNewBasicForm
              handleFormAlert={handleFormAlert}
              toggle={toggle}
              employerId={id}
            />
          </>

        </DialogWrapper>
      )}

      <ToastAlert />
    </>
  );
};

export default EmployerJobs_NewUI;
