import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { createSetupIntent } from "../../../../../../callable-cloud-functions/cloudFunctions";
import AddNewCardModal from "./AddNewCardModal";
import { manageConfiguration } from "../../../../../../data/configuration";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(
//   "pk_test_51PxsDGELIBAvshmEguDLDTRKLzRxS0ehcciCmJ9Te5zJ6gQJJyv7MM84VOrLqnJ6DH0B5s5DtHJSE5EgsPLY4FTJ00gMXxCpqB",
// );

const appearance = {
  theme: "stripe",
};
// Enable the skeleton loader UI for optimal loading.
const loader = "auto";

type ElementsProviderProps = {
  customerId: string;
  employerId: string;
  onDone: () => void;
};

const ElementsProvider = ({
  customerId,
  employerId,
  onDone,
}: ElementsProviderProps) => {
  // useEffect(() => {
  //   employerInfo = localStorage.getItem(employerInfo) || {};
  // }, []);
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const configData = await manageConfiguration();
        const publishableKey = configData[0]?.stripe_live
          ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE
          : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

        setStripePromise(loadStripe(publishableKey || ""));

        const result = await createSetupIntent({
          employerId: employerId,
          customerId: customerId,
        });

        // Cast the response to the expected type
        const data = result.data as any;

        if (isMounted && data) {
          setClientSecret(data?.clientSecret);
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [employerId, customerId]);

  const handlePayment = (data: any) => {
    console.log(data, "======>data inside handlePayment");

    navigate("/home");
    // setRenderView(3)
    // onDone(data);
  };

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          <AddNewCardModal
            employerId={employerId}
            customerId={customerId}
            onDone={onDone}
          />
        </Elements>
      ) : (
        <div className="flex items-center justify-center bg-gray-100 h-[70vh]">
          <div className="bg-white p-8 rounded shadow-md text-center max-w-md">
            <h1 className="text-2xl font-bold text-gray-800">
              Redirecting you to card page...
            </h1>
            <p className="mt-4 text-gray-600">
              Please do not refresh or close this page.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ElementsProvider;
