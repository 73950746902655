import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { filterByLatestJobs, filterJobsByCriteria } from '../util';
import { useGlobalState } from '../../../../../../providers/globalState/GlobalStateProvider';
import { getDeviceType } from '../util';

const FilterModal = ({ jobsData, filters, setFilters, bookmarks, closeModal, setSortedBy,setFilteredJobs ,setAppliedFilter}) => {
    const { globalState, setGlobalState } = useGlobalState();
    const [error, setError] = useState(null)

    console.log(filters)

    // Handle radio changes for other fields
    const handleRadioChange = (event) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value,
        });
    };

    // Handle checkbox changes
    const handleCheckboxChange = (event) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.checked,
        });
    };

    // Handle changes in salary type inputs
    const handleSalaryInputChange = (event, type) => {
        const { name, value } = event.target;
        const updatedRange = {
            ...filters.salaryRange,
            [name]: value ? parseInt(value, 10) : '',
        };

        setFilters({
            ...filters,
            salaryRange: type === 'hourly' ? { ...updatedRange } : updatedRange,
        });
    };

    // Reset all filters to default values
    const resetFilters = () => {
        setAppliedFilter(false)
        setFilters({
            workSetting: '',
            workType: '',
            postedBy: '',
            salaryRange: { min: 0, max: 0 }, // Reset salary range to default object structure
            salaryType: '', // Reset to default salary range
            multipleOpenings: false,
            bookmarkedJobs: false,
            instantTeamsJobs: false,
        });
        closeModal();
        const _sortedBylatest = filterByLatestJobs(jobsData)
        console.log(_sortedBylatest,"filtered by bookmarks")
        setFilteredJobs(_sortedBylatest);
        setGlobalState({ ...globalState, filteredJobs: _sortedBylatest });
    };

    const handleApplyFilter = () => {
        setSortedBy("datePosted")
        setAppliedFilter(true)
        if (filters.salaryRangeType === 'salary' || filters.salaryRangeType === 'hourly') {
            if (!filters.salaryRange?.min || !filters.salaryRange?.max) {
                setError("Please enter both minimum and maximum values")

            } else {
                if (filters.salaryRange?.min < filters.salaryRange?.max) {
                    const _filteredJobs = filterJobsByCriteria(jobsData, filters, bookmarks)
                    const _sortedBylatest = filterByLatestJobs(_filteredJobs)
                    setFilteredJobs(_sortedBylatest);
                    setGlobalState({ ...globalState, filteredJobs: _sortedBylatest });
                    closeModal()
                } else {
                    setError("Minimum value should be less than the maximum ")
                }

            }
        } else {
            const _filteredJobs = filterJobsByCriteria(jobsData, filters, bookmarks)
            const _sortedBylatest = filterByLatestJobs(_filteredJobs)
            console.log(_sortedBylatest)
            setFilteredJobs(_sortedBylatest);
            setGlobalState({ ...globalState, filteredJobs: _sortedBylatest });
            closeModal()
        }
    }
    return (
        <div style={{
            height: "550px",
            overflow: "auto",
         
            marginTop: "-70px",
            scrollbarWidth: 'thin',
            scrollbarColor: '#b3b3b3 #f0f0f0',
        }}
            className="w-80 xs:ml-1 md:ml-[20%]    lg:ml-[20%] xl:ml-[20%]  2xl:ml-[20%] overflowy-y-auto p-4 bg-white shadow-lg rounded-lg">
            <div className="flex justify-between  items-center mb-6">
                <h2 className="text-xl text-instant-teams-blue-Main font-semibold">Filter By</h2>
                <div onClick={closeModal} className="cursor-pointer bg-gray-200 rounded-full px-4 py-2">
                    <FontAwesomeIcon icon={faTimes} onClick={closeModal} className="text-gray-600  text-sm cursor-pointer" />
                </div>

            </div>
            <hr className=' py-2 ' />
            <div>
                {/* Existing Filters */}
                <div>
                    <h3 className=" text-gray-500 text-base mb-2">Work Setting</h3>
                </div>
                <label className="block mb-1  text-gray-500 text-base ">
                    <input
                        type="radio"
                        name="workSetting"
                        className='mr-1 '
                        value="hybrid"
                        onChange={handleRadioChange}
                        checked={filters.workSetting === 'hybrid'}
                    /> Hybrid
                </label>
                <label className="block  text-gray-500 text-base ">
                    <input
                        type="radio"
                        name="workSetting"
                        className='mr-1'
                        value="remote"
                        onChange={handleRadioChange}
                        checked={filters.workSetting === 'remote'}
                    /> Remote
                </label>
                <hr className=' mt-5 ' />
                <h3 className=" mt-4 mb-2  text-gray-500 text-base ">Work Type</h3>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="workType"
                        className='mr-1'
                        value="full_time"
                        onChange={handleRadioChange}
                        checked={filters.workType === 'full_time'}
                    /> Full-Time
                </label>
                <label className="block text-gray-500 text-base">
                    <input
                        type="radio"
                        className='mr-1'
                        name="workType"
                        value="part_time"
                        onChange={handleRadioChange}
                        checked={filters.workType === 'part_time'}
                    /> Part-Time
                </label>
                <hr className=' mt-5 ' />
                <h3 className=" mt-4 text-gray-500 text-base mb-2">Posted By</h3>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="postedBy"
                        className='mr-1'
                        value="all"
                        onChange={handleRadioChange}
                        checked={filters.postedBy === 'all'}
                    /> All
                </label>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="postedBy"
                        className='mr-1'
                        value="last24hrs"
                        onChange={handleRadioChange}
                        checked={filters.postedBy === 'last24hrs'}
                    /> Last 24 hrs
                </label>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="postedBy"
                        className='mr-1'
                        value="lastWeek"
                        onChange={handleRadioChange}
                        checked={filters.postedBy === 'lastWeek'}
                    /> Last Week
                </label>
                <label className="block text-gray-500 text-base">
                    <input
                        type="radio"
                        name="postedBy"
                        className='mr-1'
                        value="lastMonth"
                        onChange={handleRadioChange}
                        checked={filters.postedBy === 'lastMonth'}
                    /> Last Month
                </label>

                {/* New Salary Range Filter */}

                {/* Salary Range Section */}
                <h3 className="text-gray-500 mt-4 mb-2">Salary Range</h3>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="salaryRangeType"
                        className="mr-1"
                        value="hourly"
                        onChange={handleRadioChange}
                        checked={filters.salaryRangeType === 'hourly'}
                    />{' '}
                    Hourly
                </label>

                {filters.salaryRangeType === 'hourly' && (
                    <>

                        <div className="flex my-3 w-80 space-x-3">
                            <input
                                placeholder="Min"
                                name="min"
                                value={filters.salaryRange?.min || ''}
                                onChange={(e) => { handleSalaryInputChange(e, 'hourly'); setError(null) }}
                                className="w-28 rounded-2xl "
                            />
                            <input
                                placeholder="Max"
                                name="max"
                                value={filters.salaryRange?.max || ''}
                                onChange={(e) => { handleSalaryInputChange(e, 'hourly'); setError(null) }}
                                className="w-28 rounded-2xl"
                            />
                        </div>
                        {error && <p className='text-red-500 text-sm'>{error}</p>}

                    </>
                )}

                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="salaryRangeType"
                        className="mr-1"
                        value="salary"
                        onChange={handleRadioChange}
                        checked={filters.salaryRangeType === 'salary'}
                    />{' '}
                    Yearly
                </label>

                {filters.salaryRangeType === 'salary' && (
                    <>
                        <div className="flex my-2 w-80 space-x-3">
                            <input
                                placeholder="Min"
                                name="min"
                                value={filters.salaryRange?.min || ''}
                                onChange={(e) => { handleSalaryInputChange(e, 'salary'); setError(null) }}
                                className="w-28 rounded-2xl"
                            />
                            <input
                                placeholder="Max"
                                name="max"
                                value={filters.salaryRange?.max || ''}
                                onChange={(e) => { handleSalaryInputChange(e, 'salary'); setError(null) }}
                                className="w-28 rounded-2xl"
                            />
                        </div>
                        {error && <p className='text-red-500 mb-1 text-sm'>{error}</p>}
                    </>

                )}
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="salaryRangeType"
                        className='mr-1'
                        value="unpaid_internship"
                        onChange={handleRadioChange}
                        checked={filters.salaryRangeType === 'unpaid_internship'}
                    /> Free Internship
                </label>
                <label className="block text-gray-500 text-base mb-1">
                    <input
                        type="radio"
                        name="salaryRangeType"
                        className='mr-1'
                        value="commission"
                        onChange={handleRadioChange}
                        checked={filters.salaryRangeType === 'commission'}
                    /> Commission
                </label>

                {/* New Checkboxes */}
                <hr className=' mt-4 ' />
                <div className="mt-3">
                    <label className="block text-gray-500 mr-3 text-base mb-1">
                        Multiple Openings
                        <input
                            type="checkbox"
                            className='ml-4 rounded-sm'
                            name="multipleOpenings"
                            onChange={handleCheckboxChange}
                            checked={filters.multipleOpenings}
                        />
                    </label>
                    <hr className=' mt-3 ' />
                    <label className="block text-gray-500 mt-2 text-base mb-1">
                        Bookmarked Jobs
                        <input
                            type="checkbox"
                            className='ml-5 rounded-sm'
                            name="bookmarkedJobs"
                            onChange={handleCheckboxChange}
                            checked={filters.bookmarkedJobs}
                        />
                    </label>
                    <hr className=' mt-3 ' />
                    <label className="block mt-2 text-gray-500 text-base">
                        Instant Teams Jobs
                        <input
                            type="checkbox"
                            className='ml-3 rounded-sm'
                            name="instantTeamsJobs"
                            onChange={handleCheckboxChange}
                            checked={filters.instantTeamsJobs}
                        />
                    </label>
                    <hr className=' mt-4 ' />
                </div>

                {/* Reset and Apply Buttons */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        className="text-instant-teams-teal-D1 text-sm"
                        onClick={resetFilters}
                    >
                        Reset All
                    </button>
                    <button
                        className="bg-instant-teams-blue-Main text-white py-2 px-10 text-sm rounded-3xl hover:bg-blue-600"
                        onClick={handleApplyFilter}
                    >
                        Apply Filter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;
