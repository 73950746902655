import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { JobDescription } from "../dataTypes/JobDescription";
import { collectionNames } from "./dictionary/collectionNames";

const lookupRef = collection(db, collectionNames.lookup);

export function useLookup(group: string) {
  return useQuery(
    [`${group}Lookup`, { group }],
    createQuery(() =>
      query(
        lookupRef,
        orderBy("sortOrder", "asc"),
        where("group", "==", group),
      ),
    ),
    { enabled: !!group },
  );
}

export function useLookupActive(group: string) {
  return useQuery(
    [`${group}Lookup`, { group }],
    createQuery(() =>
      query(
        lookupRef,
        where("group", "==", group),
        where("isActive", "==", true),
        orderBy("sortOrder", "asc"),
      ),
    ),
    { enabled: !!group },
  );
}

export function useLookupItem(group: string, code: string) {
  return useQuery(
    [`${group}Lookup`, { group, code }],
    createQuery(() =>
      query(
        lookupRef,
        orderBy("sortOrder", "asc"),
        where("group", "==", [group]),
        where("code", "==", [code]),
      ),
    ),
    { enabled: !!group && !!code },
  );
}

export function useLookupItemByArray(group: string, code: Array<string>) {
  return useQuery(
    [`${group}Lookup`, { group, code }],
    createQuery(() =>
      query(
        lookupRef,
        orderBy("sortOrder", "asc"),
        where("group", "==", group),
        where("code", "in", code),
      ),
    ),
    { enabled: !!group && !!code },
  );
}

export function useLookupCode(code: string) {
  return useQuery(
    [`${code}Lookup`, { code }],
    createQuery(() =>
      query(lookupRef, orderBy("sortOrder", "asc"), where("code", "==", code)),
    ),
    { enabled: !!code },
  );
}

export function useFindLookup(group: string, code: string) {
  return useQuery(
    [`${code}Lookup`, { code }],
    createQuery(() =>
      query(
        lookupRef,
        orderBy("sortOrder", "asc"),
        where("group", "==", group),
        where("code", "==", code),
      ),
    ),
    { enabled: !!code },
  );
}
