import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { updateAutoSave } from "../../../data/utility";
import { useUser } from "../../../providers/database";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";
import { useLookup } from "../../../data/lookup";
import { USA_States } from "../../seeker/utils";


function ProfileSettings({
  closeProfileModal,
}: {
  closeProfileModal: Function;
}) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { data: userData } = useUser(auth?.user?.uid);



  const { register, handleSubmit, errors, reset, getValues, trigger, watch } = useForm({
    defaultValues: {
      name: "",
      preferredName: "",
      state: "",
      city: "",
      zipCode: "",
      country: ""
    }
  });

  useEffect(() => {
    if (userData) {
      reset({
        name: userData.name || '',
        preferredName: userData.preferredName || '',
        state: userData.seekerLocationInfo?.stateResidence || '',
        city: userData.seekerLocationInfo?.city || '',
        zipCode: userData.seekerLocationInfo?.zipCode || '',
        country: userData.seekerLocationInfo?.country || ''

      });
    }
  }, [userData, reset]);

  const onSubmit = async (data: any) => {
    try {

      setPending(true);


      await updateAutoSave(collectionNames.users, auth?.user?.uid, {
        name: data?.name,
        preferredName: data?.preferredName,
        seekerLocationInfo: {
          stateResidence: data?.state,
          zipCode: data?.state !== "Outside-USA" ? data?.zipCode : null,
          country: data?.state === "Outside-USA" ? data?.country : null,
          city: data?.city,
        }// Adjusted to `data.name` instead of `data.data.name`
      });

      // Sign out after successful update

    } catch (error) {
      toast.error(`${error}`);
      console.error("Failed to update user data:", error);
    } finally {
      // Hide pending indicator
      closeProfileModal()
      setPending(false);
    }
  };


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-6">

        <div className="flex mb-10 ">

          <p className="border-b w-full py-2 font-bold text-xl text-[#15415E]">Edit Personal Information</p>
        </div>



        <div className="relative mb-10">
          <input
            type="text"
            name="name"
            placeholder=" "
            ref={register({
              required: "Please enter your full name.",
            })}

            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="name"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Full Name <span className="text-red-600">*</span>
          </label>
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}


        </div>


        <div className="relative mb-10">
          <input
            type="text"
            name="preferredName"
            placeholder=" "
            ref={register({
              required: "Please enter your preferredName",
            })}

            className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
            style={{ boxShadow: 'none' }}
          />
          <label
            htmlFor="preferredName"
            className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
          >
            Preferred Name
          </label>

          {errors.preferredName && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.preferredName.message}
            </p>
          )}

          <div className="  w-full grid grid-cols-2 mt-10 space-x-4">
            <div>
              <div className={`flex  ${getValues("state") ? "font-bold w-[240px]" : "w-[240px]"}  flex-col  justify-start `}>
                <h1 className="text-[13px] mt-[-9px] z-40 text-[#1b4d6e]">
                  State <span className="text-red-500 text-[12px]">*</span>
                </h1>
                <select
                  className={`px-3 ml-[-10px] mt-[-12px] text-[13px] border-2 border-gray-900  ${getValues("state") ? "font-bold w-full" : "w-[240px]"}  leading-8 focus:ring-1 focus:ring-gray-900 rounded-md`}
                  style={{ color: '#15415E', boxShadow: 'none', borderColor: errors.state ? 'red' : '' }}
                  name="state"
                  ref={register({
                    required: "Please select state.",
                  })}
                >
                  <option value="" selected disabled>
                    Select State
                  </option>
                  {USA_States?.map((state: any) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.state}
                    </option>
                  ))}
                </select>
                <hr
                  className={`mt-[-9px] border-t-2 border-gray-300 ${getValues("state") ? "font-bold w-[240px]" : "w-[240px]"} ${errors.state ? "border-red-600 bg-red-600" : ""
                    }`}
                />
              </div>
              {errors.state && (
                <p className="text-sm mt-1 text-left text-red-600">
                  {errors.state.message}
                </p>
              )}
            </div>
            <div className="relative">
              <input
                type="text"
                name="city"
                placeholder=" " // Empty placeholder for floating effect
                ref={register()} // No required validation
                onBlur={async () => await trigger("city")}
                className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                style={{ boxShadow: "none" }}
              />
              <label
                htmlFor="City"
                className="absolute -top-4 text-sm text-[#1b4d6e] bg-white px-1 transition-all duration-200 
      peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm 
      peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
              >
                City
              </label>
            </div>


          </div>

          {watch("state") !== "Outside-USA" && <div className="relative mt-7 w-[50%] ">
            <input
              type="text"
              name="zipCode"
              placeholder=" "  // Empty placeholder for floating effect
              ref={register({
                validate: (value: any) => {
                  if (watch("state") !== "Outside-USA") {
                    if (!value) {
                      return "Please enter your Zipcode.";
                    }
                    if (!/^\d{5}$/.test(value)) {
                      return "Zipcode must be exactly 5 digits.";
                    }
                  }
                  return true;
                },
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("zipCode")}
              className="peer mt-1 block w-full  inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: 'none' }}
            />
            <label
              htmlFor="City"
              className="absolute -top-4 text-sm text-[#1b4d6e] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Zipcode <span className="text-red-600">*</span>
            </label>
            {errors.zipCode && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.zipCode.message}
              </p>
            )}
          </div>}

          {watch("state") === "Outside-USA" && <div className="relative mt-7 w-[50%] ">
            <input
              type="text"
              name="country"
              placeholder=" "  // Empty placeholder for floating effect
              ref={register({
                validate: (value) => {
                  if (watch("state") === "Outside-USA" && !value) {
                    return "Please enter your Country.";
                  }
                  return true;
                }
              })}
              //onChange={async () => await trigger("name")}
              onBlur={async () => await trigger("country")}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{ boxShadow: 'none' }}
            />
            <label
              htmlFor="City"
              className="absolute -top-4 text-sm text-[#1b4d6e] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
            >
              Country <span className="text-red-600">*</span>
            </label>
            {errors.country && (
              <p className="mt-1 text-sm text-left text-red-600">
                {errors.country.message}
              </p>
            )}
          </div>}
        </div>






        <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">

          <button
            className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-[#15415E] w-full h-12 rounded-full"
            onClick={() => closeProfileModal()}
          >
            Cancel
          </button>
          <button
            className="py-2 bg-[#15415E] text-white w-full rounded-full h-12 "
            type="submit"
            disabled={pending}
          >
            Save
          </button>
        </div>
      </form>
      < ToastAlert />
    </>

  );
}

export default ProfileSettings;
