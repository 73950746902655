import { useEffect, useState, useCallback } from "react";
import { updateUser } from "../../../../../data/user";
import { updateUserOnboardedStatus } from "../../../../../data/user";
import ProgressDots from "../../../../custom-ui/progress-dots/ProgressDots";
import SuccessCircleCheck from "../../../../custom-ui/success/SuccessCircleCheck";
import AddressForm from "../../../../address/AddressForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../providers/auth";
import { Invitation } from "../../../../../dataTypes/Invitation";
import { useForm } from "react-hook-form";
import { AuthStatus, Origin } from "../../../../../dataTypes/AuthOrigin";
import { createAuthOrigin } from "../../../../../data/authOrigin";
import { Timestamp } from "firebase/firestore";
import { errorLogger } from "../../../../../providers/clientLogger";
import EmployerRegistrationStepFour from "../EmployerRegistrationStepFour";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PwdCheck from "../../../../custom-ui/pwd-check/PwdCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "../InfoIcon";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  employerGeneralUserOnboardingSchema,
  PASSWORD_REQ,
  pwdSchema,
} from "../Schema/registrationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import MultifactorEnrollmentPage from "../../../../../pages/register/multifactor-enrollment";

type UIComponent = {
  name: string;
  label: string;
  type: string;
  placeholder: string;
  requiredMessage: string;
};

type EmployerRegistration = {
  email: string;
  company: string;
  title: string;
  password: string;
  employerId: string;
  type: string;
  name: string;
  invitationId: string;
  displayName?: string;
  phone: string;
  confirmPassword: string;
  terms: boolean;
};

function EmployerOnboardingIndex({
  invitation,
  onDone,
  processingInvitation,
}: {
  invitation: Invitation;
  onDone: () => void;
  processingInvitation: (status: boolean) => void;
}) {
  const auth = useAuth();
  const navigate = useNavigate();
  const userId = auth?.user?.id;
  const genEmployerData : any = localStorage.getItem("genEmployerData");

  const [activeStep, setActiveStep] = useState(1);
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [employerInfo, setEmployerInfo] = useState({});

  const totalSteps = 2;

  const {
    handleSubmit,
    register,
    errors,
    getValues,
    control,
    trigger,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: invitation.email,
      company: invitation.company,
      title: invitation.title ?? "",
      name: invitation.name ?? "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(employerGeneralUserOnboardingSchema),
  });

  useEffect(() => {
    if (activeStep >= totalSteps) {
      updateUserOnboardedStatus(userId, true)
        .then(() => console.log("Onboarding complete"))
        .catch(console.error);
    }
  }, [activeStep, totalSteps, userId]);

  useEffect(()=>{
    if(genEmployerData){
      const employerData: any = JSON.parse(genEmployerData)
      setEmployerInfo(employerData);
      setActiveStep(2);
    }
  },[genEmployerData])

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev: any) => !prev);
  }, []);

  const toggleConfirmPasswordVisibility = useCallback(() => {
    setShowConfirmPassword((prev: any) => !prev);
  }, []);

  const onSubmit = async (data: EmployerRegistration) => {
    setPending(true);
    processingInvitation(true);

    const signupEmployerData = {
      ...data,
      invitationId: invitation.id,
      employerId: invitation.employerId,
      type: invitation.type,
      terms: data.terms,
    };
    try {
      await createAuthOrigin({
        email: data.email.toLowerCase(),
        origin: Origin.employerInvited,
        status: AuthStatus.start,
        lastUpdated: Timestamp.now(),
      });
      setEmployerInfo(signupEmployerData);
      const userData = await auth.signupNewEmployer(signupEmployerData);
      if(userData?.uid){
        localStorage.setItem("genEmployerData", JSON.stringify(signupEmployerData));
        setActiveStep(2);
      }
    } catch (error: any) {
      errorLogger(error.message, {
        component: "EmployerOnboardingIndex",
        flow: "Employer Registration",
        userId: "n/a",
      });
      setFormAlert("There was a problem creating the account.");
    } finally {
      processingInvitation(false);
      setPending(false);
    }
  };

  const renderActiveStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="max-w-2xl mx-auto p-6 overflow-y-scroll no-scrollbar bg-white">
              <form
                className="space-y-8"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                {/* Hidden Fields */}
                {/* {["employerId", "invitationId", "type"].map((field) => (
                  <input key={field} name={field} type="hidden" />
                ))} */}

                {/* Visible Fields */}
                {[
                  {
                    name: "name",
                    label: "Full Name",
                    type: "text",
                    placeholder: "Full Name",
                    requiredMessage: "Please enter your full name.",
                  },
                  {
                    name: "email",
                    label: "Email",
                    type: "email",
                    placeholder: "Email",
                    requiredMessage: "Please enter an email.",
                  },
                  {
                    name: "company",
                    label: "Company",
                    type: "text",
                    placeholder: "Company",
                    requiredMessage: "Please enter your company.",
                  },
                  {
                    name: "title",
                    label: "What is your title",
                    type: "text",
                    placeholder: "Job Title",
                    requiredMessage: "Please enter your job title.",
                  },
                ].map(
                  ({
                    name,
                    label,
                    type,
                    placeholder,
                    requiredMessage,
                  }: UIComponent) => (
                    <div className="relative mb-7" key={name}>
                      <input
                        readOnly
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        ref={register({ required: requiredMessage })}
                        className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                        style={{ boxShadow: "none" }}
                      />
                      <label
                        htmlFor={name}
                        className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                      >
                        {label} <span className="text-red-600">*</span>
                      </label>
                      {errors[name as keyof typeof errors] && (
                        <p className="mt-1 text-sm text-left text-red-600">
                          {errors[name as keyof typeof errors]?.message}
                        </p>
                      )}
                    </div>
                  ),
                )}

                {/* Phone Number */}
                <div className="relative mb-7">
                  <PhoneInputWithCountry
                    className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
                    style={{ boxShadow: "none" }}
                    name="phone"
                    international
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    control={control}
                    onBlur={async () => await trigger("phone")}
                    rules={{
                      required: "Phone number is required",
                      pattern: {
                        value: /^\+?[1-9]\d{1,14}$/,
                        message: "Invalid phone number",
                      },
                    }}
                  />
                  <label
                    htmlFor="phone"
                    className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                  >
                    Phone Number <span className="mr-1 text-red-600">*</span>
                  </label>
                  {dirtyFields.phone && errors.phone && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                {/* Password Fields */}
                {[
                  {
                    name: "password",
                    label: "Password",
                    toggle: togglePasswordVisibility,
                    requiredMessage:"Password is required",
                    show: showPassword,
                  },
                  {
                    name: "confirmPassword",
                    label: "Confirm Password",
                    toggle: toggleConfirmPasswordVisibility,
                    requiredMessage:"Password confirmation is required",
                    show: showConfirmPassword,
                  },
                ].map(({ name, label, toggle,requiredMessage, show }: any) => (
                  <div className="relative mb-7" key={name}>
                    <input
                      type={show ? "text" : "password"}
                      name={name}
                      placeholder=" "
                      autoComplete="on"
                      ref={register({ required: requiredMessage })}
                      onBlur={async () => await trigger(name)}
                      className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
                      style={{ boxShadow: "none" }}
                    />
                    <label
                      htmlFor={name}
                      className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                    >
                      {label} <span className="text-red-600">*</span>
                    </label>
                    <div
                      className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                      onClick={toggle}
                    >
                      <FontAwesomeIcon
                        icon={show ? faEye : faEyeSlash}
                        color="#15415E"
                        className="w-4"
                      />
                    </div>
                    {errors[name as keyof typeof errors] && (
                      <p className="mt-1 text-sm text-left text-red-600">
                        {errors[name as keyof typeof errors]?.message}
                      </p>
                    )}
                  </div>
                ))}

                {/* Terms and Conditions */}
                <div className="relative mb-7 items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      name="terms"
                      ref={register({
                        required: "Please agree to our terms of service.",
                      })}
                      className="h-4 w-4 text-[#15415E] focus:ring-gray-500 border-gray-600 rounded"
                    />
                    <label
                      htmlFor="terms"
                      className="text-[#616161] ml-3 text-sm"
                    >
                      By creating an account, you agree to our{" "}
                      <a
                        href="https://instantteams.com/terms-and-conditions/"
                        target="_blank"
                        className="text-[#15415E] underline"
                        rel="noreferrer"
                      >
                        Terms
                      </a>{" "}
                      and have read and acknowledge our{" "}
                      <a
                        href="https://instantteams.com/privacypolicy/"
                        target="_blank"
                        className="text-[#15415E] underline"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div>
                  {errors.terms && (
                    <p className="mt-1 text-sm text-left text-red-600">
                      {errors.terms.message}
                    </p>
                  )}
                </div>

                {/* Submit Button */}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]"
                    style={{ width: "200px" }}
                    disabled={pending}
                  >
                    {pending ? (
                      <div className="flex items-center justify-center space-x-2">
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 2:
        return (
          <EmployerRegistrationStepFour
            onDone={onDone}
            employerInfo={employerInfo}
          />
        );
    }
  };

  return (
    <>
      <div className="flex min-h-screen bg-white overflow-hidden">
        <div
          className=" h-screen bg-cover bg-center bd-white flex justify-center items-center w-1/2 flex-col  relative"
        >

          <img
            className="w-[85%] h-[85%]"
            src=" /images/bannerimg_new_MP.png" />
        </div>

        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className={`transform transition-transform duration-300 ease-in-out bg-white rounded-2xl cursor-pointer shadow-lg p-8 w-3/6 h-full overflow-y-auto no-scrollbar ${
              true ? "translate-x-0 animate-slideIn" : "translate-x-full"
            }`}
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {activeStep <= 2 && (
              <div
                className={
                  "flex justify-between items-center w-full max-w-3xl mx-auto p-6 bg-white"
                }
              >
                {["User Information", "Phone Number Verification"].map(
                  (label, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative w-full"
                    >
                      <div
                        className={`w-7 h-7 rounded-full border-2 flex items-center justify-center z-10 ${
                          activeStep >= index + 1
                            ? "bg-[#15415E] border-[#15415E] text-white"
                            : "bg-[#D4DFE3] border-[#D4DFE3] text-white"
                        }`}
                      >
                        <span
                          style={{ fontSize: "12px" }}
                          className="font-normal"
                        >
                          {index + 1}
                        </span>
                      </div>
                      {activeStep === index + 1 && (
                        <span className="text-xs -mb-4 uppercase text-[#15415E] font-semibold whitespace-nowrap">
                          {label}
                        </span>
                      )}
                      {index !== totalSteps - 1 && (
                        <div
                          className={`absolute top-1/2 transform -translate-y-1/2 h-1 ${
                            activeStep >= index + 2
                              ? "bg-[#15415E]"
                              : "bg-[#D4DFE3]"
                          }`}
                          style={{
                            width: "100%",
                            left: "50%",
                            right: "-50%",
                            zIndex: 1,
                          }}
                        ></div>
                      )}
                    </div>
                  ),
                )}
              </div>
            )}
            {renderActiveStep(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployerOnboardingIndex;
