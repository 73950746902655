const ExclamationSVG = ({ className = "", sizeSmall=false }: { className?: string , sizeSmall?:boolean}) => (
  <svg
    width={sizeSmall?"20":"49"}
    height={sizeSmall?"20":"49"}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.6953 48.6953C37.9501 48.6953 48.6953 37.9501 48.6953 24.6953C48.6953 11.4405 37.9501 0.695313 24.6953 0.695313C11.4405 0.695313 0.695312 11.4405 0.695312 24.6953C0.695312 37.9501 11.4405 48.6953 24.6953 48.6953Z"
      // fill="#ED3535"
      fill="currentColor"
    />
    <path
      d="M28.0371 12.5527C28.0371 13.2689 27.8008 15.6751 27.3281 19.7715C26.8555 23.8678 26.4401 27.8281 26.082 31.6523H24.4062C24.0911 27.8281 23.6973 23.8678 23.2246 19.7715C22.7663 15.6751 22.5371 13.2689 22.5371 12.5527C22.5371 11.7363 22.7949 11.0488 23.3105 10.4902C23.8262 9.93164 24.485 9.65234 25.2871 9.65234C26.0892 9.65234 26.748 9.93164 27.2637 10.4902C27.7793 11.0345 28.0371 11.722 28.0371 12.5527ZM28.2305 38.4629C28.2305 39.265 27.9368 39.9525 27.3496 40.5254C26.7767 41.084 26.0892 41.3633 25.2871 41.3633C24.485 41.3633 23.7904 41.084 23.2031 40.5254C22.6302 39.9525 22.3438 39.265 22.3438 38.4629C22.3438 37.6608 22.6302 36.9733 23.2031 36.4004C23.7904 35.8132 24.485 35.5195 25.2871 35.5195C26.0892 35.5195 26.7767 35.8132 27.3496 36.4004C27.9368 36.9733 28.2305 37.6608 28.2305 38.4629Z"
      fill="white"
    />
  </svg>
);

export default ExclamationSVG;
