import { cn } from "../../../util/cn";

const LoaderSVG = ({
  className = "",
  stroke = "#15415E",
  fill = "#15415E",
}: {
  className?: string;
  stroke?: string;
  fill?: string;
}) => (
  <div className={cn("flex items-center justify-center space-x-2", className)}>
    <svg
      className="animate-spin h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke={stroke}
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill={fill}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
      ></path>
    </svg>
  </div>
);
export default LoaderSVG;
