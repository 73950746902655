import React, { useEffect, useMemo, useState } from "react";

import AuthCode from "react-auth-code-input";

import { useForm } from "react-hook-form";
import { useAuth } from "../../../../providers/auth";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/custom-ui/toast-alert/ToastAlert";
import BuySubscription from "../Components/BuySubscription";
import EmployerTermsPage from "../Components/EmployerTermsPage";
import BillingPage from "../Components/BillingPage";
import PaymentPage from "../Components/PaymentPage";
import ElementsProvider from "../Components/ElementsProvider";
import PurchaseInformationPage from "../Components/PurchaseInformation";

function EmployerRegistrationStepFive({
  onDone,
  employerInfo,
}: {
  onDone: Function;
  employerInfo: any;
}) {
  const auth = useAuth();
  const [currentPlan, setCurrentPlan] = useState("");
  const [promoCodeData, setPromoCodeData] = useState(null);
  const [renderView, setRenderView] = useState();

  // to be removed
  
  // console.log(employerInfo, "========>employerInfo");

  // const [renderView, setRenderView] = useState(3);
  const handleContinue = (selection: string) => {
    setCurrentPlan(selection);
    setRenderView(1);
    // onDone(slection);
  };

  const handleTerms = (data: any) => {
    setRenderView(2);
    // onDone(slection);
  };

  const handleBilling = (data: any) => {
    setRenderView(3);
    // onDone(data);
  };

  const handlePurchaseInformation = (data: any) => {
    console.log(data, "============>data");

    setPromoCodeData(data);
    setRenderView(4);
  };

  const handlePayment = (data: any) => {
    console.log(data, "======>data inside handlePayment");

    // setRenderView(3)
    // onDone(data);
  };

  const renderSteps = (activeStep: any) => {
    switch (activeStep) {
      case 1:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <EmployerTermsPage
              onContinue={handleTerms}
              currentPlan={currentPlan}
              employerInfo={employerInfo}
            />
          </div>
        );
      case 2:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <BillingPage
              onContinue={handleBilling}
              currentPlan={currentPlan}
              employerInfo={employerInfo}
            />
          </div>
        );
      case 3:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <PurchaseInformationPage
              onContinue={handlePurchaseInformation}
              currentPlan={currentPlan}
              employerInfo={employerInfo}
            />
          </div>
        );
      case 4:
        return (
          <ElementsProvider
            currentPlan={currentPlan}
            priceId={"price_1QPpjfELIBAvshmEI5DonUnt"}
            employerInfo={employerInfo}
            promoCodeData={promoCodeData}
          />
        );
      default:
        return (
          <div className="w-full flex flex-row justify-start my-10">
            <BuySubscription onContinue={handleContinue} isSignup={true} />
          </div>
        );
    }
  };

  return <>{renderSteps(renderView)}</>;
}

export default EmployerRegistrationStepFive;
