import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/auth";
import { useNavigate } from "react-router-dom";
import EmployerRegistrationStepOne from "./EmployerRegistrationStepOne";
import EmployerRegistrationStepTwo from "./EmployerRegistrationStepTwo";
import EmployerRegistrationStepThree from "./EmployerRegistrationStepThree";
import EmployerRegistrationStepFour from "./EmployerRegistrationStepFour";
import EmployerRegistrationStepFive from "./EmployerRegistrationStepFive";

import ForgotPasswordComponent from "../../../seeker/details/New_UI/registration/ForgotPasswordComponent";
import EmployerLogin from "./EmployerLogin";
import EmployerLoginAuth from "./EmployerLoginAuth";
import EmployerMFA from "./EmployerMFA";
import { remove } from "lodash";
import SeekerLogin from "../../../seeker/details/New_UI/registration/SeekerLogin";
import { manageConfiguration } from "../../../../data/configuration";
import Meta from "../../../custom-ui/meta/Meta";

function EmployerRegistrationIndexNew(props: any) {
  const employerEmail = localStorage.getItem("employerEmail");
  const employerMFAReset = localStorage.getItem("mfaReset");
  const employerCompany = localStorage.getItem("employerCompany");
  const signUpEmployer = localStorage.getItem("signUpEmployer");
  // to be removed
  const employerInfoData = localStorage.getItem("employerInfo");

  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const totalSteps = 5;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [employerInfo, setEmployerInfo] = useState();
  const [employerCompanyInfo, setEmployerCompanyInfo] = useState();
  const [employerLogin, setEmployerLogin] = useState(false);
  const [employerUI, setEmployerUI] = useState(false);

  // to be removed
  // useEffect(() => {
  //   if (employerInfoData) setActiveStep(5);
  // }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await manageConfiguration();
        if (data[0]?.manage_new_ui_employer) {
          setEmployerUI(true);
        } else {
          setEmployerUI(false);
        }
      } finally {
        // Stop loading after data is fetched
      }
    }
    fetchData();
  })

  useEffect(() => {
    if (employerCompany) {
      setEmployerCompanyInfo(employerCompany);

    }
    if (employerEmail) {
      setEmployerInfo(JSON.parse(employerEmail));
      setActiveStep(3);
      setIsSignUp(true);
      setIsModalOpen(true);
    }
  }, [employerCompany, employerEmail]);

  useEffect(() => {
    if (props.isSignUp || signUpEmployer === "true") {
      setIsModalOpen(true);
      setIsSignUp(true);
    } else {
      setIsModalOpen(false);
      setIsSignUp(false);
    }
  }, [props.isSignUp, signUpEmployer]);

  useEffect(() => {
    if (employerMFAReset === "true") {
      setActiveStep(2);
      setEmployerInfo("MFAD");
      setIsSignUp(false);
      setIsModalOpen(true);
    }
  }, [employerMFAReset]);

  const handleForgotPasswordClick = () => {
    setIsModalOpen(true);
    setForgotPasswordMode(true);
  };

  const handleSignUpClick = () => {
    setIsModalOpen(true);
    setIsSignUp(true);
  };



  const handleSignInClick = (employer: boolean) => {
    // setIsModalOpen(true);
    // setIsSignUp(false);
    // if (employer) setEmployerLogin(true);
    if (employer) {
      localStorage.setItem("seekerLogin", "false");
    } else {
      localStorage.setItem("seekerLogin", "true");
    }
    navigate("/auth/signin");
  };

  const handleSeekerSignUpClick = () => {
    navigate("/auth/signin", { state: { activeSeekerRegistration: true } });
  }

  const closeModal = () => {
    localStorage.removeItem("employerEmail");
    localStorage.removeItem("mfaReset");
    localStorage.removeItem("signUpEmployer");
    setIsModalOpen(false);
    setIsSignUp(false);
    window.location.reload();
  };

  const nextStep = () => setActiveStep((prevState: number) => prevState + 1);

  const handleEmployerInfoDone = (data: any) => {
    setEmployerInfo(data);
    setIsSignUp(true);
    nextStep();
  };

  const handleEmployerDone = (data: any) => {
    // navigate("/home")
  };

  const handleEmployerComapnyInfoDone = (data: any) => {
    setEmployerCompanyInfo(data);
    nextStep();
  };

  const handleLink = (link: string | URL | undefined) => {
    window.open(link, "_blank");
  };

  const renderActiveStep = (activeStep: any) => {
    if (forgotPasswordMode) {
      return <ForgotPasswordComponent />;
    }
    if (isSignUp) {
      switch (activeStep) {
        case 1:
          return (
            <EmployerRegistrationStepOne
              onDone={handleEmployerComapnyInfoDone}
            />
          );
        case 2:
          return (
            <EmployerRegistrationStepTwo
              onDone={handleEmployerInfoDone}
              employerInfo={{ ...employerInfo, ...employerCompanyInfo }}
              employerCompany={employerCompanyInfo}
            />
          );
        case 3:
          return (
            <EmployerRegistrationStepThree
              onDone={handleEmployerInfoDone}
              employerInfo={employerInfo}
            />
          );
        case 4:
          return (
            <EmployerRegistrationStepFour
              onDone={handleEmployerInfoDone}
              employerInfo={employerInfo}
            />
          );
        case 5:
          return (
            <EmployerRegistrationStepFive
              onDone={handleEmployerDone}
              employerInfo={employerInfo}
            />
          );
        default:
          return <SeekerLogin onForgotPassword={handleForgotPasswordClick} />;
      }
    } else {
      switch (activeStep) {
        case 1:
          return (
            <SeekerLogin
              onSignUp={handleSignUpClick}
              onForgotPassword={handleForgotPasswordClick}
              onDone={handleEmployerInfoDone}
              employerLogin={employerLogin}
              handleSignInClick={handleSignInClick}
              employerInfo={employerInfo}
            />
          );
        case 2:
          return employerInfo === "MFAD" ? (
            <EmployerMFA
              onDone={handleEmployerInfoDone}
              employerInfo={employerInfo}
            />
          ) : (
            <EmployerLoginAuth
              onDone={handleEmployerInfoDone}
              mfaResolver={employerInfo}
            />
          );
        default:
          return <EmployerLogin onForgotPassword={handleForgotPasswordClick} />;
      }
    }
  };


  return (
    <>
      <Meta title="Instant Teams" />
      <div className="flex w-full min-h-screen bg-white overflow-hidden">
        <div className="grid w-full grid-cols-2 xs:grid-cols-1 md:grid-cols-1">
          <div className=" w-full h-full xs:hidden md:hidden flex justify-center items-center">
            <div
              className="w-[70%]  h-[70%] bg-cover bg-center  relative"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bannerimg_new_MP.png"
                  })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
            </div>
          </div>

          <div className=" flex flex-col w-full justify-center items-center  h-full">
            <div className="w-[60%] xs:w-full md:w-full flex flex-col justify-center items-center">
              <div className="absolute xs:top-0 md:top-0  top-1/4 flex flex-col  xs:justify-center xs:items-center md:justify-center md:items-center  p-10 w-[550px]">
                <img
                  src="/images/InstantTeamsLogo_Main_1.png"
                  alt="instant teams logo"
                  className="mb-6 w-60"
                />
                <div className="w-[80%]  lg:hidden xl:hidden 2xl:hidden">
                  <img
                    src="/images/bannerimg_new_MP.png"
                    alt="instant teams logo"
                    className="mb-6 w-[100%] h-[100%] mt-3 text-center xs:mb-3"
                  />
                </div>
                <h1 className="xs:text-base md:text-base xs:text-center md:text-center leading-tight mt-5 text-[55px] tracking-wide  font-extrabold text-[#15415E] mb-3 xs:mb-3">

                  Deploy Your<br />
                  Job Ad in Minutes
                </h1>
                <p className="text-lg mb-6 uppercase tracking-wider font-bold text-[#216478]">
                  Join Our Job Board and Reach Top Talent Today!
                </p>

                <div className="flex mt-5 xs:w-[80%] md:w-[80%] xs:justify-center  space-x-9 mb-4">
                  <button
                    className="px-6 py-3 text-lg font-semibold bg-white border border-[#15415E] rounded-full hover:bg-[#15415E] hover:text-[#ffffff] focus:outline-none w-[220px] h-[50px]"
                    onClick={() => {
                      handleSignInClick(true);
                    }}
                  >
                    Login
                  </button>
                  {employerUI &&
                    <button
                      className="px-6 py-3 text-lg font-semibold text-white bg-[#15415E] rounded-full hover:bg-[#15415E] focus:outline-none w-[220px] h-[50px]"
                      onClick={handleSignUpClick}
                    >
                      Sign Up
                    </button>}
                </div>
                <p className="text-sm xs:text-xs xs:flex-wrap xs:whitespace-nowrap mt-5 flex text-gray-500">
                  If you are looking for a remote job &nbsp;&nbsp;
                  <span
                    onClick={() => {
                      handleSignInClick(false);
                    }}
                    className="text-[#15415E] cursor-pointer ml-1 mr-2 font-bold underline xs:ml-0"
                  >
                    sign in here
                  </span>

                  <>or
                    &nbsp;
                    <span
                      onClick={() => {
                        handleSeekerSignUpClick()
                      }}
                      className="text-[#15415E] cursor-pointer ml-1 mr-2 font-bold underline xs:ml-0"
                    >
                      create an account.
                    </span></>
                </p>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={closeModal}
            ></div>

            <div
              className={`transform transition-transform duration-300 ease-in-out bg-white rounded-2xl shadow-lg p-8 w-3/6 h-full overflow-y-auto no-scrollbar ${isModalOpen
                ? "translate-x-0 animate-slideIn"
                : "translate-x-full"
                }`}
              style={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <div className="w-full flex justify-end">
                <div
                  onClick={closeModal}
                  className="px-[14px] py-2 bg-gray-300 rounded-full text-gray-600"
                >
                  X
                </div>
              </div>
              {activeStep < 5 && (
                <div
                  className={`flex justify-between items-center w-full max-w-3xl mx-auto p-6 bg-white ${isSignUp ? "" : " invisible"
                    }`}
                >
                  {[
                    "Company Information",
                    "Point Of Contact",
                    "Email Verification",
                    "Phone Number Verification",
                    "Subscription",
                  ].map((label, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative w-full"
                    >
                      <div
                        className={`w-7 h-7 rounded-full border-2 flex items-center justify-center z-10 ${activeStep >= index + 1
                          ? "bg-[#15415E] border-[#15415E] text-white"
                          : "bg-[#D4DFE3] border-[#D4DFE3] text-white"
                          }`}
                      >
                        <span
                          style={{ fontSize: "12px" }}
                          className="font-normal"
                        >
                          {index + 1}
                        </span>
                      </div>
                      {activeStep === index + 1 && (
                        <span className="absolute text-xs mt-8 uppercase text-[#15415E] font-semibold whitespace-nowrap">
                          {label}
                        </span>
                      )}
                      {index !== totalSteps - 1 && (
                        <div
                          className={`absolute top-1/2 transform -translate-y-1/2 h-1 ${activeStep >= index + 2
                            ? "bg-[#15415E]"
                            : "bg-[#D4DFE3]"
                            }`}
                          style={{
                            width: "100%",
                            left: "50%",
                            right: "-50%",
                            zIndex: 1,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {renderActiveStep(activeStep)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default EmployerRegistrationIndexNew;
