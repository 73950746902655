import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from "../../../../../providers/auth";
import { addUserAffiliationNew, addOrUpdateUserAffiliation } from "../../../../../data/affiliation";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../../data/dictionary/collectionNames";
import { createResume } from "../../../../../data/seeker";
import { useLookup } from "../../../../../data/lookup";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/custom-ui/toast-alert/ToastAlert";
import { seekerRoute } from '../../../../../react-router_routes/seeker/seekerRoutes';

function SeekerRegistrationStepFour({
  onDone,
  seekerInfo,
}: {
  onDone: Function;
  seekerInfo: any;
}) {
  //const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const { user } = auth;
  const { data: branchList = [] } = useLookup("branch");
  const { data: affiliationList = [] } = useLookup("affiliation");
  const { data: stateList = [] } = useLookup("state");
  // State to track whether user is military connected
  const [pending, setPending] = useState(false);
  const [branch, setbranch] = useState('No');
  const [selectedBranch, setSelectedBranch] = useState('No');
  const [selectedAffiliation, setSelectedAffiliation] = useState('');
  const [loading, setLoading] = useState(false);
  const affiliationSchema = z.object({
    stateResidence: z
      .string({ required_error: "State of residence is required" }).optional(),
  });

  type AffiliationSchema = z.infer<typeof affiliationSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    control,
  } = useForm<AffiliationSchema>({
  });

  const onSubmit = async (data: AffiliationSchema) => {
    setLoading(true)
    localStorage.setItem("username", seekerInfo.name)
    await addUserAffiliationNew(
      user.id,
      selectedBranch,
      selectedAffiliation,
      seekerInfo.email,
      seekerInfo.name,
      seekerInfo.phone,
      seekerInfo.preferredName,
      seekerInfo?.state,
      {
        stateResidence: seekerInfo?.state,
        zipCode: seekerInfo?.state !== "Outside-USA" ? seekerInfo?.zipCode : null,
        country: seekerInfo?.state === "Outside-USA" ? seekerInfo?.country : null,
        city: seekerInfo?.city,
      }
    )
      .then((docRef) => {
        createResume({ id: user.id, stateResidence:seekerInfo?.state })
          .then(() => {
            console.log("done!");
            setLoading(false)
            onDone(seekerInfo)
            setPending(false)
            toast.success(`Military Affiliation submit`);
          })
          .catch((error: any) => {
            console.error(error);
            toast.error(error);
            setPending(false)
          });
      })
      .catch((error) => {
        console.error(error);
        setPending(false)
      });
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      {/* Progress Bar */}

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

        <div className="mb-6 pb-4 border-b border-gray-300">
          <label className="block text-lg font-medium text-[#15415E] mb-2">
            Are you military affiliation?
          </label>
          <div className="flex items-center space-x-8">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Yes"

                checked={branch === 'Yes'}
                onClick={() => setbranch('Yes')}
                className="form-radio h-5 w-5 text-[#15415E]"
              />
              <span className="ml-2 text-[#15415E] text-sm">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="No"

                checked={branch === 'No'}
                onClick={() => setbranch('No')}
                className="form-radio h-5 w-5 text-[#15415E]"
              />
              <span className="ml-2 text-[#15415E] text-sm">No</span>
            </label>
          </div>

        </div>

        {branch === 'Yes' && (
          <>
            {/* Military Branch */}
            <div className="mb-6 pb-4 border-b border-gray-300">
              <label className="block text-lg font-medium text-[#15415E] mb-2">
                What is your Military Branch?
              </label>
              <div className="grid grid-cols-2 gap-4">
                {branchList.map((branch: any) => (
                  <label key={branch.id} className="inline-flex items-center">
                    <input

                      type="radio"
                      value={branch.code}
                      checked={selectedBranch === branch.code}
                      onChange={() => setSelectedBranch(branch.code)}
                      name="branch"
                      className="form-radio h-5 w-5 text-[#15415E]"
                    />
                    <span className="ml-2 text-[#15415E] text-sm">{branch.label}</span>
                  </label>
                ))}
              </div>

            </div>

            {/* Military Affiliation */}
            <div className="mb-6 pb-4 border-b border-gray-300">
              <label className="block text-lg font-medium text-[#15415E] mb-2">
                What is your Military Affiliation?
              </label>
              <div className="grid grid-cols-2 gap-4">
                {affiliationList.map((affiliation: any) => (
                  <label key={affiliation.id} className="inline-flex items-center">
                    <input

                      type="radio"
                      value={affiliation.code}
                      name="affiliation"
                      checked={selectedAffiliation === affiliation.code}
                      onChange={() => setSelectedAffiliation(affiliation.code)}
                      className="form-radio h-5 w-5 text-[#15415E]"
                    />
                    <span className="ml-2 text-[#15415E] text-sm">{affiliation.label}</span>
                  </label>
                ))}
              </div>

            </div>
          </>
        )}

        {/* State of Residence */}
        {/* <div className="mb-6 border-b border-gray-300">
          <label className="block text-lg  font-sm text-[#15415E] ">
            Which state do you currently reside in?  <span className="text-red-600">*</span>
          </label>

          <select
            className={`py-2 px-3 font-bold w-full leading-8 font-medium borrder-none focus:ring-1 ${errors.stateResidence ? "border-red-600" : ""
              }`}
            style={{ color: '#15415E', boxShadow: 'none' }}

            name="stateResidence"
            ref={register({
              required: "Please select your state of residence.",
            })}
          >
            <option value="" selected disabled>
              Select a State
            </option>
            {stateList.map((state: any) => (
              <option key={state.id} value={state.code}>
                {state.label}
              </option>
            ))}
          </select>
          {errors.stateResidence && (
            <p className="m-2 text-sm text-left text-red-600">
              {errors.stateResidence.message}
            </p>
          )}
        </div> */}

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="py-2 px-8 text-white font-medium rounded-lg focus:outline-none focus:ring-2"
            style={{ backgroundColor: '#15415E' }}
            disabled={pending}
          >
            {pending ? (
              <div className="flex items-center justify-center space-x-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>

              </div>
            ) : (
                <>{
                  loading?"...": "Continue"
                }
                </>
            )}
          </button>
        </div>
      </form>
      <ToastAlert />
    </div>
  );
}

export default SeekerRegistrationStepFour;
