import React, { useState, useEffect } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { useAuth } from "../../../providers/auth";

const AutoSavePlainText = ({
  value: initialValue,
  rows,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  readOnly,
  maxLength = 500,
}) => {
  const auth = useAuth();
  const [value, setValue] = useState(initialValue ?? "");
  const [check, setCheck] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [saved, setSaved] = useState(false);
  const [length, setLength] = useState(0);

  useEffect(() => {
    setValue(initialValue);
    setLength(initialValue?.length ?? 0);
  }, [initialValue]);

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saved]);

  const handleBlur = async () => {
    if (!value && required) {
      setIsValid(false);
      return;
    }
    if (value !== check) {
      setIsValid(true);
      setCheck(value);
      await updateAutoSave(collection, recId, { [name]: value });
      await logActivity(
        collection,
        recId,
        name,
        check,
        value,
        auth.user.uid,
        check === "" ? "Added" : "Edited"
      );
      setSaved(true);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (maxLength === -1 || newValue.length <= maxLength) {
      setValue(newValue);
      setLength(newValue.length);
    }
  };

  return (
    <div id={name} className="relative mb-4">
      <div className="text-instant-teams-teal-Main font-bold pl-1">
        {display} {readOnly ? " - LOCKED" : null}
      </div>
      <textarea
        readOnly={readOnly}
        className="shadow-md rounded-md bg-white p-3 w-full border border-gray-300"
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {saved && <span className="text-xs text-green-600">{display} saved!</span>}
      {maxLength !== -1 && (
        <div className="text-right text-gray-500 text-sm font-bold mt-1">
          {length} of {maxLength} characters
        </div>
      )}
      {!isValid && <div className="text-red-500 text-xs">This field is required.</div>}
    </div>
  );
};

export default AutoSavePlainText;
