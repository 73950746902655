import { useState, useMemo } from "react";
import PlansPage from "./PlansPage";
import ElementsProvider from "./ElementsProvider";
import MonthlySubscriptionPreText from "./MonthlySubscriptionPreText";
import PurchaseInformationPage from "../../../../New_UI/Components/PurchaseInformation";

type BuyCreditsProps = {
  currentSubscription?: string;
  employerId: string;
  onDone: () => void;
};

const BuyCredits = ({
  currentSubscription = "",
  employerId = "",
  onDone,
}: BuyCreditsProps) => {
  const [selectedSubscription, setSelectedSubcription] = useState("");
  const [renderView, setRenderView] = useState();
  const [promoCodeData, setPromoCodeData] = useState(null);

  const handleContinue = (subscriptionText: string) => {
    setSelectedSubcription(subscriptionText);

    if (subscriptionText === "single") {
      setRenderView(1);
    } else if (subscriptionText === "monthly") {
      setRenderView(1);
    } else if (subscriptionText === "null") {
      onDone();
    }
  };

  const handleShowPayment = () => {
    setRenderView(2);
  };

  const handlePurchaseInformation = (data: any) => {
    setPromoCodeData(data);
    setRenderView(2);
  };

  const renderSteps = (activeStep: any) => {
    switch (activeStep) {
      case 1:
        return (
          <div className="w-full">
            <PurchaseInformationPage
              onContinue={handlePurchaseInformation}
              currentPlan={selectedSubscription}
              employerInfo={{
                employerId: employerId,
              }}
              hasQuantity={selectedSubscription === "single"}
            />
          </div>
        );
      case 2:
        return (
          <div className="w-full">
            <ElementsProvider
              selectedSubscription={selectedSubscription}
              employerId={employerId}
              promoCodeData={promoCodeData}
              hasQuantity={selectedSubscription === "single"}
            />
          </div>
        );
      case 3:
        return (
          <div className="w-full">
            <MonthlySubscriptionPreText onContinue={handleShowPayment} />
          </div>
        );
      default:
        return (
          <div className="w-full flex flex-row justify-center">
            <PlansPage
              currentSubscription={currentSubscription}
              onContinue={handleContinue}
            />
          </div>
        );
    }
  };

  return <>{renderSteps(renderView)}</>;
};

export default BuyCredits;
