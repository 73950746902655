import { useRef, useMemo } from 'react';
import { useListingJobByDocId } from '../../data/listings';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatString, getMappedDataWithLookupCode } from '../seeker/details/New_UI/Components/util';
import { useParams } from 'react-router-dom';
import { formatRateRange } from '../seeker/details/New_UI/Components/util';
import { sanitizeMarkup } from '../../util/sanitization/sanitize-html';
import { useLookup } from '../../data/lookup';
import SliderWithCheckbox from '../custom-ui/old-ui/SliderWithCheckbox';
import { useShare } from '../../data/share';
import Meta from '../custom-ui/meta/Meta';
import { Helmet } from 'react-helmet'
import CustomMetaForSocialShare from '../custom-ui/meta/Cus_Meta';
// import { FaApple, FaGooglePlay } from 'react-icons/fa';

const JobListingShare = ({ id, share }) => {
    const { shareId } = useParams()
    const { data } = useShare(shareId);
    const commissionRef = useRef(null);
    const hostKey = process.env.REACT_APP_JOBS_URL;
    // const { data } = useListingJobByDocId(id)
    const [jobsData, setJobsData] = useState()
    const date = new Date(jobsData?.data?.employer?.startDate?.seconds * 1000);
    const options = { month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(' ', ' ');


    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    // const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    // const { data: mustHaves = [] } = useLookup("mustHaves");

    const aspectsOfJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aspectsOfJob), [aspectsOfJob])
    // const aboutThisJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aboutThisJob), [aboutThisJob])
    // const mustHavesMappedWithCode = useMemo(() => getMappedDataWithLookupCode(mustHaves), [mustHaves])

    useEffect(() => {
        setJobsData(data)
    }, [data])

    const scrollToCommission = () => {
        commissionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleClick = () => {
        window.open(`https://${hostKey}`, '_blank', 'noopener,noreferrer');
    }
    function formatCompensation(data) {
        if (data?.salary) {
            const minSalary = data.salary.min.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            const maxSalary = data.salary.max.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return `${minSalary} - ${maxSalary} Yearly`;
        }
        if (data?.hourly) {
            const minHourly = data.hourly.min.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            const maxHourly = data.hourly.max.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return `${minHourly} - ${maxHourly} Hourly`;
        }
        if (data?.commission) {
            const commission = data.commission.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
            return ` Commission ${commission}`;
        }
        return "";
    }
    return (
        <>

            <CustomMetaForSocialShare title={jobsData?.data?.job?.title} brandImage={jobsData?.data?.employer?.logo} shareUrl={""} />
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
                className={`h-60  w-full z-50 py-5 px-5 md:px-36 lg:px-36 xl:px-36 2xl:px-36 3xl:px-36 bg-cover`}>
                <div className='ml-0 md:ml-[-100px] lg:ml-[-100px] xl:ml-[-100px] 2xl:ml-[-100px] 3xl:ml-[-100px] w-48'>
                    <img src="/images/InstantTeamsLogo_Main_1.png" alt="Instant Teams Logo" />
                </div>
                <div className=" z-50 mx-auto mt-8 p-6 border border-gray-300  overflow-y-auto rounded-lg shadow-md bg-white">
                    {/* Header Section */}
                    <div className="flex justify-between items-start flex-wrap xs:justify-center xs:gap-y-[10px]">
                        <div className="flex items-center">
                            <img
                                src={jobsData?.data?.employer?.logo}
                                alt="Company Logo"
                                className="w-16 h-16 rounded object-contain"
                            />
                            <div className="ml-4">
                                <h2 className="text-xl text-instant-teams-blue-Main font-bold">{jobsData?.data?.job?.title}</h2>
                                <p className="text-gray-500 text-sm ">{jobsData?.data?.employer?.displayName}</p>
                            </div>
                        </div>
                        <button onClick={handleClick} className="xs:w-full bg-instant-teams-blue-Main text-white py-2 px-8 rounded-full">
                            Join Now To Apply
                        </button>
                    </div>

                    {/* Job Details */}
                    <div className="flex mt-4 gap-x-4 gap-y-4 flex-wrap">
                    <div className='flex space-x-3 '>
                            <div className='grid grid-cols-2 gap-3'>
                                <div className="xs:w-full bg-[#CCD6FF] text-center p-2 px-6 items-center rounded-md ">
                                    <p className="text-sm text-gray-500 items-center">Work Type</p>
                                    <p className="font-bold items-center text-gray-700 ">{formatString(jobsData?.data?.employer?.workType)} </p>
                                    {jobsData?.data?.employer?.workType === "part_time" && <p className=' text-instant-teams-blue-Main text-xs text-center'>{`${jobsData?.data?.employer?.hoursPerWeek ? jobsData?.data?.employer?.hoursPerWeek?.min + " - " : ""}${jobsData?.data?.employer?.hoursPerWeek ? jobsData?.data?.employer?.hoursPerWeek?.max + "hours/week" : ""} `}</p>}

                                </div>
                                <div className="xs:w-full bg-[#CCD6FF] text-center items-center p-2 px-6 rounded-md ">
                                    <p className="text-sm text-gray-500 items-center ">Work Setting</p>
                                    <p className="font-bold  text-gray-700">{formatString(jobsData?.data?.employer?.workSettings)} </p>
                                    { jobsData?.data?.employer?.workSettings ==="hybrid" && jobsData?.data?.employer?.hybridJobLocation && <p className=' text-instant-teams-blue-Main text-xs text-center'>{jobsData?.data?.employer?.hybridJobLocation || ""}</p>}
                                </div>
                            </div>
                            {(jobsData?.data?.employer?.startDateInclude && jobsData?.data?.employer?.startDate) && <div className="bg-[#026AAF33] xs:w-full md:w-full py-2 px-6 rounded-lg ">
                                <p className="text-instant-teams-blue-Main text-sm  text-center">Start Date</p>
                                <p className="font-semibold text-instant-teams-blue-Main text-center">{formattedDate}</p>
                            </div>}

                            {jobsData?.data?.employer?.payRate?.salary && (
                                <div style={{ backgroundColor: "#3E4C9533" }} className={`${jobsData?.data?.employer?.payRate?.commission ? "cursor-pointer" : ""} bg-[#3E4C9533] py-2 px-6  rounded-lg xs:w-full`}>
                                    <p className="text-instant-teams-blue-Main text-center text-sm">{jobsData?.data?.employer?.payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                    <p className="text-center font-semibold text-instant-teams-blue-Main ">{formatRateRange("salary", jobsData?.data?.employer?.payRate?.salary)}</p>
                                </div>
                            )}
                            {jobsData?.data?.employer?.payRate?.hourly && (
                                <div style={{ backgroundColor: "#3E4C9533" }} className="xs:w-full bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                    <p className="text-instant-teams-blue-Main text-center text-sm">{jobsData?.data?.employer?.payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                    <p className="text-center font-semibold text-instant-teams-blue-Main">{formatRateRange("hoursPerWeek", jobsData?.data?.employer?.payRate?.hourly)}</p>
                                </div>
                            )}
                            {jobsData?.data?.employer?.payRate?.commission && !jobsData?.data?.employer?.payRate?.salary && !jobsData?.data?.employer?.payRate?.hourly && (
                                <div onClick={scrollToCommission} style={{ backgroundColor: "#3E4C9533" }} className="xs:w-full bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                    <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                    <p className="text-center font-semibold text-instant-teams-blue-Main">Commission Based</p>
                                </div>
                            )}

                            {jobsData?.data?.employer?.payRate?.type == "unpaid_internship" && (
                                <div style={{ backgroundColor: "#3E4C9533" }} className="xs:w-full bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                    <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                    <p className="text-center font-semibold text-instant-teams-blue-Main">Unpaid Internship</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Mobile App Section */}
                    <div style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }} className="mt-4  p-4 rounded-lg flex items-center justify-between flex-wrap">
                        <div>
                            <h3 className="text-xl text-gray-700 font-bold">
                                Get Connected On the Go!
                            </h3>
                            <p className="text-gray-500 text-sm">
                                Download our mobile app today and unlock opportunities right at your fingertips!
                            </p>
                        </div>
                        <div className="flex gap-2">
                            <div className="text-center pt-4">
                                <a
                                    className="h-12 inline-block"
                                    href={`https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?itsct=apps_box_badge&amp;itscg=30200`}
                                    target="_blank"
                                >
                                    <img
                                        className="w-full h-11"
                                        src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685577600`}
                                        alt="Download on the App Store"
                                    />
                                </a>
                            </div>
                            <div className="text-center">
                                <a
                                    className="h-16 mt-1 inline-block"
                                    href="https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&utm_source=build.instantteams.com&utm_campaign=Build&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                    target="_blank"
                                >
                                    <img
                                        className=" h-16"
                                        alt="Get it on Google Play"
                                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Job Description Section */}
                    {
                        jobsData?.data?.job?.description?.whatsInItForYou === null && jobsData?.data?.job?.description?.dayInLife && <div className="mt-4">
                            <p className="xs:text-center font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                Job Summary
                            </p>
                            <div className="mt-[5px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">

                                {jobsData?.data?.job?.description?.dayInLife}
                            </div>
                        </div>
                    }


                    {
                        jobsData?.data?.job?.description?.whatsInItForYou === null
                            ? (
                                <div className="flex flex-col mt-[30px]">
                                    <p className="xs:text-center font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                        Why Work Here
                                    </p>
                                    {
                                        jobsData?.data?.job?.description?.aspectsOfJob?.length > 0 && (
                                            <div className="mt-[20px] flex flex-row gap-[20px] flex-wrap xs:justify-center">
                                                {
                                                    jobsData?.data?.job?.description?.aspectsOfJob.map(aspectsOfJobCode => {
                                                        return (
                                                            <div key={aspectsOfJobMappedWithCode[aspectsOfJobCode]?.id} className="xs:w-full xs:text-center bg-[#F4F7F8] py-[16px] px-[24px] rounded-[70px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#373737]">
                                                                {aspectsOfJobMappedWithCode[aspectsOfJobCode]?.label}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }

                                    {
                                        jobsData?.data?.job?.description?.aspectsOfJobOther && (
                                            <div className="mt-[20px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[23px] text-[#373737]">
                                                {jobsData?.data?.job?.description?.aspectsOfJobOther}
                                            </div>
                                        )
                                    }
                                </div>
                            ) : (
                                <>
                                    {jobsData?.data?.job?.description?.whatsInItForYou && <div className="mt-[30px]">
                                        <h3 className="text-instant-teams-teal-Main mb-2">What's in it for you?</h3>
                                        <p className="text-gray-500 text-sm">
                                            <div
                                                className="dangerously"
                                                dangerouslySetInnerHTML={sanitizeMarkup(
                                                    jobsData?.data?.job?.description?.whatsInItForYou
                                                )}
                                            ></div>
                                        </p>
                                    </div>}
                                </>
                            )
                    }


                    {
                        // jobsData?.data?.job?.description?.dayInLife === null
                        jobsData?.data?.job?.description?.aboutThisJob && jobsData?.data?.job?.description?.aboutThisJob?.length > 0
                            ? (
                                <div className="flex flex-col mt-[30px]">
                                    <p className="xs:text-center font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                        Instant Job Insights
                                    </p>
                                    <div className='w-2/3 pl-[10px] xs:w-full'>
                                        {jobsData?.data?.job?.description?.aboutThisJob.map((option) => {
                                            return (
                                                <SliderWithCheckbox
                                                    key={option.code}
                                                    option={option}
                                                    value={option.value}
                                                    isChecked={true}
                                                    onSelectionChange={() => { }}
                                                    isViewOnly={true}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {jobsData?.data?.job?.description?.dayInLife && <div className="mt-4">
                                        <h3 className="text-instant-teams-teal-Main mb-2">A Day in the Life</h3> 
                                        <p className="text-gray-500 text-sm">
                                            <div
                                                className="dangerously text-sm"
                                                dangerouslySetInnerHTML={sanitizeMarkup(
                                                    jobsData?.data?.job?.description?.dayInLife
                                                )}
                                            ></div>

                                        </p>
                                    </div>}
                                </>
                            )
                    }


                    {
                        jobsData?.data?.job?.description?.mustHaves === null
                            ? (
                                <div className="flex flex-col mt-[30px]">
                                    <p className="xs:text-center font-[Gilroy-SemiBold] font-[400] text-[20px] leading-[24.5px] text-[#15415E]">
                                        Must Haves for Success
                                    </p>

                                    <div className='mt-[20px] flex flex-row gap-[20px] flex-wrap justify-start xs:justify-center'>
                                        {
                                            jobsData?.data?.job?.description?.mustHavesCheckboxes?.map(mustHavesCode => {
                                                return (
                                                    <div key={mustHavesCode}>
                                                        <img
                                                            src={`/images/must-haves-images/${mustHavesCode}.png`}
                                                            alt="Image"
                                                            className="w-[200px] h-auto"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {jobsData?.data?.job?.description?.mustHaves && <div className="mt-4">
                                        <h3 className="text-instant-teams-teal-Main mb-2">Must Haves/What You Bring to the Table</h3>
                                        <div className="text-gray-500 text-sm">
                                            <div
                                                className="dangerously"
                                                dangerouslySetInnerHTML={sanitizeMarkup(
                                                    jobsData?.data?.job?.description?.mustHaves
                                                )}
                                            ></div>

                                        </div>
                                    </div>}
                                </>
                            )
                    }

                    {
                        jobsData?.data?.employer?.payRate?.commission && <>
                            <div ref={commissionRef} className='mt-5'>
                                <h1 className='text-instant-teams-teal-Main'>
                                    {jobsData?.data?.employer?.payRate?.additionalBenifits === "empComm" ? "Commission" : "Bonus"}
                                </h1>
                            </div>
                            <div className="mt-2 text-gray-600 text-sm">
                                <div
                                    className="dangerously text-sm"
                                    dangerouslySetInnerHTML={sanitizeMarkup(
                                        jobsData?.data?.employer?.payRate?.commission
                                    )}
                                ></div>

                            </div>
                        </>
                    }


                </div >

                <div className="min-h-[50px]">&nbsp;</div>
            </div >
        </>
    );
};

export default JobListingShare;