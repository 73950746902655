import React from 'react';
import AccountOwnerInforCard from '../Component/AccountOwnerInfo/AccountOwnerInforCard';
import { useParams } from 'react-router-dom';
import { useEmployer, useEmployerPrimary } from '../../../../../data/employer';
import { useEffect } from 'react';
import { getUser } from '../../../../../data/user';
import { useState } from 'react';
 

const AccountOwnerInfo = () => {
    const params = useParams();
    const { data: employerData } = useEmployer(params?.employerId || "")
    const { data: employerUsers } = useEmployerPrimary(params?.employerId || "")
    const [accountOwner, setAccountOwner] = useState("");

    useEffect(() => {
      if (
        !employerData.accounterOwnerDetails?.email &&
        employerData.createdBy
      ) {
        getUser(employerData.createdBy).then((userDataObj) => {
          if (userDataObj?.email) {
            setAccountOwner(userDataObj?.email);
          } else {
            setAccountOwner(employerData.createdBy);
          }
        });
      } else if (
        !employerData.accounterOwnerDetails?.email &&
        !employerData.createdBy &&
        employerUsers &&
        employerUsers[0]?.email
      ) {
        setAccountOwner(employerUsers[0]?.email);
      } else {
        setAccountOwner(employerData.accounterOwnerDetails?.email);
      }
    }, [
      employerData.accounterOwnerDetails?.email,
      employerData.createdBy,
      employerUsers,
      params.employerId,
    ]);
    
    const companyHeadquaters = {
        city:  employerData?.headquaterCity||" ",
        state: employerData?.headquaterState||" "
    }
    const accountOwnerDetails = {
        email: employerData?.accounterOwnerDetails?.email ? employerData?.accounterOwnerDetails?.email: accountOwner,
        name: employerData?.accounterOwnerDetails?.name ?? "",
        phone: employerData?.accounterOwnerDetails?.phone ?? "",
        title: employerData?.accounterOwnerDetails?.title ?? "No Data",
    };

    const billingContact = {
        email: employerData?.billingContact?.email ?? "",
        name: employerData?.billingContact?.name ?? "",
        phone: employerData?.billingContact?.phone ?? "",
        title: employerData?.billingContact?.title ?? "No Data",
        address: employerData?.billingContact?.address ? employerData?.billingContact?.address + ", "+
        employerData?.billingContact?.city+", "+employerData?.billingContact?.state +", "+employerData?.billingContact?.zipCode ||"":"No Data",
    }

    return (
        <>
            <div className='grid grid-cols-2 pr-4  gap-4'>
                <AccountOwnerInforCard cardTitle='Account Owner' cardData={accountOwnerDetails} />
              {  employerData?.billingContact&& <AccountOwnerInforCard cardTitle='Billing Contact' cardData={billingContact} />}
                <AccountOwnerInforCard cardTitle='Company Headquaters' cardData={companyHeadquaters}/>
            </div>
           
            
        </>
    );
}

export default AccountOwnerInfo;
