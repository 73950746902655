import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; import { useAuth } from '../../../../../../providers/auth';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfilePopup from '../../../../../seeker/details/New_UI/Header/Profile';
import NotificationList_NewUI from '../../../../../notifications/NotificationList_NewUI';


const EmpHeader = () => {

    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const auth = useAuth();

    return (
        <div style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
            className={` fixed w-full py-4 z-50 p-1 bg-cover`}>

            {!currentPath.startsWith("/social") && (<><div className='w-full  flex justify-between items-center'>
                <div onClick={() => { navigate("/jobs") }} className='ml-8 cursor-pointer w-48'>
                    <img src="/images/InstantTeamsLogo_Main_1.png" alt="Instant Teams Logo" />
                </div>

                <div className='flex items-center  mr-8'>
                    <div onClick={() => navigate("/faq")} className='px-4'>
                        <span className="text-lg font-bold cursor-pointer text-[#1E596D] border-[#1E596D] flex h-6 w-6 items-center justify-center rounded-full border-2 bg-white shadow-lg">
                            i
                        </span>
                    </div>
                    {/* <div className='px-3'>

                        <NotificationList_NewUI userId={auth?.user?.id} />
                    </div> */}
                    <div onClick={() => navigate(`/messages/${auth.user.id}`)} className='px-3'>
                        <i className='far fa-comment-alt text-[22px] cursor-pointer text-[#1E596D]'></i>
                    </div>
                    <div className='px-3 cursor-pointer'>
                        <ProfilePopup />
                    </div>
                    {/* () => navigate("/settings/newprofile" */}
                </div>
            </div>

            </>)}
        </div>
    );
};

export default EmpHeader;
