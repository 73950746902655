import EmployerDropzone from "./employer-dropzone";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import InfoIcon from "../../New_UI/registration/InfoIcon";
function EmployerLogo(props) {
  return (
    <>
      <DialogWrapper title="Add a logo" onClose={props.toggleModal}>
        <div className="flex justify-between items-center">
          <p className="my-6 text-slate-600  leading-relaxed text-sm flex justify-center">
            Tap the image to open file explorer or Drop image below
          </p>
          <InfoIcon tooltipText="Image size should not exceed more than 8MB" viewDown={true} />
        </div>


        <EmployerDropzone closeModal={props.toggleModal}  />
      </DialogWrapper>
    </>
  );
}

export default EmployerLogo;
