import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { jobFormSchemaStep2 } from "./JobFormSchemaStep2";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import { useJobDetailsNewCollection } from "../../../../../../data/jobDetailsNewCollection";
import InfoIcon from "../../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";

function AddJobStep2({ currStep, setCurrentStep, setFormvalues, formvalues }: any) {
    const params = useParams();
    const jobId = params.id ?? "vUN2qyluYqwzeaEmduqU";
    const navigate = useNavigate();
    const [isInitialized, setIsInitialized] = useState(false);
    const [includeDate, setIncludeDate] = useState(false);
    const [error, setError] = useState()
    const [formSchema] = useState(jobFormSchemaStep2());
    const { data: job } = useJobDetailsNewCollection(jobId);
    const isFieldLocked = isLockedJobNew(job?.jobState);
    const [includeATS, setIncludeATS] = useState(true);

    type FormValues = z.infer<ReturnType<typeof jobFormSchemaStep2>>;
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startDate: formvalues?.startDate || "",
            applicationFlow: formvalues?.applicationFlow || "",
            noATS: "noATS_true",
            noATSContactEmail: "",
        },
        reValidateMode: "onBlur",
    });


    const {
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;


    const noATS_watch = watch("noATS");


    const onSubmit = async ({ ...values }: FormValues) => {
        setFormvalues({
            ...formvalues,
            startDate: includeDate ? values?.startDate || null : null,
            applicationFlow: values?.applicationFlow,
            startDateInclude: includeDate,
            noATS: values?.noATS === "noATS_true" ? false : true,
            noATSContactEmail: values?.noATS === "noATS_false" ? values?.noATSContactEmail : "",

        });
        if (includeDate) {
            if (values?.startDate) {
                setCurrentStep(currStep + 1);
            }
            else {
                setError("Start Date is Required")
            }
        } else {
            setCurrentStep(currStep + 1);
        }
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;
        setValue("applicationFlow", formvalues?.applicationFlow ?? "");
        setValue("startDate", formvalues?.startDate?.toDate?.() ?? undefined);
    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);


    console.log(includeATS)

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center">
                    <div className="w-[50vw]">

                        <div className="flex ">
                            <h1 className="text-instant-teams-blue-Main text-lg flex mt-2">
                                Do you have an ATS System (Application Tracking System) <span className="text-red-500 text-base ml-1">*</span>
                            </h1>
                            <div className="flex items-end mt-2">
                                <InfoIcon
                                    tooltipText={"An ATS (Application Tracking System) is a platform where applicants can complete your full job application. If your company uses an ATS, please provide the link so we can direct applicants to it. If you don't have an ATS, you can provide an email address where you'd like to receive job applications instead."}
                                />
                            </div>
                        </div>

                        {/* Radio Buttons for Include Date and Not Include Date */}
                        <div className="mt-4 my-3 mb-5 w-3/4 ">
                            <Controller
                                control={control}
                                name="noATS"
                                render={(field) => (
                                    <RadioGroup_Emp
                                        {...field}
                                        options={[
                                            {
                                                "id": "noATS_true",
                                                "icon": null,
                                                "isActive": true,
                                                "label": "Yes",
                                                "code": "noATS_true",
                                            },
                                            {
                                                "id": "noATS_false",
                                                "isActive": true,
                                                "label": "No",
                                                "icon": null,
                                                "code": "noATS_false"
                                            }
                                        ]}
                                        disabled={isFieldLocked}
                                        error={errors?.noATS?.message}
                                        required
                                    />
                                )}
                            />
                         
                        </div>



                        {noATS_watch === "noATS_false" && (
                            <div>
                                {noATS_watch === "noATS_false" && <div className="flex mt-2 justify-center">
                                    <Controller
                                        control={control}
                                        name="noATSContactEmail"
                                        render={(field) => (
                                            <FloatingLabelInput
                                                {...field}
                                                display="Please Enter Your Email Address for Receiving Application for This Job"
                                                placeholder="Enter email id"
                                                readOnly={isFieldLocked}
                                                error={errors?.noATSContactEmail?.message}
                                                required
                                            />
                                        )}
                                    />
                                </div>}
                            </div>
                        )}

                        {noATS_watch === "noATS_true" && <div className="flex items-center">

                            <Controller
                                control={control}
                                name="applicationFlow"
                                render={(field) => (
                                    <FloatingLabelInput
                                        {...field}
                                        display="Apply Link"
                                        placeholder="Enter Application Flow"
                                        readOnly={isFieldLocked}
                                        error={errors?.applicationFlow?.message}
                                        showInfo={true}
                                        showInfoText={`This link will direct candidates from Instant Teams to  complete your full application in your company's Applicant Tracking System(ATS)`}
                                        required
                                    />
                                )}
                            />
                        </div>}



                        <div className="flex mt-6 w-auto justify-start items-center">
                            <h1 className="text-instant-teams-blue-Main text-lg flex mt-2">
                                Start Date <span className="text-red-500 text-base ml-1">*</span>
                            </h1>
                            <div className="flex items-end mt-2">
                                <InfoIcon
                                    tooltipText={"Please select include if you would like to post your desired start date for this(these) candidate(s)."}
                                />
                            </div>
                        </div>

                        {/* Radio Buttons for Include Date and Not Include Date */}
                        <div className="mt-4 my-3 flex ">
                            <div className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    id="includeDate"
                                    name="dateOption"
                                    value="includeDate"
                                    checked={includeDate}
                                    onChange={() => setIncludeDate(true)}
                                    className="mr-2 "
                                />
                                <label className="mr-5" htmlFor="includeDate">Include Start Date</label>
                            </div>
                            <div className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    id="notIncludeDate"
                                    name="dateOption"
                                    value="notIncludeDate"
                                    checked={!includeDate}
                                    onChange={() => setIncludeDate(false)}
                                    className="mr-2"
                                />
                                <label htmlFor="notIncludeDate"> Do Not Include Start Date</label>
                            </div>
                        </div>

                        {/* Conditionally Render Start Date */}
                        {includeDate && (
                            <Controller
                                control={control}
                                name="startDate"
                                render={(field) => (
                                    <DateGeneric
                                        {...field}
                                        display="Start Date"
                                        timestamp={true}
                                        readOnly={isFieldLocked}
                                        error={errors?.startDate?.message}
                                        required
                                    />
                                )}
                            />
                        )}
                        {error && <p className="text-red-500  text-base" >{error}</p>}
                    </div>
                </div>
                <div className="mt-28 w-full flex flex-row justify-between">
                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        onClick={() => {
                            reset();
                            navigate("/jobs");
                        }}
                    >
                        Cancel
                    </button>
                    <div className="flex justify-center items-center">
                        <button
                            onClick={() => setCurrentStep(currStep - 1)}
                            className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {isSubmitting ? "Saving..." : "Next"}
                        </button>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddJobStep2;